import first from 'lodash/first';
import get from 'lodash/get';
import { Workflow } from './workflow';
import { handleRouteInput } from '../route';

export class WorkflowService {
  /**
   * Constructor
   *
   * @param {RouterService} routerService
   */
  constructor (routerService) {
    /** @type RouterService */
    this.routerService = routerService;

    this.workflows = {};
    this.activeFlow = null;
  }

  /**
   * Register new workflow
   *
   * @param name
   * @param workflow
   */
  register (name, workflow) {
    if (!(workflow instanceof Workflow)) {
      throw new Error(`Workflow ${name} must be instance of Workflow object`);
    }
    this.workflows[name] = workflow;
  }

  /**
   * Returns a list of workflow names; an individual name can then be passed
   * to `this.get` to retrieve the entire workflow.
   *
   * @returns {string[]}
   */
  getRegisteredWorkflows () {
    return Object.keys(this.workflows);
  }

  /**
   * Gets a random workflow
   *
   * @returns {string}
   */
  getRandomWorkflow () {
    const registeredWorkflows = this.getRegisteredWorkflows();

    return registeredWorkflows[Math.floor(Math.random() * registeredWorkflows.length)];
  }

  /**
   * Sets the current workflow
   *
   * @param name
   */
  setActiveFlow (name) {
    if (!this.has(name)) {
      throw new Error(`Workflow ${name} could not be found`);
    }

    this.activeFlow = name;
  }

  /**
   * check if a workflow exists
   *
   * @param name
   * @returns {boolean}
   */
  has (name) {
    return this.workflows.hasOwnProperty(name);
  }

  /**
   * Gets a workflow by name
   *
   * @param name
   * @returns {Workflow | null}
   */
  get (name) {
    return this.workflows[name] || null;
  }

  /**
   * Get the current workflow based on query or storage
   *
   * @returns {Workflow}
   */
  get currentWorkflow () {
    return this.get(this.activeFlow);
  }

  /**
   * get the current page step for workflow
   *
   * @returns {Page}
   */
  get currentPage () {
    const route = this.routerService.getCurrentRoute();
    return this.currentWorkflow.getPage(get(route, 'name'));
  }

  /**
   * return page that matches the route name
   *
   * @param routeName
   * @returns {Page}
   */
  getPageForRoute (routeName) {
    return this.currentWorkflow.getPage(routeName);
  }

  /**
   * Return the next page by route name
   *
   * @param routeName
   * @param args
   * @returns {*}
   */
  getNextPageForRoute (routeName, args) {
    const name = routeName === null
      ? get(first(this.currentWorkflow()), 'name')
      : routeName;

    const route = this.getPageForRoute(name);
    const next = get(route, 'next');

    if (typeof next === 'function') {
      return handleRouteInput(next.call(this, args));
    }

    return handleRouteInput(next);
  }

  /**
   * Return the previous page by route name
   *
   * @param routeName
   * @param args
   * @returns {*}
   */
  getPreviousPageForRoute (routeName, args) {
    const route = this.getPageForRoute(routeName);
    const previous = get(route, 'previous');

    if (typeof previous === 'function') {
      return handleRouteInput(previous.call(this, args));
    }

    return handleRouteInput(previous);
  }

  /**
   * Get the next page in the workflow
   *
   * @param args
   * @returns {*}
   */
  getNextPage (args) {
    return this.getNextPageForRoute(get(this, 'currentPage.name'), args);
  }

  /**
   * Get the previous page in the workflow
   *
   * @param args
   * @returns {*}
   */
  getPreviousPage (args) {
    return this.getPreviousPageForRoute(get(this, 'currentPage.name'), args);
  }

  /**
   * Calculates the workflow page progress
   *
   * @returns {number}
   */
  get progress () {
    return this.currentWorkflow.getPageProgress(get(this, 'currentPage.name'));
  }

  /**
  * Get the previous page in the workflow
  *
  * @returns {Promise<*>}
  */
  goHome () {
    return this.routerService.push({ name: 'forms.pre' });
  }

  /**
   * Navigate forward
   *
   * @param args
   * @returns {Promise<void>}
   */
  async next (args) {
    const next = this.getNextPage(args);
    return await this.routerService.push(next);
  }

  /**
   * Navigate backward
   *
   * @param args
   * @param useHistory
   * @returns {Promise<void>}
   */
  async previous (args, useHistory) {
    const prev = this.getPreviousPage(args);
    return await this.routerService.push(prev);
  }

  /**
   * Determine if we should show the back button
   *
   * @param args
   * @returns {boolean}
   */
  showBackButton (args) {
    const prev = this.getPreviousPage(args);
    return get(prev, 'name') !== 'forms.home';
  }
}
