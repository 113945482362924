export const getBrandNameKey = (
  getHostnameFromWindow = () => window.location.hostname
) => () => {
  const hostname = getHostnameFromWindow();

  if (hostname.includes('tresl')) {
    return 'tresl';
  } else if (hostname.includes('rategenius')) {
    return 'rategenius';
  }

  return 'autopay';
};
