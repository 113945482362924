export const offers = [
  {
    lender: 'ELEMENTSFINANCIAL',
    term: 48,
    rate: 5.5,
    monthlyPayment: '8000.00',
    principal: '24560.00',
    loanAmount: '24560.00',
    id: '017c0e59-ca4b-4d77-9681-9ee1ec05fcb6',
    createdOn: '2021-09-22T16:32:17+00:00',
    request: {
      sponsor: 'RATEGENIUS',
      payload: {
        city: 'austin',
        state: 'AK',
        zip_code: '78701',
        last_name: 'sknsk',
        birth_date: '1992-09-04',
        first_name: 'shshsk',
        vehicle_make: 'Audi',
        vehicle_trim: 'Sedan 4D 2.0T Premium',
        vehicle_year: '2019',
        vehicle_model: 'A4',
        street_address: 'lmlsmls',
        monthly_payment: '5001',
        remainingBalance: '24560.00',
        remainingTerm: 50,
        total_yearly_income: '400'
      }
    }
  },
  {
    lender: 'AFFINITY',
    term: 48,
    rate: 5.69,
    monthlyPayment: '4500.00',
    principal: '24560.00',
    loanAmount: '24560.00',
    id: '017c0e59-ca4b-4d77-9681-9ee1ec05fcb6',
    createdOn: '2021-09-22T16:32:17+00:00',
    request: {
      sponsor: 'RATEGENIUS',
      payload: {
        city: 'austin',
        state: 'AK',
        zip_code: '78701',
        last_name: 'Mikington',
        birth_date: '1992-09-04',
        first_name: 'Mike',
        vehicle_make: 'Audi',
        vehicle_trim: 'Sedan 4D 2.0T Premium',
        vehicle_year: '2019',
        vehicle_model: 'A4',
        street_address: 'lmlsmls',
        monthly_payment: '5001',
        remainingBalance: '24560.00',
        remainingTerm: 50,
        total_yearly_income: '400'
      }
    }
  },
  {
    lender: 'LENDERPEOPLE',
    term: 60,
    rate: 4.49,
    monthlyPayment: '4500.00',
    principal: '24560.00',
    loanAmount: '24560.00',
    id: '017c0e59-ca4b-4d77-9681-9ee1ec05fcb6',
    createdOn: '2021-09-22T16:32:17+00:00',
    request: {
      sponsor: 'RATEGENIUS',
      payload: {
        city: 'austin',
        state: 'AK',
        zip_code: '78701',
        last_name: 'sknsk',
        birth_date: '1992-09-04',
        first_name: 'shshsk',
        vehicle_make: 'Audi',
        vehicle_trim: 'Sedan 4D 2.0T Premium',
        vehicle_year: '2019',
        vehicle_model: 'A4',
        street_address: 'lmlsmls',
        monthly_payment: '5001',
        remainingBalance: '24560.00',
        remainingTerm: 58,
        total_yearly_income: '400'
      }
    }
  }
];
