import {
  NETWORK_STATUS_ERROR_CODE,
  SLOW_RESPONSE_TIME_IN_MILLISECONDS,
  FAST_RESPONSE_TIME_IN_MILLISECONDS
} from '../../../values/connection';

export const getters = {
  connectionSpeedAsString: (state, {
    hasSlowConnection,
    hasFastConnection
  }) => {
    if (hasSlowConnection) {
      return 'slow connection speed';
    }
    if (hasFastConnection) {
      return 'fast connection speed';
    }

    return 'unknown connection speed';
  },
  isOnline: (state) =>
    state.isOnline,
  isOnlineAsString: (state) =>
    state.isOnline ? 'online' : 'offline',
  hadLongerRequests: (state) =>
    state.networkStatus.some(({ responseTimeInMilliseconds }) =>
      responseTimeInMilliseconds >= SLOW_RESPONSE_TIME_IN_MILLISECONDS),
  hadTimeouts: (state) =>
    state.networkStatus.some(({ responseTimeInMilliseconds }) =>
      responseTimeInMilliseconds === NETWORK_STATUS_ERROR_CODE),
  hasSlowConnection: (state) =>
    state.latestResponseTimeInMilliseconds >= SLOW_RESPONSE_TIME_IN_MILLISECONDS,
  hasFastConnection: (state) =>
    state.latestResponseTimeInMilliseconds <= FAST_RESPONSE_TIME_IN_MILLISECONDS &&
    state.latestResponseTimeInMilliseconds !== NETWORK_STATUS_ERROR_CODE,
  networkStatus: (state, {
    connectionSpeedAsString,
    isOnlineAsString,
    hadTimeouts,
    hadLongerRequests
  }) => {
    let status = `currently ${isOnlineAsString} with ${connectionSpeedAsString}`;

    if (hadTimeouts) {
      status += ' - spotty connection history';
    } else if (hadLongerRequests) {
      status += ' - slow connection history';
    }

    return status;
  }
};
