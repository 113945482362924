import { IngressService } from '../../core/service/ingress';
import { QueryService } from '../../core/service/query';

export default {
  require: [
    'location', 'localStorage', 'cookieService'
  ],
  lazy: false,
  init: (location, localStorage, cookieService) => {
    const ingress = new IngressService(localStorage, null, null);

    ingress.attribute(new QueryService(location), cookieService);

    return ingress;
  }
};
