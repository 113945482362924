import { StoreModifier } from '../../utilities/storeModifiers';
import { actions } from './connection/actions';
import { getters } from './connection/getters';
import { mutations } from './connection/mutations';
import { state } from './connection/state';

export default new StoreModifier({
  actions,
  getters,
  mutations,
  namespaced: true,
  state
});
