import { stringify } from 'qs';
import {
  ApplicationSubmissionNetworkError,
  ApplicationSubmissionRejected,
  ErrorMessagePayload
} from '../errors/network';

function handleSubmissionResponse (response, payloadData) {
  const responseContent = response.data;

  if (responseContent.error_code) {
    const errorPayload = new ErrorMessagePayload(responseContent.error_message, payloadData);
    throw new ApplicationSubmissionRejected(errorPayload);
  }

  return responseContent.data;
}

export class Api {
  constructor (
    nxgHttpRequest,
    legacyHttpRequest
  ) {
    this.legacyHttpRequest = legacyHttpRequest;
    this.nxgHttpRequest = nxgHttpRequest;
  }

  /**
   * Send payload to Legacy API
   *
   * @param payload
   * @returns {Promise<*>}
   */
  async submitLegacy (payload) {
    try {
      const response = await this.legacyHttpRequest.post('/Incoming/NewApplicantReceive.php', stringify(payload));
      return handleSubmissionResponse(response, payload);
    } catch (error) {
      throw new ApplicationSubmissionNetworkError(
        new ErrorMessagePayload(error.message, payload)
      );
    }
  }

  /**
   * Send payload to NXG API
   *
   * @param payload
   * @returns {Promise<*>}
   */
  async submitNxg (payload) {
    try {
      const response = await this.nxgHttpRequest.post('/api/rgsystem/sponsor/rglegacy', stringify(payload));
      return handleSubmissionResponse(response, payload);
    } catch (error) {
      throw new ApplicationSubmissionNetworkError(
        new ErrorMessagePayload(error.message, payload)
      );
    }
  }
}
