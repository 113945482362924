import map from 'lodash/map';
import { getVehicleModelsFromConsumerPortal } from './consumerportal/getVehicleModelsFromConsumerPortal';
import VehiclePlaceholder from 'Images/vehicle/vehiclePlaceholder.png';

/**
 * Gets the vehicle model data for the provided year and make id and
 * also sets the vehicle placeholder for the image url
 *
 * @param {(year: number, makeId: number) => Promise<Object[]>} getVehicleModelsResponse
 * @returns {(year: number, makeId: number) => Promise<Object[]>}
 */
export const getVehicleModelsWithPlaceholderImage = (
  getVehicleModelsResponse = getVehicleModelsFromConsumerPortal()
) => async (
  year,
  makeId
) => {
  const vehicleModelsResponse = await getVehicleModelsResponse(year, makeId);

  return map(
    vehicleModelsResponse,
    model => ({ ...model, image_url: VehiclePlaceholder })
  );
};
