import get from 'lodash/get';
import { getMailCodeData } from '../../../core/api/autopay/getMailCodeData';
import { isNonEmptyString } from '../../../utilities/isNonEmptyString';

export const actions = {
  getMailCodeData ({ commit, state, rootGetters }) {
    const mailCode = rootGetters['mailCodeOffer/mailCode'];

    if (isNonEmptyString(mailCode)) {
      commit('SET_OFFER_REQUEST_HAS_FINISHED', false);

      const mailCodeSource = rootGetters['leadChannel/mailCodeSource'];

      return getMailCodeData(state.httpRequest)(mailCodeSource, mailCode)
        .then((axiosResponse) => {
          commit(
            'SET_OFFER_RESPONSE_STATUS_CODE',
            get(axiosResponse, 'status', null)
          );
          commit(
            'SET_OFFER_RESPONSE_BODY',
            get(axiosResponse, 'data', null)
          );
          commit(
            'applicant/SET_PRIMARY_APPLICANT_FIRST_NAME',
            get(axiosResponse, 'data.payload.applicant.firstName', null),
            { root: true }
          );
          commit(
            'applicant/SET_PRIMARY_APPLICANT_LAST_NAME',
            get(axiosResponse, 'data.payload.applicant.lastName', null),
            { root: true }
          );
        })
        .catch((error) => {
          commit(
            'SET_OFFER_RESPONSE_STATUS_CODE',
            get(error, 'response.status', null)
          );
          commit(
            'SET_OFFER_RESPONSE_BODY',
            get(error, 'response.data', null)
          );
        })
        .finally(() => {
          commit('SET_OFFER_REQUEST_HAS_FINISHED', true);
        });
    }
  },
  setMailCode ({ commit }, mailCode) {
    commit('SET_OFFER_MAIL_CODE', mailCode);
  }
};
