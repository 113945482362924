import { autopayEmploymentTypeMap } from '../values/application';

/**
 * Returns the Autopay employment type for the provided FastTrack employment type
 *
 * @param {string} fastTrackEmploymentType
 * @returns {string|null}
 */
export const toAutopayEmploymentType = (
  fastTrackEmploymentType
) => autopayEmploymentTypeMap[fastTrackEmploymentType] || null;
