import { getTreslCreateScheduleRequestBody } from '../../lib/getTreslCreateScheduleRequestBody';
import { treslHttpRequest } from '../axios.config';

/**
 * Function to send an HTTP request to Tresl's "create schedule url" API endpoint
 *
 * @param {AxiosInstance} config.httpRequest
 *
 * @returns {({ apiKey: string, clientNumber: string, partnerNumber: string }) => Promise<*>}
 */
export const submitCreateScheduleRequestToTresl =
  (httpRequest = treslHttpRequest) =>
    ({ apiKey, clientNumber, partnerNumber } = {}) =>
      httpRequest.request({
        headers: { 'Content-type': 'text/xml' },
        method: 'POST',
        data: getTreslCreateScheduleRequestBody({
          apiKey,
          clientNumber,
          partnerNumber
        })
      });
