import { StoreModifier } from '../../utilities/storeModifiers';
import { actions } from './mailCodeOffer/actions';
import { getters } from './mailCodeOffer/getters';
import { mutations } from './mailCodeOffer/mutations';
import { state } from './mailCodeOffer/state';

export default new StoreModifier({
  actions,
  getters,
  mutations,
  namespaced: true,
  state
});
