import { Factory } from './factory';

/**
 * Agnostic dependency injection container
 *
 * Usage:
 *
 * const di = new ServiceContainer()
 * di.register('foobar', {foo: 'bar'});
 *
 * // Binding Factories:
 * di.bind('needsFoobar', ['foobar', (foobar) => new NeedsFoobar(foobar)]);
 * // or
 * di.bind('needsFoobar3', {requires: ['foobar'], init: (foobar) => new NeedsFoobar(foobar), lazy: false};
 *
 * di.needsFoobar // instanceof NeedsFoobar
 *
 */
export class ServiceContainer {
  /**
   * constructor
   */
  constructor () {
    this.dependencies = {};
    this.factories = {};
  }

  /**
   * Register any object as a dependency
   *
   * @param {string} name
   * @param {*} dependency - any object to be injected (if Factory is passed we will ignore the lazy flag)
   */
  register (name, dependency) {
    if (dependency instanceof Factory) {
      dependency = this.make(dependency);
    }

    this.dependencies[name] = dependency;
    this.createAccessor(name);
  }

  /**
   * Bind a Factory or Factory compatible Array|Object.
   *
   * Factories are functions used to initialize a dependency.
   * By default the Factory object is lazy meaning it will not create an instance
   * until it is called or injected when using bind.
   *
   * @param {string} name - property we'd like to bind to
   * @param {Factory|Array|Object} factory - the factory
   */
  bind (name, factory) {
    factory = Factory.create(factory);

    if (factory.lazy === false) {
      return this.register(name, factory);
    }

    this.factories[name] = factory;
    this.createAccessor(name);
  }

  /**
   * Locate the service within the container.
   *
   * @param name
   * @returns {*}
   */
  get (name) {
    if (typeof name !== 'string') {
      name = ServiceContainer.resolveName(name);
    }

    if (!this.dependencies[name]) {
      const factory = this.factories[name];
      this.dependencies[name] = factory && this.make(factory);
      if (!this.dependencies[name]) throw new Error(`Cannot find module ${name}`);
    }

    return this.dependencies[name];
  }

  /**
   * inject dependencies
   *
   * @param {Factory} factory
   * @returns {*}
   */
  make (factory) {
    if (factory instanceof Factory) {
      return factory.inject(this);
    }

    return Factory.create(factory).inject(this);
  }

  /**
   * Generate a getter on the instance for the registered service.
   *
   * @param name
   * @returns {*}
   */
  createAccessor (name) {
    Object.defineProperty(this, name, {
      get () {
        return this.get(name);
      }
    });
  }

  /**
   * resolve a class name
   *
   * @param classReference
   * @returns {*}
   */
  static resolveName (classReference) {
    return classReference.name;
  }
}
