import { creditSnapHttpRequest } from '../axios.config';
import { submitRequestToCreditSnap } from './submitRequestToCreditSnap';

/**
 * POSTs the credit snap application using the provided axios instance
 *
 * @param {AxiosInstance} httpRequest
 * @returns {( creditSnapApplication: Object) => Promise<*>}
 */
export const submitApplicationToCreditSnap = (
  httpRequest = creditSnapHttpRequest
) => (
  creditSnapApplication
) =>
  submitRequestToCreditSnap(httpRequest)(
    '/partner/application/offerswithvariations',
    'POST',
    creditSnapApplication
  );
