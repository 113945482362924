import {
  convertToFloatIfStringOrNumber
} from '../../../utilities/convertToFloatIfStringOrNumber';

export const mutations = {
  // primary applicant current employment
  SET_PRIMARY_APPLICANT_CURRENT_EMPLOYMENT_EMPLOYER_NAME (state, employerName) {
    state.primary.current.employerName = employerName;
  },
  SET_PRIMARY_APPLICANT_CURRENT_EMPLOYMENT_JOB_TITLE (state, jobTitle) {
    state.primary.current.jobTitle = jobTitle;
  },
  SET_PRIMARY_APPLICANT_CURRENT_EMPLOYMENT_TYPE (state, type) {
    state.primary.current.type = type;
  },
  SET_PRIMARY_APPLICANT_GROSS_ANNUAL_INCOME_AT_CURRENT_EMPLOYMENT (state, grossAnnualIncome) {
    state.primary.current.grossAnnualIncome =
      convertToFloatIfStringOrNumber(grossAnnualIncome);
  },
  SET_PRIMARY_APPLICANT_GROSS_ANNUAL_INCOME_CONFIRMED_AT_CURRENT_EMPLOYMENT (state, grossIncomeConfirmed) {
    state.primary.current.grossIncomeConfirmed = grossIncomeConfirmed;
  },
  SET_PRIMARY_APPLICANT_GROSS_ANNUAL_INCOME_CONFIRMED_VALUE_AT_CURRENT_EMPLOYMENT (state, grossIncomeConfirmedValue) {
    state.primary.current.grossIncomeConfirmedValue =
      convertToFloatIfStringOrNumber(grossIncomeConfirmedValue);
  },
  SET_PRIMARY_APPLICANT_NUMBER_OF_MONTHS_AT_CURRENT_EMPLOYMENT (state, numberOfMonthsEmployed) {
    state.primary.current.numberOfMonthsEmployed = numberOfMonthsEmployed;
  },
  SET_PRIMARY_APPLICANT_NUMBER_OF_YEARS_AT_CURRENT_EMPLOYMENT (state, numberOfYearsEmployed) {
    state.primary.current.numberOfYearsEmployed = numberOfYearsEmployed;
  },
  // Additional Income
  SET_PRIMARY_APPLICANT_HAS_ADDITIONAL_INCOME (state, hasAdditionalIncome) {
    state.primary.additionalIncome.hasAdditionalIncome = hasAdditionalIncome;
  },
  SET_PRIMARY_APPLICANT_ADDITIONAL_INCOME_AMOUNT (state, amount) {
    state.primary.additionalIncome.amount = convertToFloatIfStringOrNumber(amount);
  },
  SET_PRIMARY_APPLICANT_ADDITIONAL_INCOME_EMPLOYER_NAME (state, employerName) {
    state.primary.additionalIncome.employerName = employerName;
  },
  SET_PRIMARY_APPLICANT_ADDITIONAL_INCOME_FREQUENCY (state, frequency) {
    state.primary.additionalIncome.frequency = frequency;
  },
  SET_PRIMARY_APPLICANT_ADDITIONAL_INCOME_JOB_TITLE (state, jobTitle) {
    state.primary.additionalIncome.jobTitle = jobTitle;
  },
  SET_PRIMARY_APPLICANT_ADDITIONAL_INCOME_SOURCE (state, source) {
    state.primary.additionalIncome.source = source;
  },
  SET_PRIMARY_APPLICANT_NUMBER_OF_MONTHS_AT_ADDITIONAL_EMPLOYMENT (state, numberOfMonthsEmployed) {
    state.primary.additionalIncome.numberOfMonthsEmployed = numberOfMonthsEmployed;
  },
  SET_PRIMARY_APPLICANT_NUMBER_OF_YEARS_AT_ADDITIONAL_EMPLOYMENT (state, numberOfYearsEmployed) {
    state.primary.additionalIncome.numberOfYearsEmployed = numberOfYearsEmployed;
  },
  // primary applicant previous employment
  SET_PRIMARY_APPLICANT_PREVIOUS_EMPLOYMENT_EMPLOYER_NAME (state, employerName) {
    state.primary.previous.employerName = employerName;
  },
  SET_PRIMARY_APPLICANT_PREVIOUS_EMPLOYMENT_JOB_TITLE (state, jobTitle) {
    state.primary.previous.jobTitle = jobTitle;
  },
  SET_PRIMARY_APPLICANT_PREVIOUS_EMPLOYMENT_TYPE (state, type) {
    state.primary.previous.type = type;
  },
  SET_PRIMARY_APPLICANT_NUMBER_OF_MONTHS_AT_PREVIOUS_EMPLOYMENT (state, numberOfMonthsEmployed) {
    state.primary.previous.numberOfMonthsEmployed = numberOfMonthsEmployed;
  },
  SET_PRIMARY_APPLICANT_NUMBER_OF_YEARS_AT_PREVIOUS_EMPLOYMENT (state, numberOfYearsEmployed) {
    state.primary.previous.numberOfYearsEmployed = numberOfYearsEmployed;
  },

  // joint applicant current employment
  SET_JOINT_APPLICANT_CURRENT_EMPLOYMENT_EMPLOYER_NAME (state, employerName) {
    state.joint.current.employerName = employerName;
  },
  SET_JOINT_APPLICANT_CURRENT_EMPLOYMENT_JOB_TITLE (state, jobTitle) {
    state.joint.current.jobTitle = jobTitle;
  },
  SET_JOINT_APPLICANT_CURRENT_EMPLOYMENT_TYPE (state, type) {
    state.joint.current.type = type;
  },
  SET_JOINT_APPLICANT_GROSS_ANNUAL_INCOME_AT_CURRENT_EMPLOYMENT (state, grossAnnualIncome) {
    state.joint.current.grossAnnualIncome =
      convertToFloatIfStringOrNumber(grossAnnualIncome);
  },
  SET_JOINT_APPLICANT_GROSS_ANNUAL_INCOME_CONFIRMED_AT_CURRENT_EMPLOYMENT (state, grossIncomeConfirmed) {
    state.joint.current.grossIncomeConfirmed = grossIncomeConfirmed;
  },
  SET_JOINT_APPLICANT_GROSS_ANNUAL_INCOME_CONFIRMED_VALUE_AT_CURRENT_EMPLOYMENT (state, grossIncomeConfirmedValue) {
    state.joint.current.grossIncomeConfirmedValue =
      convertToFloatIfStringOrNumber(grossIncomeConfirmedValue);
  },
  SET_JOINT_APPLICANT_NUMBER_OF_MONTHS_AT_CURRENT_EMPLOYMENT (state, numberOfMonthsEmployed) {
    state.joint.current.numberOfMonthsEmployed = numberOfMonthsEmployed;
  },
  SET_JOINT_APPLICANT_NUMBER_OF_YEARS_AT_CURRENT_EMPLOYMENT (state, numberOfYearsEmployed) {
    state.joint.current.numberOfYearsEmployed = numberOfYearsEmployed;
  },
  // joint applicant additional income
  SET_JOINT_APPLICANT_HAS_ADDITIONAL_INCOME (state, hasAdditionalIncome) {
    state.joint.additionalIncome.hasAdditionalIncome = hasAdditionalIncome;
  },
  SET_JOINT_APPLICANT_ADDITIONAL_INCOME_AMOUNT (state, amount) {
    state.joint.additionalIncome.amount = convertToFloatIfStringOrNumber(amount);
  },
  SET_JOINT_APPLICANT_ADDITIONAL_INCOME_EMPLOYER_NAME (state, employerName) {
    state.joint.additionalIncome.employerName = employerName;
  },
  SET_JOINT_APPLICANT_ADDITIONAL_INCOME_FREQUENCY (state, frequency) {
    state.joint.additionalIncome.frequency = frequency;
  },
  SET_JOINT_APPLICANT_ADDITIONAL_INCOME_JOB_TITLE (state, jobTitle) {
    state.joint.additionalIncome.jobTitle = jobTitle;
  },
  SET_JOINT_APPLICANT_ADDITIONAL_INCOME_SOURCE (state, source) {
    state.joint.additionalIncome.source = source;
  },
  SET_JOINT_APPLICANT_NUMBER_OF_MONTHS_AT_ADDITIONAL_EMPLOYMENT (state, numberOfMonthsEmployed) {
    state.joint.additionalIncome.numberOfMonthsEmployed = numberOfMonthsEmployed;
  },
  SET_JOINT_APPLICANT_NUMBER_OF_YEARS_AT_ADDITIONAL_EMPLOYMENT (state, numberOfYearsEmployed) {
    state.joint.additionalIncome.numberOfYearsEmployed = numberOfYearsEmployed;
  },
  // joint applicant previous employment
  SET_JOINT_APPLICANT_PREVIOUS_EMPLOYMENT_EMPLOYER_NAME (state, employerName) {
    state.joint.previous.employerName = employerName;
  },
  SET_JOINT_APPLICANT_PREVIOUS_EMPLOYMENT_JOB_TITLE (state, jobTitle) {
    state.joint.previous.jobTitle = jobTitle;
  },
  SET_JOINT_APPLICANT_PREVIOUS_EMPLOYMENT_TYPE (state, type) {
    state.joint.previous.type = type;
  },
  SET_JOINT_APPLICANT_NUMBER_OF_MONTHS_AT_PREVIOUS_EMPLOYMENT (state, numberOfMonthsEmployed) {
    state.joint.previous.numberOfMonthsEmployed = numberOfMonthsEmployed;
  },
  SET_JOINT_APPLICANT_NUMBER_OF_YEARS_AT_PREVIOUS_EMPLOYMENT (state, numberOfYearsEmployed) {
    state.joint.previous.numberOfYearsEmployed = numberOfYearsEmployed;
  }
};
