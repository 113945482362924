export function handleRouteInput (routeNameOrObject) {
  let route = {};
  if (typeof routeNameOrObject === 'string') {
    route.name = routeNameOrObject;
  } else {
    route = routeNameOrObject;
  }

  return route;
}

export class RouterService {
  /**
   *
   * @param {VueRouter} vueRouter
   */
  constructor (vueRouter) {
    this.vueRouter = vueRouter;
  }

  /**
   * get the current route info
   *
   * @returns {{fullPath: string, path: string, query: Dictionary<string|(string|null)[]>, name: string}}
   */
  getCurrentRoute () {
    const query = this.getQuery();
    const fullPath = this.getFullPath();
    const path = this.getPath();
    const name = this.getName();
    const matched = this.getMatched();

    return {
      name, path, fullPath, query, matched
    };
  }

  /**
   * Pass-through for go
   *
   * @param index
   */
  async go (index) {
    return await this.vueRouter.go(index);
  }

  /**
   * Pass-through for push
   *
   * @param routeNameOrObject
   * @returns {Promise<*>}
   */
  async push (routeNameOrObject) {
    return await this.vueRouter.push(handleRouteInput(routeNameOrObject));
  }

  /**
   * get route query
   *
   * @returns {Dictionary<string | (string | null)[]>}
   */
  getQuery () {
    return this.vueRouter.currentRoute.query;
  }

  /**
   * get route full path
   *
   * @returns {string}
   */
  getFullPath () {
    return this.vueRouter.currentRoute.fullPath;
  }

  /**
   * get route path
   *
   * @returns {string}
   */
  getPath () {
    return this.vueRouter.currentRoute.path;
  }

  /**
   * get route name
   *
   * @returns {string}
   */
  getName () {
    return this.vueRouter.currentRoute.name;
  }

  /**
   * gets the matched routes
   *
   * @returns {RouteRecord[]}
   */
  getMatched () {
    return this.vueRouter.currentRoute.matched;
  }
}
