import { StoreModifier } from '../../utilities/storeModifiers';
import { actions } from './offers/actions';
import { getters } from './offers/getters';
import { mutations } from './offers/mutations';
import { state } from './offers/state';

export default new StoreModifier({
  actions,
  getters,
  mutations,
  namespaced: true,
  state
});
