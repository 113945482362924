import { creditSnapOwnershipStatusMap } from '../values/application';

/**
 * Returns the CreditSnap ownership status for the provided FastTrack ownership status
 *
 * @param {string} fastTrackOwnershipStatus
 * @returns {string|null}
 */
export const toCreditSnapOwnershipStatus = (fastTrackOwnershipStatus) => {
  const status = (fastTrackOwnershipStatus || '').toUpperCase();

  return creditSnapOwnershipStatusMap[status] || null;
};
