import get from 'lodash/get';
import { nxgHttpRequest } from '../axios.config';
import { extractErrorInformation } from '../extractErrorInformation';
import { reportError } from '../../errors/reportError';

/**
 * Gets the vehicle model data for the provided year and make name
 *
 * @param {*} httpRequest
 * @returns {({ year: number, makeId: string }) => Promise<Object[]>}
 */
export const getVehicleModelsFromRateGenius = (
  httpRequest = nxgHttpRequest
) => async ({
  year,
  makeId
}) => {
  try {
    const response = await httpRequest.get(
      `/api/v2/vehicle_identities/${year}/${makeId}/models`
    );

    return get(response, 'data.data', []);
  } catch (error) {
    reportError(error, { extra: extractErrorInformation(error) });
    throw error;
  }
};
