import forEach from 'lodash/forEach';
import isString from 'lodash/isString';
import replace from 'lodash/replace';
import { isNonEmptyString } from './isNonEmptyString';

export const fieldsToMask = [
  'ADDRESS',
  'birthDate',
  'cellPhone',
  'dob',
  'DOB',
  'email',
  'EMAIL',
  'emailAddress',
  'employerName',
  'empName',
  'EMP_PHONE',
  'EMPLOYER',
  'firstName',
  'FIRST_NAME',
  'JT_ADDRESS',
  'JT_DOB',
  'JT_EMPLOYER',
  'JT_EMP_PHONE',
  'JT_FIRST_NAME',
  'JT_LAST_NAME',
  'JT_PHONE',
  'JT_SSN',
  'LAST_NAME',
  'lastName',
  'lineOne',
  'lineTwo',
  'primaryPhone',
  'PHONE',
  'PREV_ADDRESS',
  'PREV_EMPLOYER',
  'streetAddress',
  'ssn',
  'SSN'
];

/**
 * Masks all characters in the provided string
 *
 * @param {*} value
 * @returns {*}
 */
const maskValue = (value) =>
  isString(value) ? replace(value, /./gi, '*') : value;

/**
 * Responsible for checking each section of the provided data for fields to mask
 *
 * @param {Object} data
 * @returns {Object}
 */
const maskValues = (data) => {
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      forEach(data[key], (obj, index) => {
        data[key][index] = maskValues(data[key][index]);
      });
    } else if (fieldsToMask.includes(key)) {
      data[key] = maskValue(data[key]);
    } else if (data[key] && typeof data[key] === 'object') {
      data[key] = maskValues(data[key]);
    }
  });

  return data;
};

/**
 * Returns the request body with PII values masked (if applicable)
 *
 * @param {*} requestBody
 * @returns {*}
 */
export const sanitizeRequestBody = (requestBody) => {
  if (isNonEmptyString(requestBody)) {
    try {
      return JSON.stringify(maskValues(JSON.parse(requestBody)));
    } catch {
      return requestBody;
    }
  }

  return requestBody;
};
