import get from 'lodash/get';
import { consumerPortalHttpRequest } from './axios.config';
import { getVehicleMakesFromConsumerPortal } from './consumerportal/getVehicleMakesFromConsumerPortal';
import * as makeImages from '../../images/vehicle/makeLogos';
import { pascalCase } from '../../utilities/String';

/**
 * Gets the vehicle make data (including image) for the provided year
 *
 * @param {(year: number) => Promise<Object[]>} getVehicleMakesResponse
 * @returns {(year: string) => Promise<Object[]>}
 */
export const getVehicleMakesWithImage = (
  getVehicleMakesResponse = getVehicleMakesFromConsumerPortal(consumerPortalHttpRequest)
) => async (
  year
) => {
  const vehicleMakesResponse = await getVehicleMakesResponse(year);

  return vehicleMakesResponse.map(
    make => ({
      ...make,
      image_url: get(
        makeImages,
        pascalCase((get(make, 'name') || '').toLowerCase()),
        null
      )
    })
  );
};
