import { storage } from './storage';

/**
 * Sets the application type for the lead channel in local storage
 *
 * @param {string} leadChannelCode
 * @param {string} applicationType
 */
export const setApplicationTypeInStorage = (
  leadChannelCode,
  applicationType
) => {
  const applicationTypes = storage.get('applicationTypes', {});

  applicationTypes[leadChannelCode] = applicationType;

  storage.set('applicationTypes', applicationTypes);
};
