export function cleanValue (input) {
  return input
    ? `${input}`.replace(/[^\d.-]/g, '')
    : null;
};

export function formatValue (input) {
  const locale = 'en-US';
  const options = {
    currency: 'USD',
    style: 'currency'
  };

  return input
    ? new Intl.NumberFormat(locale, options).format(cleanValue(input))
    : null;
};

export function addCommas (input) {
  const locale = 'en-US';
  const options = {
    style: 'decimal'
  };

  return input
    ? new Intl.NumberFormat(locale, options).format(input)
    : null;
};
