import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import map from 'lodash/map';
import { UnleashClient } from 'unleash-proxy-client';
import { storage } from '../../../utilities/storage';
import { DEFAULT_APPLICATION_SUBMISSION_API } from '../../../values/brand';

export const getters = {
  isComplianceOnContactPageEnabled: (state, { client }) =>
    client.isEnabled('ft_1539_update_verbiage_on_contact_page') || false,
  isSignInButtonEnabled: (state, { client }) =>
    client.isEnabled('ft_1521_enable_consumer_portal_login') || false,
  isPodiumChatWidgetEnabled: (state, { client }) =>
    client.isEnabled('ft_929_enable_podium_chat_widget') || false,
  isConnectionBannerEnabled: (state, { client }) =>
    client.isEnabled('ft_1073_enable_connection_banner') || false,
  isConnectionStatusEnabled: (state, { client }) =>
    client.isEnabled('ft_1102_enable_connection_status') || false,

  creditSnapWeightedSubmissionApi: (state, { client }) => {
    const variant = client.getVariant(
      'ft_1148_credit_snap_application_submission_api_weighted'
    );

    return get(variant, 'payload.value', DEFAULT_APPLICATION_SUBMISSION_API);
  },
  rateGeniusWeightedSubmissionApi: (state, { client }) => {
    const variant = client.getVariant(
      'ft_1148_rate_genius_application_submission_api_weighted'
    );

    return get(variant, 'payload.value', DEFAULT_APPLICATION_SUBMISSION_API);
  },

  currentFeatureFlagStates: (state, { client }) =>
    fromPairs(
      map(
        client.getAllToggles(),
        ({ name, enabled }) => ([name, enabled])
      )
    ),

  client: ({ mockedClient }) => {
    if (mockedClient) {
      return mockedClient;
    }

    return new UnleashClient({
      appName: 'default',
      clientKey: process.env.UNLEASH_CLIENT_KEY,
      environment: process.env.APP_ENV === 'prod' ? 'production' : 'development',
      url: process.env.UNLEASH_URL,
      impressionDataAll: true,
      refreshInterval: 60 * 60 * 12, // check every 12 hours
      storageProvider: {
        save: (name, data) => storage.set(name, data),
        get: (name) => storage.get(name)
      }
    });
  }
};
