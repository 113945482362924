import get from 'lodash/get';
import { nxgHttpRequest } from './axios.config';
import { extractErrorInformation } from './extractErrorInformation';
import { reportError } from '../errors/reportError';

/**
 * Replaces any characters in the vehicle model name that may cause issues
 * when being appended to the path string
 *
 * @example
 *  - 2011 Dodge Grand Caravan C/V
 *
 * The / ends up becoming a path separator, causing Kong to return a 500 error
 * "The server did not find a resource matching the request URI."
 *
 * @param {{ name: string}} vehicleModel
 * @returns {string}
 */
const getVehicleModelName = ({ name = '' }) => {
  return encodeURI(name.replace('/', ''));
};

/**
 * Gets the vehicle image data for the year, make and vehicle models provided
 *
 * @param {*} httpRequest
 * @returns {({ year: number, makeName: number, vehicleModels: Object[] }) => Promise<Object[]>}
 */
export const getVehicleModelsWithImage = (
  httpRequest = nxgHttpRequest
) => async ({
  year,
  makeName,
  vehicleModels
}) => {
  const modelNames = vehicleModels.map(getVehicleModelName).join(';');

  try {
    const response = await httpRequest.get(
      `/api/v2/vehicle_identities/image_urls/${year}/${makeName}/${modelNames}`
    );

    const vehicleImageUrls = get(response, 'data.data', []);

    return vehicleModels.map(
      model => {
        const image = vehicleImageUrls.find(image => image.model === model.name);
        return { ...model, ...image };
      }
    );
  } catch (error) {
    reportError(error, { extra: extractErrorInformation(error) });

    return vehicleModels;
  }
};
