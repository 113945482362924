import find from 'lodash/find';
import { isNonEmptyString } from '../../../utilities/isNonEmptyString';

export const getters = {
  isPrimaryApplicantEmailAddressValid: (state, getters, rootState, rootGetters) =>
    isNonEmptyString(rootGetters['applicant/primaryApplicantEmailAddress']),
  isJointApplicantEmailAddressValid: (state, getters, rootState, rootGetters) =>
    isNonEmptyString(rootGetters['applicant/jointApplicantEmailAddress']),
  isVehicleMakeNameValid: (state, getters, rootState, rootGetters) =>
    isNonEmptyString(rootGetters['vehicle/makeName']),
  isVehicleModelNameValid: (state, getters, rootState, rootGetters) =>
    isNonEmptyString(rootGetters['vehicle/modelName']),
  isVehicleYearValid: (state, getters, rootState, rootGetters) => {
    const vehicleYear = rootGetters['vehicle/year'];
    const years = rootGetters['vehicle/years'] || [];
    return find(years, { name: vehicleYear }) !== undefined;
  }
};
