import get from 'lodash/get';
import { reportError } from '../../../core/errors/reportError';

import {
  extractErrorInformation
} from '../../../core/api/extractErrorInformation';
import {
  getAutopayApplicationPath
} from '../../../core/api/autopay/getAutopayApplicationPath';
import {
  getConsumerPortalApplicationPath
} from '../../../core/api/consumerportal/getConsumerPortalApplicationPath';
import {
  submitApplicationToAutopay
} from '../../../core/api/autopay/submitApplicationToAutopay';
import {
  submitOfferToCreditSnap
} from '../../../core/api/tresl/submitOfferToCreditSnap';
import {
  submitApplicationToCreditSnap
} from '../../../core/api/tresl/submitApplicationToCreditSnap';
import {
  submitCreateScheduleRequestToTresl
} from '../../../core/api/tresl/submitCreateScheduleRequestToTresl';
import {
  getTextContentFromXMLNode
} from '../../../core/lib/getTextContentFromXMLNode';
import {
  convertTreslCalendarURL
} from '../../../core/lib/convertTreslCalendarURL';
import {
  getTreslRedirectURL
} from '../../../core/lib/getTreslRedirectURL';
import {
  submitApplicationToRateGenius
} from '../../../core/api/rategenius/submitApplicationToRateGenius';
import {
  submitApplicationToConsumerPortal
} from '../../../core/api/consumerportal/submitApplicationToConsumerPortal';

const getActionName = ({
  forceAutopay = false,
  shouldSubmitToCreditSnap = false,
  shouldSubmitToConsumerPortal = false,
  shouldSubmitToRateGenius = false
} = {}) => {
  if (forceAutopay) {
    return 'submitApplicationToAutopay';
  } else if (shouldSubmitToCreditSnap) {
    return 'submitApplicationToCreditSnap';
  } else if (shouldSubmitToConsumerPortal) {
    return 'submitApplicationToConsumerPortal';
  } else if (shouldSubmitToRateGenius) {
    return 'submitApplicationToRateGenius';
  }

  return 'submitApplicationToAutopay';
};

const getOfferActionName = ({
  shouldSubmitToCreditSnap = false,
  shouldSubmitToRateGenius = false
} = {}) => {
  if (shouldSubmitToCreditSnap) {
    return 'submitOfferToCreditSnap';
  } else if (shouldSubmitToRateGenius) {
    return 'submitOfferToRateGenius';
  }

  return 'submitOfferToAutopay';
};

export const actions = {
  redirectAfterOfferSelection ({ dispatch, rootGetters }) {
    if (rootGetters['leadChannel/shouldSubmitToCreditSnap']) {
      return dispatch('redirectToTresl');
    }
  },
  redirectToTresl ({ commit, rootGetters }) {
    const httpRequest = rootGetters['global/treslHttpRequest'];
    const httpConfig = {
      apiKey: rootGetters['global/treslApiKey'],
      clientNumber: rootGetters['global/clientApplicationNumber'],
      partnerNumber: rootGetters['global/treslPartnerNumber']
    };
    const isProduction = rootGetters.isProduction;
    const nonProductionURLString = rootGetters['global/treslNonProductionScheduleAppointmentURL'];

    return submitCreateScheduleRequestToTresl(httpRequest)(httpConfig)
      .then((response) =>
        getTextContentFromXMLNode('url')(response.data))
      .then((originalURL) =>
        convertTreslCalendarURL({ isProduction, nonProductionURLString })(originalURL))
      .then((treslCalendarURL) =>
        window.location.assign(getTreslRedirectURL()(treslCalendarURL)))
      .catch((error) => {
        commit('SET_TRESL_SCHEDULE_ERROR', error);

        reportError(error, { extra: extractErrorInformation(error) });
      });
  },
  setAppId ({ commit }, appId) {
    commit('SET_APP_ID', appId);
  },
  setApplicationType ({ commit }, applicationType) {
    commit('SET_APPLICATION_TYPE', applicationType);
  },
  setHasSeenHome ({ commit }, hasSeenHome) {
    commit('SET_HAS_SEEN_HOME', hasSeenHome);
  },
  setLoadingPercent ({ commit }, loadingPercent) {
    commit('SET_LOADING_PERCENT', loadingPercent);
  },
  setOriginMarketingSourceUrl ({ commit }, originMarketingSourceUrl) {
    commit('SET_ORIGIN_MARKETING_SOURCE_URL', originMarketingSourceUrl);
  },
  setReasonForLoanChange ({ commit }, reasonForChange) {
    commit('SET_REASON_FOR_LOAN_CHANGE', reasonForChange);
  },
  setSelectedOfferId ({ commit }, offerId) {
    commit('SET_SELECTED_OFFER_ID', offerId);
  },
  setWindowWidth ({ commit }, width) {
    commit('SET_WINDOW_WIDTH', width);
  },
  scrollToTop () {
    window.scrollTo(0, 0);
  },
  submitApplication ({ commit, dispatch, rootGetters }, { forceAutopay = false } = {}) {
    commit('SET_SUBMITTING_APPLICATION', true);

    const actionName = getActionName({
      forceAutopay,
      shouldSubmitToCreditSnap: rootGetters['leadChannel/shouldSubmitToCreditSnap'],
      shouldSubmitToConsumerPortal: rootGetters['leadChannel/shouldSubmitToConsumerPortal'],
      shouldSubmitToRateGenius: rootGetters['leadChannel/shouldSubmitToRateGenius']
    });

    return dispatch(actionName).finally(() => {
      commit('SET_APPLICATION_SUBMITTED', true);
      commit('SET_SUBMITTING_APPLICATION', false);
    });
  },
  submitApplicationToAutopay ({ commit, rootGetters, state }) {
    const bearerToken = rootGetters['leadChannel/bearerToken'];
    const httpRequest = state.autopayHttpRequest;

    const path = getAutopayApplicationPath(
      rootGetters['leadChannel/organizationCode'],
      rootGetters['leadChannel/leadChannel']
    );
    const application = rootGetters.autopayApplication;

    return submitApplicationToAutopay(bearerToken, httpRequest)(path, application)
      .then((response) => {
        commit('SET_SUBMISSION_RESPONSE', response.data);
        commit('SET_SUBMISSION_STATUS', response.status);

        // We need to commit the App Id (AP Number)
        // so it is available to the Autopilot Store plugin
        commit('SET_APP_ID', rootGetters['global/autopayNumber']);

        // We should only set this time if the submission was successful
        // In the marketingDictionary.js file we use this value to determine if
        // the user successfully submitted their application when updating Autopilot
        commit('SET_SUBMISSION_TIME', new Date());
      })
      .catch((error) => {
        commit('SET_SUBMISSION_ERROR', error);
        commit('SET_SUBMISSION_RESPONSE', get(error, 'response.data', null));
        commit('SET_SUBMISSION_STATUS', get(error, 'response.status', null));

        if (!rootGetters['global/isCreditSoftPullError'] && !rootGetters['global/isNotFoundStatusCode']) {
          reportError(error, { extra: extractErrorInformation(error) });
        }
      });
  },
  submitApplicationToCreditSnap ({ commit, dispatch, rootGetters, state }) {
    const httpRequest = state.creditSnapHttpRequest;
    const application = rootGetters.creditSnapApplication;

    return submitApplicationToCreditSnap(httpRequest)(application)
      .then((response) => {
        commit('SET_SUBMISSION_RESPONSE', response.data);
        commit('SET_SUBMISSION_STATUS', response.status);

        // We should only set this time if the submission was successful
        // In the marketingDictionary.js file we use this value to determine if
        // the user successfully submitted their application when updating Autopilot
        commit('SET_SUBMISSION_TIME', new Date());
      })
      .catch((error) => {
        commit('SET_SUBMISSION_ERROR', error);
        commit('SET_SUBMISSION_RESPONSE', get(error, 'response.data', null));
        commit('SET_SUBMISSION_STATUS', get(error, 'response.status', null));

        reportError(error, { extra: extractErrorInformation(error) });
      });
  },
  submitApplicationToConsumerPortal ({ commit, dispatch, rootGetters, state }) {
    const bearerToken = rootGetters['leadChannel/bearerToken'];
    const httpRequest = state.consumerPortalHttpRequest;

    const path = getConsumerPortalApplicationPath(
      rootGetters['leadChannel/organizationCode'],
      rootGetters['leadChannel/leadChannel']
    );
    const application = rootGetters.consumerPortalApplication;

    return submitApplicationToConsumerPortal(bearerToken, httpRequest)(path, application)
      .then((response) => {
        commit('SET_SUBMISSION_RESPONSE', response.data);
        commit('SET_SUBMISSION_STATUS', response.status);

        // We need to commit the App Id (AP Number)
        // so it is available to the Autopilot Store plugin
        commit('SET_APP_ID', rootGetters['global/autopayNumber']);

        // We should only set this time if the submission was successful
        // In the marketingDictionary.js file we use this value to determine if
        // the user successfully submitted their application when updating Autopilot
        commit('SET_SUBMISSION_TIME', new Date());
      })
      .catch((error) => {
        commit('SET_SUBMISSION_ERROR', error);
        commit('SET_SUBMISSION_RESPONSE', get(error, 'response.data', null));
        commit('SET_SUBMISSION_STATUS', get(error, 'response.status', null));

        if (!rootGetters['global/isCreditSoftPullError'] && !rootGetters['global/isNotFoundStatusCode']) {
          reportError(error, { extra: extractErrorInformation(error) });
        }
      });
  },
  submitApplicationToRateGenius ({ commit, dispatch, rootGetters, state }) {
    const httpRequest = state.nxgHttpRequest;
    const application = rootGetters.rateGeniusApplication;

    return submitApplicationToRateGenius(httpRequest)(application)
      .then((response) => {
        commit('SET_SUBMISSION_RESPONSE', response.data);
        commit('SET_SUBMISSION_STATUS', response.status);

        // We need to commit the App Id
        // so it is available to the Autopilot Store plugin
        commit('SET_APP_ID', rootGetters['global/rateGeniusApplicationId']);

        // We should only set this time if the submission was successful
        // In the marketingDictionary.js file we use this value to determine if
        // the user successfully submitted their application when updating Autopilot
        commit('SET_SUBMISSION_TIME', new Date());
      })
      .catch((error) => {
        commit('SET_SUBMISSION_ERROR', error);
        commit('SET_SUBMISSION_RESPONSE', get(error, 'response.data', null));
        commit('SET_SUBMISSION_STATUS', get(error, 'response.status', null));

        reportError(error, { extra: extractErrorInformation(error) });
      });
  },
  submitOffer ({ dispatch, rootGetters }) {
    const actionName = getOfferActionName({
      shouldSubmitToCreditSnap: rootGetters['leadChannel/shouldSubmitToCreditSnap'],
      shouldSubmitToRateGenius: rootGetters['leadChannel/shouldSubmitToRateGenius']
    });

    return dispatch(actionName);
  },
  submitOfferToCreditSnap ({ state, rootGetters }) {
    const applicationId = rootGetters['global/offerApplicationId'];
    const payload = rootGetters.creditSnapOffer;

    return submitOfferToCreditSnap(state.creditSnapHttpRequest)(applicationId, payload)
      .catch((error) => {
        reportError(error, { extra: extractErrorInformation(error) });
      });
  }
};
