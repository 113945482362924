import { WorkflowService } from '../../core/service/workflow';
import { Workflow } from '../../core/service/workflow/workflow';
import DefaultNoIntent from '../../workflows/defaultNoIntent';
import offers from '../../workflows/offers';
import vehicleFirst from '../../workflows/vehicleFirst';
import vehicleSelectionAndLoanFirst from '../../workflows/vehicleSelectionAndLoanFirst';

const customerInfoFirst = [
  {
    name: 'forms.home',
    next: 'forms.pre'
  },
  {
    name: 'forms.pre',
    next: 'forms.contact',
    previous: 'forms.home'
  },
  {
    name: 'forms.contact',
    next: ({ hasJointApplicant } = {}) => {
      return hasJointApplicant ? 'forms.contact.joint' : 'vehicle.year';
    },
    previous: 'forms.pre'
  },
  {
    name: 'forms.contact.joint',
    next: 'forms.residence.joint',
    previous: 'forms.contact'
  },
  {
    name: 'forms.residence.joint',
    next: 'forms.employment.joint',
    previous: 'forms.contact.joint'
  },
  {
    name: 'forms.employment.joint',
    next: 'vehicle.year',
    previous: 'forms.residence.joint'
  },
  {
    name: 'vehicle.year',
    next: 'vehicle.make',
    previous: ({ hasJointApplicant } = {}) => {
      return hasJointApplicant ? 'forms.employment.joint' : 'forms.contact';
    }
  },
  {
    name: 'vehicle.make',
    next: 'vehicle.model',
    previous: 'vehicle.year'
  },
  {
    name: 'vehicle.model',
    next: 'vehicle.trim',
    previous: 'vehicle.make'
  },
  {
    name: 'vehicle.trim',
    next: 'vehicle.details',
    previous: 'vehicle.model'
  },
  {
    name: 'vehicle.details',
    next: 'forms.residence',
    previous: 'vehicle.trim'
  },
  {
    name: 'forms.residence',
    next: 'forms.employment',
    previous: 'vehicle.details'
  },
  {
    name: 'forms.employment',
    next: 'review',
    previous: 'forms.residence'
  },
  {
    name: 'review',
    next: 'loading',
    previous: 'forms.employment'
  },
  {
    name: 'loading',
    next: ({ legacyAppId } = {}) => {
      return typeof legacyAppId !== 'undefined' && legacyAppId !== null ? {
        name: 'thanks.id',
        params: { id: legacyAppId }
      } : 'thanks';
    },
    previous: 'review'
  }
];

/**
 * Service factory for WorkflowService
 *
 * For this example we are using the Factory Object to demonstrate the internals
 *
 *
 */
export default {
  require: ['routerService', 'initialQuery', 'localStorage', 'randomizeWorkflows', 'randomizeWorkflowsSponsors', 'ingressService'],
  init: (routerService, initialQuery, localStorage, randomizeWorkflows, randomizeWorkflowsSponsors, ingressService) => {
    const workflowService = new WorkflowService(routerService);

    workflowService.register('default', new Workflow(vehicleSelectionAndLoanFirst));
    workflowService.register('custinfofirst', new Workflow(customerInfoFirst));
    workflowService.register('vehandloanfirst', new Workflow(vehicleSelectionAndLoanFirst));
    workflowService.register('defaultnointent', new Workflow(DefaultNoIntent));
    workflowService.register('vehiclefirst', new Workflow(vehicleFirst));
    workflowService.register('offers', new Workflow(offers));

    let chosenWorkflow = 'default';

    const sponsorId = ingressService.actualAttribution.sponsorId;
    /*
     * If workflows are randomized, get a random workflow. If not, get `default`.
     * We are unable to set bools in the container - it will accept true, but not false so we use a string.
     */
    if (randomizeWorkflows === 'true' && randomizeWorkflowsSponsors.includes(sponsorId)) {
      chosenWorkflow = workflowService.getRandomWorkflow();
    }

    try {
      // try to find a flow from localstorage if none try and flow name from initial query, else random.
      const activeFlow = initialQuery.getWorkflowName() || localStorage.get('activeFlow', chosenWorkflow);
      workflowService.setActiveFlow(activeFlow);
    } catch (e) {
      // if requested workflow not registered set to random.
      workflowService.setActiveFlow(chosenWorkflow);
      console.warn('Workflow could not be found. Using: ' + chosenWorkflow);
    }

    // preserves selected workflow to avoid showing returning users different workflows
    localStorage.set('activeFlow', workflowService.activeFlow);

    return workflowService;
  }
};
