import Vue from 'vue';
import VueMeta from 'vue-meta';

import './setup';
import { serviceContainer } from './di';
import { initializeSentry } from './core/errors/initializeSentry';
import { reportError } from './core/errors/reportError';
import store from './store/index';
import GTM from './plugins/gtm';
import CookieConsentPlugin from './plugins/cookie-consent';
import AutopayChatPlugin from './plugins/autopay-chat';
import LiveChatPlugin from './plugins/vue-livechat';
import VWO from './plugins/vwo';
import Beacon from './plugins/beacon';
import { getAndSetLeadChannel } from './utilities/getAndSetLeadChannel';
import { getGtmId } from './utilities/getGtmId';
import { storage } from './utilities/storage';
import { getAndSetOriginMarketingSourceUrl } from './utilities/getAndSetOriginMarketingSourceUrl';
import { getBrandNameKey } from './utilities/getBrandNameKey';
import { getWorkflow } from './utilities/getWorkflow';
import { getAppEnvironment } from './utilities/getAppEnvironment';
import { getAndSetApplicationType } from './utilities/getAndSetApplicationType';

initializeSentry();

// this will run after Sentry has handled the error
Vue.config.errorHandler = (error, vm, info) => {
  if (process.env.APP_ENV === 'local') {
    console.error(error, vm, info);
  }
};

Vue.use(Beacon, {
  appEnvironment: getAppEnvironment()(),
  brandName: getBrandNameKey()(),
  document
});

Vue.use(VWO, {
  vwoId: process.env.VWO_ID,
  window
});

Vue.use(CookieConsentPlugin, {
  brandName: getBrandNameKey()()
});

Vue.use(GTM, {
  gtmId: getGtmId()(),
  appEnvironment: getAppEnvironment()(),
  brandName: getBrandNameKey()(),
  window
});

Vue.use(VueMeta);

// Vue Router
const router = serviceContainer.vueRouter;

// Attach some of our services to vue instance
Vue.use((Vue) => {
  // dependency injection attached to vue instance as $di
  Vue.prototype.$di = serviceContainer;

  // instantiate the service to set attribution
  serviceContainer.get('ingressService');
  serviceContainer.get('vuexStoreService');
});

router.beforeEach((to, from, next) => {
  const environment = process.env.APP_ENV;

  // hook into GTM dataLayer for pageview logging
  if (to && from && to.path !== from.path) {
    const leadChannelBrand = store.getters['leadChannel/brand'];
    const leadChannelCode = store.getters['leadChannel/leadChannel'];
    const sponsorId = store.getters['trackingData/sponsorId'];

    window && window.dataLayer && window.dataLayer.push({
      event: 'pageview',
      brand: leadChannelBrand,
      leadChannel: leadChannelCode,
      sponsorId
    });
  }

  if (environment === 'local') {
    next();
    return;
  }

  const stateCheck = store.getters['global/hasSeenHome'];
  const guarded = !to.matched.some(record => record.meta.unguarded);

  if (guarded && !stateCheck) next({ name: 'forms.home' });
  else next();
});

const leadChannel = getAndSetLeadChannel()();

const originMarketingURL = getAndSetOriginMarketingSourceUrl()(leadChannel);
store.dispatch('global/setOriginMarketingSourceUrl', originMarketingURL);

const applicationType = getAndSetApplicationType(leadChannel)();
store.dispatch('global/setApplicationType', applicationType);

const urlSearchParams = new URLSearchParams(window.location.search);
store.dispatch('trackingData/setSponsorPartnerIdFromURLSearchParams', urlSearchParams);

store.dispatch('feature/start')
  .then(() => store.dispatch('leadChannel/loadLeadChannelInformation', leadChannel))
  .then(() => {
    if (store.getters['feature/isConnectionStatusEnabled']) {
      store.dispatch('connection/startNetworkStatusCheck');
    }
  })
  .then(() => {
    const workflow = getWorkflow(store.getters['leadChannel/workflow']);

    // cache the workflow in local storage for future use
    storage.set('activeFlow', workflow);

    // inform the workflow service of the change
    serviceContainer
      .get('workflowService')
      .setActiveFlow(workflow);

    // update tackingData with the workflow change.
    // prior to loading the lead channel information, the tracking data workflow
    // might of been defaulted if a workflow was not found in local storage
    store.dispatch('trackingData/setWorkflow', workflow);
  })
  .then(() => {
    if (store.getters['leadChannel/isRateGeniusBrand']) {
      Vue.use(LiveChatPlugin, {
        token: process.env.LIVE_CHAT_TOKEN,
        window,
        document
      });
    } else if (store.getters['feature/isPodiumChatWidgetEnabled']) {
      Vue.use(AutopayChatPlugin, {
        window,
        document
      });
    }
  })
  .then(() => {
    // finally, boot up the application
    window.fastTrack = new Vue({ router, store }).$mount('#app');

    const successEvent = document.createEvent('Event');
    successEvent.initEvent('FastTrackLoaded', true, true);
    document.dispatchEvent(successEvent);
  })
  .catch((error) => {
    reportError(error);
    const failureEvent = document.createEvent('Event');
    failureEvent.initEvent('FastTrackLoadFailed', true, true);
    document.dispatchEvent(failureEvent);
  });
