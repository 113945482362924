import { consumerPortalEmploymentTypeMap } from '../values/application';

/**
 * Returns the Consumer Portal employment type for the provided FastTrack employment type
 *
 * @param {string} fastTrackEmploymentType
 * @returns {string|null}
 */
export const toConsumerPortalEmploymentType = (
  fastTrackEmploymentType
) => consumerPortalEmploymentTypeMap[fastTrackEmploymentType] || null;
