import Vue from 'vue';
import Vuex from 'vuex';
import VueMask from 'v-mask';

import applicant from './modules/applicant';
import connection from './modules/connection';
import employment from './modules/employment';
import feature from './modules/feature';
import global from './modules/global';
import leadChannel from './modules/leadChannel';
import navigation from './modules/navigation';
import offers from './modules/offers';
import prequal from './modules/prequal';
import mailCodeOffer from './modules/mailCodeOffer';
import residence from './modules/residence';
import trackingData from './modules/trackingData';
import vehicle from './modules/vehicle';
import integrity from './modules/integrity';
import { autopayApplication } from './getters/autopayApplication';
import { creditSnapApplication } from './getters/creditSnapApplication';
import { creditSnapOffer } from './getters/creditSnapOffer';
import { consumerPortalApplication } from './getters/consumerPortalApplication';
import { isProduction } from './getters/isProduction';
import { rateGeniusApplication } from './getters/rateGeniusApplication';

import { ErrorReportingPlugin } from '../plugins/error-reporting';

Vue.use(Vuex);
Vue.use(VueMask);

export default new Vuex.Store({
  modules: {
    applicant,
    connection,
    employment,
    feature,
    global,
    leadChannel,
    navigation,
    offers,
    prequal,
    mailCodeOffer,
    residence,
    trackingData,
    vehicle,
    integrity
  },
  getters: {
    autopayApplication,
    creditSnapApplication,
    creditSnapOffer,
    consumerPortalApplication,
    isProduction,
    rateGeniusApplication
  },
  strict: process.env.APP_ENV !== 'prod',
  actions: {
    resetAll ({ commit }) {
      commit('applicant/RESET');
      // leadChannel store module not included in resetAll so all pages work as expected, including the Thanks page
      commit('connection/RESET');
      commit('employment/RESET');
      commit('feature/RESET');
      commit('global/RESET');
      commit('residence/RESET');
      commit('trackingData/RESET');
      commit('vehicle/RESET');
      commit('navigation/RESET');
      commit('prequal/RESET');
      commit('mailCodeOffer/RESET');
      commit('offers/RESET');
      commit('integrity/RESET');
    }
  },
  plugins: [ErrorReportingPlugin]
});
