
/**
 * Assembles a error payload message
 */
export class ErrorMessagePayload {
  constructor (errorMessage, { FIRST_NAME, LAST_NAME, EMAIL, PHONE } = {}) {
    this.errorMessage = errorMessage;
    this.firstName = FIRST_NAME;
    this.lastName = LAST_NAME;
    this.email = EMAIL;
    this.mobilePhone = PHONE;
  }
}

export class ApplicationSubmissionError extends Error {
  constructor (message) {
    super(JSON.stringify(message));
    this.name = 'ApplicationSubmissionError';
  }
}

/**
 * Thrown when an application post is rejected by backend system
 */
export class ApplicationSubmissionRejected extends ApplicationSubmissionError {
  constructor (message, errorCode) {
    super(message);
    this.name = 'ApplicationSubmissionRejected';
    this.errorCode = errorCode;
  }
}

/**
 * Thrown when the application fails to post to backend
 */
export class ApplicationSubmissionNetworkError extends ApplicationSubmissionError {
  constructor (message) {
    super(message);
    this.name = 'ApplicationSubmissionNetworkError';
  }
}
