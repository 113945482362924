import get from 'lodash/get';

export const creditSnapOffer = (state, getters, rootState, rootGetters) => {
  const offerSelected = rootGetters['global/offerSelected'];

  const payload = {
    offerId: get(offerSelected, 'offerId'),
    offerVariationId: get(offerSelected, 'offerVariationId'),
    term: get(offerSelected, 'term'),
    apr: get(offerSelected, 'apr'),
    emi: get(offerSelected, 'emi'),
    ach: false,
    loanAmount: rootGetters['vehicle/payoffAmountAsNumber'],
    status: 'OFFER_SELECTED'
  };

  return payload;
};
