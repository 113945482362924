import get from 'lodash/get';
import { toAutopayEmploymentType } from '../../utilities/toAutopayEmploymentType';
import { toAutopayOwnershipStatus } from '../../utilities/toAutopayOwnershipStatus';
import { autopayRelationshipType } from '../../values/application';

export const autopayApplication = (state, getters, rootState, rootGetters) => {
  const primaryApplicantCurrentAddress = rootGetters['residence/primaryApplicantCurrentAddress'];

  const primaryApplicantData = {
    type: 'APPLICANT',
    firstName: rootGetters['applicant/primaryApplicantFirstName'],
    lastName: rootGetters['applicant/primaryApplicantLastName'],
    emailAddress: rootGetters['applicant/primaryApplicantEmailAddress'],
    cellPhone: rootGetters['applicant/primaryApplicantMobileNumber'],
    birthDate: rootGetters['applicant/primaryApplicantDateOfBirthAsStringInUtcFormat'],
    ssn: rootGetters['applicant/primaryApplicantSocialSecurityNumber'],
    termsAndConditionsOptIn: {
      agreesToTerms: true,
      userAgent: navigator.userAgent
    },
    primaryResidence: {
      ownershipStatus: toAutopayOwnershipStatus(rootGetters['residence/primaryApplicantCurrentResidenceType']),
      monthlyPaymentAmount: rootGetters['residence/primaryApplicantMonthlyPaymentAtCurrentResidenceAsNumber'],
      monthsAtResidence: rootGetters['residence/primaryApplicantTotalNumberOfMonthsAtCurrentResidence'],
      physicalAddress: {
        lineOne: get(primaryApplicantCurrentAddress, 'street'),
        lineTwo: get(primaryApplicantCurrentAddress, 'street2'),
        city: get(primaryApplicantCurrentAddress, 'city'),
        province: get(primaryApplicantCurrentAddress, 'state'),
        postalCode: get(primaryApplicantCurrentAddress, 'zipCode')
      }
    },
    currentEmployment: {
      employerName:
        rootGetters['employment/primaryApplicantCurrentEmploymentEmployerName'],
      employmentPosition:
        rootGetters['employment/primaryApplicantCurrentEmploymentJobTitle'],
      employmentType:
        toAutopayEmploymentType(
          rootGetters['employment/primaryApplicantCurrentEmploymentTypeValue']
        ),
      monthsAtEmployer:
        rootGetters['employment/primaryApplicantTotalNumberOfMonthsAtCurrentEmployment'],
      yearlyIncomeAmount:
        rootGetters['employment/primaryApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber']
    }
  };

  if (rootGetters['employment/primaryApplicantHasAdditionalIncome']) {
    primaryApplicantData.additionalIncome = {
      amount: rootGetters['employment/primaryApplicantAdditionalIncomeAmountAsNumber'],
      frequency: rootGetters['employment/primaryApplicantAdditionalIncomeFrequencyValue'],
      source: rootGetters['employment/primaryApplicantAdditionalIncomeSource']
    };
  }

  if (rootGetters['employment/primaryApplicantHasPreviousEmploymentType']) {
    primaryApplicantData.previousEmployment = {
      employerName:
        rootGetters['employment/primaryApplicantPreviousEmploymentEmployerName'],
      employmentPosition:
        rootGetters['employment/primaryApplicantPreviousEmploymentJobTitle'],
      employmentType:
        toAutopayEmploymentType(
          rootGetters['employment/primaryApplicantPreviousEmploymentTypeValue']
        ),
      monthsAtEmployer:
        rootGetters['employment/primaryApplicantTotalNumberOfMonthsAtPreviousEmployment']
    };
  }

  if (rootGetters['residence/primaryApplicantHasResidedAtCurrentResidenceLessThanTwoYears']) {
    const primaryApplicantPreviousAddress = rootGetters['residence/primaryApplicantPreviousAddress'];

    primaryApplicantData.previousResidence = {
      monthsAtResidence: rootGetters['residence/primaryApplicantTotalNumberOfMonthsAtPreviousResidence'],
      physicalAddress: {
        lineOne: get(primaryApplicantPreviousAddress, 'street'),
        lineTwo: get(primaryApplicantPreviousAddress, 'street2'),
        city: get(primaryApplicantPreviousAddress, 'city'),
        province: get(primaryApplicantPreviousAddress, 'state'),
        postalCode: get(primaryApplicantPreviousAddress, 'zipCode')
      }
    };
  }

  const payload = {
    applicationType: rootGetters['global/applicationType'],

    applicants: [primaryApplicantData],

    vehicle: {
      // [TODO] https://autopay.atlassian.net/browse/AP-864, https://autopay.atlassian.net/browse/APEX-2666
      //        in testing, the LOS worked better with matching ymmt than the id
      //        ideally, we want to use the id longer term, it "should" be more accurate
      // thirdPartyIdentifier: {
      //   partner: vehicleDetailProviders.CHROMEDATA,
      //   id: trim.id
      // },
      year: rootGetters['vehicle/year'],
      make: rootGetters['vehicle/makeName'],
      model: rootGetters['vehicle/modelName'],
      trim: rootGetters['vehicle/trimName'],
      mileage: rootGetters['vehicle/mileageAsNumber']
    },

    originMarketingSourceUrl: rootGetters['global/originMarketingSourceUrl'],

    metaData: rootGetters['trackingData/metaData'],

    // the ap[a] query param contains any passed in application data
    // with keys mapping to/defined in docs.autopay.com
    // More information can also be found here: `docs/attribution/README.md`
    ...rootGetters['trackingData/autopayData']
  };

  if (rootGetters['mailCodeOffer/hasMailCode']) {
    payload.financeAmount = rootGetters['mailCodeOffer/payoffAmount'];

    payload.vehicle.lien = {
      payoffAmount: rootGetters['mailCodeOffer/payoffAmount']
    };
  }

  // when provided a prequal amount we assign both the financeAmount and
  // vehicle lien payoffAmount
  if (rootGetters['prequal/hasPrequalAmount']) {
    // the LOS expects this value in cases where the application type is
    // Purchase or Refinance.
    payload.financeAmount = rootGetters['prequal/amount'];

    // the LOS expects this value in cases where the application type is
    // NOT Purchase.
    payload.vehicle.lien = {
      payoffAmount: rootGetters['prequal/amount']
    };
  }

  // vehicle payoffAmount is currently provided in the FullDetails component.
  // the FullDetails component is currently used on lead channels that are configured to
  // use the offers workflow. We want to capture the value here incase submissions to
  // CreditSnap fail in which we result submitting the application to AutoPay.
  if (rootGetters['vehicle/payoffAmountAsNumber']) {
    payload.financeAmount = rootGetters['vehicle/payoffAmountAsNumber'];

    payload.vehicle.lien = {
      payoffAmount: rootGetters['vehicle/payoffAmountAsNumber']
    };
  }

  // vehicle purchase price is currently available in the vehicle Details component,
  // but only when the applicationType is set to PURCHASE
  if (rootGetters['vehicle/purchasePriceAsNumber']) {
    payload.financeAmount = rootGetters['vehicle/purchasePriceAsNumber'];
  }

  if (rootGetters['applicant/hasJointApplicant']) {
    const jointApplicantCurrentAddress = rootGetters['residence/jointApplicantCurrentAddress'];
    const relationshipTypeValue = rootGetters['applicant/jointApplicantRelationshipToPrimaryApplicantTypeValue'];

    const jointApplicantData = {
      type: 'CO_APPLICANT',
      firstName: rootGetters['applicant/jointApplicantFirstName'],
      lastName: rootGetters['applicant/jointApplicantLastName'],
      emailAddress: rootGetters['applicant/jointApplicantEmailAddress'],
      cellPhone: rootGetters['applicant/jointApplicantMobileNumber'],
      birthDate: rootGetters['applicant/jointApplicantDateOfBirthAsStringInUtcFormat'],
      ssn: rootGetters['applicant/jointApplicantSocialSecurityNumber'],
      relationshipType: autopayRelationshipType[relationshipTypeValue],
      termsAndConditionsOptIn: {
        agreesToTerms: true
      },
      primaryResidence: {
        ownershipStatus: toAutopayOwnershipStatus(rootGetters['residence/jointApplicantCurrentResidenceType']),
        monthlyPaymentAmount: rootGetters['residence/jointApplicantMonthlyPaymentAtCurrentResidenceAsNumber'],
        monthsAtResidence: rootGetters['residence/jointApplicantTotalNumberOfMonthsAtCurrentResidence'],
        physicalAddress: {
          lineOne: get(jointApplicantCurrentAddress, 'street'),
          lineTwo: get(jointApplicantCurrentAddress, 'street2'),
          city: get(jointApplicantCurrentAddress, 'city'),
          province: get(jointApplicantCurrentAddress, 'state'),
          postalCode: get(jointApplicantCurrentAddress, 'zipCode')
        }
      },
      currentEmployment: {
        employmentPosition:
          rootGetters['employment/jointApplicantCurrentEmploymentJobTitle'],
        employerName:
          rootGetters['employment/jointApplicantCurrentEmploymentEmployerName'],
        employmentType:
          toAutopayEmploymentType(
            rootGetters['employment/jointApplicantCurrentEmploymentTypeValue']
          ),
        monthsAtEmployer:
          rootGetters['employment/jointApplicantTotalNumberOfMonthsAtCurrentEmployment'],
        yearlyIncomeAmount:
          rootGetters['employment/jointApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber']
      }
    };

    if (rootGetters['employment/jointApplicantHasAdditionalIncome']) {
      jointApplicantData.additionalIncome = {
        amount: rootGetters['employment/jointApplicantAdditionalIncomeAmountAsNumber'],
        frequency: rootGetters['employment/jointApplicantAdditionalIncomeFrequencyValue'],
        source: rootGetters['employment/jointApplicantAdditionalIncomeSource']
      };
    }

    if (rootGetters['employment/jointApplicantHasPreviousEmploymentType']) {
      jointApplicantData.previousEmployment = {
        employerName:
          rootGetters['employment/jointApplicantPreviousEmploymentEmployerName'],
        employmentPosition:
          rootGetters['employment/jointApplicantPreviousEmploymentJobTitle'],
        employmentType:
          toAutopayEmploymentType(
            rootGetters['employment/jointApplicantPreviousEmploymentTypeValue']
          ),
        monthsAtEmployer:
          rootGetters['employment/jointApplicantTotalNumberOfMonthsAtPreviousEmployment']
      };
    }

    payload.applicants.push(jointApplicantData);
  }

  return payload;
};
