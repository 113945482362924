import autopayLogoSrc from 'Images/autopay/autopay-logo.png';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { reportError } from '../../../core/errors/reportError';
import { reportInfoMessage } from '../../../core/errors/reportInfoMessage';
import {
  CREDIT_SNAP,
  DEFAULT_BRAND_LEAD_CHANNEL,
  DEFAULT_BRAND_NAME,
  DEFAULT_BRAND_ORGANIZATION_CODE,
  RATE_GENIUS
} from '../../../values/brand';
import {
  getApplicationSubmissionApi,
  getBearerToken,
  getFaviconURL,
  getFooterLogoAltText,
  getFooterLogoHeight,
  getFooterLogoURL,
  getFooterLogoWidth,
  getLeadChannelBrand,
  getLeadChannelCode,
  getLeadChannelDisplayLabel,
  getLeadChannelEmailAddress,
  getLeadChannelPhoneNumber,
  getLeadChannelTrackingPixelType,
  getLeadChannelTrackingPixelUrl,
  getMailCodeSource,
  getOrganizationCode,
  getPrimaryLogoAltText,
  getPrimaryLogoHeight,
  getPrimaryLogoURL,
  getPrimaryLogoWidth,
  getStylesheet,
  getUseWeightedSubmissionApi,
  getWorkflow
} from './selectors';

const parseMessage = (message) => {
  try {
    return JSON.parse(message);
  } catch {
    return message;
  }
};

/**
 * Extracts the error details from the error message
 * and reports it to Sentry
 *
 * @param {String} leadChannel
 * @returns {(Error) => void}
 */
const captureContentfulError = (leadChannel) => (error) => {
  const errorDetails = parseMessage(error.message);

  const errorMessage = isString(errorDetails)
    ? errorDetails
    : errorDetails.message;

  error.message = errorMessage;

  reportError(
    error,
    { extra: { errorDetails, leadChannel } }
  );
};

export const actions = {
  async loadLeadChannelInformation ({ commit, dispatch, rootGetters }, leadChannel) {
    let response;

    try {
      response = await rootGetters['leadChannel/client'].getEntries({
        content_type: 'fastTrackBranding',
        // To search on references, we need to include the content type id
        // @link https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters/search-on-references
        'fields.leadChannel.sys.contentType.sys.id': 'leadChannel',
        'fields.leadChannel.fields.leadChannelCode': leadChannel,
        // By default, a response includes the first level of linked content
        // The maximum is 10, but with the current schema 2 should be sufficient
        // (fastTrackBranding (0) -> leadChannel (1) -> leadOrganization (2))
        // @link https://www.contentful.com/developers/docs/concepts/links/
        include: 2
      });
    } catch (error) {
      captureContentfulError(leadChannel)(error);

      return dispatch('loadDefaultLeadChannelInformation');
    }

    const items = get(response, 'items', []);

    if (!response || isEmpty(items)) {
      return dispatch('loadDefaultLeadChannelInformation');
    }

    const branding = find(
      items,
      { fields: { leadChannel: { fields: { leadChannelCode: leadChannel } } } }
    );

    const useWeightedSubmissionApi = getUseWeightedSubmissionApi(branding);
    const applicationSubmissionApi = getApplicationSubmissionApi(branding);

    if (useWeightedSubmissionApi && applicationSubmissionApi === CREDIT_SNAP) {
      commit('SET_APPLICANT_SUBMISSION_API', rootGetters['feature/creditSnapWeightedSubmissionApi']);
    } else if (useWeightedSubmissionApi && applicationSubmissionApi === RATE_GENIUS) {
      commit('SET_APPLICANT_SUBMISSION_API', rootGetters['feature/rateGeniusWeightedSubmissionApi']);
    } else {
      commit('SET_APPLICANT_SUBMISSION_API', applicationSubmissionApi);
    }

    commit('SET_LEAD_CHANNEL_BEARER_TOKEN', getBearerToken()(branding));
    commit('SET_LEAD_CHANNEL_EMAIL_ADDRESS', getLeadChannelEmailAddress(branding));
    commit('SET_LEAD_CHANNEL_FAVICON', getFaviconURL(branding));
    commit('SET_LEAD_CHANNEL_FOOTER_LOGO', {
      alt: getFooterLogoAltText(branding),
      height: getFooterLogoHeight(branding),
      src: getFooterLogoURL(branding),
      width: getFooterLogoWidth(branding)
    });
    commit('SET_LEAD_CHANNEL_HEADER_LOGO', {
      alt: getPrimaryLogoAltText(branding),
      height: getPrimaryLogoHeight(branding),
      src: getPrimaryLogoURL(branding),
      width: getPrimaryLogoWidth(branding)
    });
    commit('SET_LEAD_CHANNEL_BRAND', getLeadChannelBrand(branding));
    commit('SET_LEAD_CHANNEL_CODE', getLeadChannelCode(branding));
    commit('SET_LEAD_CHANNEL_MAIL_CODE_SOURCE', getMailCodeSource(branding));
    commit('SET_LEAD_CHANNEL_NAME', getLeadChannelDisplayLabel(branding));
    commit('SET_LEAD_CHANNEL_ORGANIZATION_CODE', getOrganizationCode(branding));
    commit('SET_LEAD_CHANNEL_PHONE_NUMBER', getLeadChannelPhoneNumber(branding));
    commit('SET_LEAD_CHANNEL_STYLESHEET', getStylesheet(branding));
    commit('SET_LEAD_CHANNEL_WORKFLOW', getWorkflow(branding));
    commit(
      'SET_LEAD_CHANNEL_TRACKING_PIXEL_TYPE',
      getLeadChannelTrackingPixelType(branding)
    );
    commit(
      'SET_LEAD_CHANNEL_TRACKING_PIXEL_URL',
      getLeadChannelTrackingPixelUrl(branding)
    );
  },

  loadDefaultLeadChannelInformation ({ commit, rootGetters }) {
    commit('SET_LEAD_CHANNEL_BEARER_TOKEN', process.env.AUTOPAY_BEARER_TOKEN);
    commit('SET_LEAD_CHANNEL_CODE', DEFAULT_BRAND_LEAD_CHANNEL);

    commit('SET_LEAD_CHANNEL_FOOTER_LOGO', {
      alt: null,
      height: null,
      src: autopayLogoSrc,
      width: null
    });

    commit('SET_LEAD_CHANNEL_HEADER_LOGO', {
      alt: 'AUTOPAY logo',
      height: null,
      src: autopayLogoSrc,
      width: null
    });

    commit('SET_LEAD_CHANNEL_NAME', DEFAULT_BRAND_NAME);
    commit('SET_LEAD_CHANNEL_ORGANIZATION_CODE', DEFAULT_BRAND_ORGANIZATION_CODE);
  },
  reportPixelIFrame ({ rootGetters }) {
    const leadChannel = rootGetters['leadChannel/leadChannel'];
    reportInfoMessage(`iFrame tracking pixel report: ${leadChannel}`, {
      extra: {
        brand: rootGetters['leadChannel/brand'],
        leadChannel
      }
    });
  },
  reportPixelImg ({ rootGetters }) {
    const leadChannel = rootGetters['leadChannel/leadChannel'];
    reportInfoMessage(`Image tracking pixel report: ${leadChannel}`, {
      extra: {
        brand: rootGetters['leadChannel/brand'],
        leadChannel
      }
    });
  },
  reportPixelPostback ({ rootGetters }) {
    const leadChannel = rootGetters['leadChannel/leadChannel'];
    reportInfoMessage(`Postback tracking pixel report: ${leadChannel}`, {
      extra: {
        brand: rootGetters['leadChannel/brand'],
        leadChannel
      }
    });
  },
  reportPixelPostbackFail ({ rootGetters }) {
    const leadChannel = rootGetters['leadChannel/leadChannel'];
    reportError(`Postback tracking pixel report: ${leadChannel}`, {
      extra: {
        brand: rootGetters['leadChannel/brand'],
        leadChannel,
        url: rootGetters['leadChannel/trackingPixelUrl']
      }
    });
  }
};
