import get from 'lodash/get';

export const getAppEnvironment = (
  getNameFromEnvironment = () => process.env.APP_ENV,
  getHostnameFromWindow = () => get(window, 'location.hostname', '')
) => () => {
  const hostname = getHostnameFromWindow() || '';
  const appEnvironment = getNameFromEnvironment() || 'dev';

  return hostname.includes('refinance')
    ? `${appEnvironment}Refinance`
    : appEnvironment;
};
