import { consumerPortalHttpRequest } from '../axios.config';

/**
 * POSTs the consumer portal application to the provided path using
 * the axios instance and bearer token
 *
 * @param {string} bearerToken
 * @param {AxiosInstance} httpRequest
 * @returns {(path: string, application: Object) => Promise<*>}
 */
export const submitApplicationToConsumerPortal = (
  bearerToken,
  httpRequest = consumerPortalHttpRequest
) => (
  path,
  consumerPortalApplication
) =>
  httpRequest.post(
    path,
    JSON.stringify(consumerPortalApplication || {}),
    {
      headers: {
        authorization: `Bearer ${bearerToken}`,
        'content-type': 'application/json'
      }
    }
  );
