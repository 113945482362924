export const mutations = {
  ADD_MARKETING_AUTOMATION_FIELD: (state, automationFields) => {
    state.marketingAutomation = { ...state.marketingAutomation, ...automationFields };
  },
  SET_ALL_UTMS: (state, utms) => {
    state.utm = utms;
  },
  SET_ATTRIBUTION_LOG: (state, attributionLog) => {
    state.attributionLog = { ...state.attributionLog, ...attributionLog };
  },
  SET_CAMPAIGN_ID: (state, campaignId) => {
    state.campaignId = campaignId;
  },
  SET_GOOGLE_VISITOR_ID: (state, googleVisitorId) => {
    state.googleVisitorId = googleVisitorId;
  },
  SET_LENDER_ID: (state, lenderId) => {
    state.lenderId = lenderId;
  },
  SET_LIVE_CHAT_ID: (state, liveChatId) => {
    state.liveChatId = liveChatId;
  },
  SET_PREQUAL_ID: (state, prequalId) => {
    state.prequalId = prequalId;
  },
  SET_REFER_ID: (state, referId) => {
    state.referId = referId;
  },
  SET_SPONSOR_ID: (state, sponsorId) => {
    state.sponsorId = sponsorId;
  },
  SET_SPONSOR_CLICK_ID: (state, sponsorClickId) => {
    state.sponsorClickId = sponsorClickId;
  },
  SET_SPONSOR_PARTNER_ID: (state, sponsorPartnerId) => {
    state.sponsorPartnerId = sponsorPartnerId;
  },
  SET_TEMPLATE_ID: (state, templateId) => {
    state.templateId = templateId;
  },
  SET_UTM_VALUE: (state, { name, value }) => {
    state.utm[name] = value;
  },
  SET_WORKFLOW: (state, activeWorkflow) => {
    state.workflow = activeWorkflow;
  }
};
