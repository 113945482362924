import { isNonEmptyString } from '../utilities/isNonEmptyString';

export default [
  {
    name: 'forms.home',
    next: ({ reason } = {}) => {
      return reason ? 'vehicle.year' : 'forms.pre';
    }
  },
  {
    name: 'forms.pre',
    next: 'vehicle.year',
    previous: 'forms.home'
  },
  {
    name: 'vehicle.year',
    next: 'vehicle.make',
    previous: 'forms.pre'
  },
  {
    name: 'vehicle.make',
    next: 'vehicle.model',
    previous: 'vehicle.year'
  },
  {
    name: 'vehicle.model',
    next: 'vehicle.trim',
    previous: 'vehicle.make'
  },
  {
    name: 'vehicle.trim',
    next: 'vehicle.full-details',
    previous: 'vehicle.model'
  },
  {
    name: 'vehicle.full-details',
    next: 'forms.contact',
    previous: 'vehicle.trim'
  },
  {
    name: 'forms.contact',
    next: ({ hasJointApplicant } = {}) => {
      return hasJointApplicant ? 'forms.contact.joint' : 'forms.residence';
    },
    previous: 'vehicle.full-details'
  },
  {
    name: 'forms.contact.joint',
    next: 'forms.residence.joint',
    previous: 'forms.contact'
  },
  {
    name: 'forms.residence.joint',
    next: 'forms.employment.joint',
    previous: 'forms.contact.joint'
  },
  {
    name: 'forms.employment.joint',
    next: 'forms.residence',
    previous: 'forms.residence.joint'
  },
  {
    name: 'forms.residence',
    next: 'forms.employment',
    previous: ({ hasJointApplicant } = {}) => {
      return hasJointApplicant ? 'forms.contact.joint' : 'forms.contact';
    }
  },
  {
    name: 'forms.employment',
    next: 'review',
    previous: 'forms.residence'
  },
  {
    name: 'review',
    next: 'loading',
    previous: 'forms.employment'
  },
  {
    name: 'loading',
    next: ({ hasOffers = false, legacyAppId, shouldTransition = false } = {}) => {
      if (isNonEmptyString(legacyAppId)) {
        return {
          name: 'thanks.id',
          params: { id: legacyAppId }
        };
      } else if (hasOffers) {
        return 'offers';
      } else if (!hasOffers && shouldTransition) {
        return 'transition-autopay';
      }

      return 'thanks';
    },
    previous: 'review'
  },
  {
    name: 'offers',
    next: 'thanks',
    previous: 'review'
  },
  {
    name: 'transition-autopay',
    next: ({ legacyAppId } = {}) => {
      return isNonEmptyString(legacyAppId) ? {
        name: 'thanks.id',
        params: { id: legacyAppId }
      } : 'thanks';
    },
    previous: 'review'
  }
];
