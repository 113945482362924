import Vue from 'vue';
import {
  titleCaseFilter,
  currencyFilter,
  numberFilter,
  percentFilter
} from './filters';

Vue.filter('titleCase', titleCaseFilter);
Vue.filter('currency', currencyFilter);
Vue.filter('number', numberFilter);
Vue.filter('percent', percentFilter);
