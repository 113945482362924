/**
 * Builds the full Tresl Calendar redirect URL
 *
 * @param {string} baseURL
 * @returns {(treslCalendarURL: string) => URL}
 */
export const getTreslRedirectURL = (
  baseURL = window.location.origin
) => (
  treslCalendarURL
) => {
  const redirect = new URL('/tresl-thanks', baseURL);

  if (!treslCalendarURL) {
    return redirect;
  }

  const url = new URL(treslCalendarURL);
  url.searchParams.set('RURL', redirect.toString());

  return url;
};
