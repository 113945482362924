import {
  legacyHttpRequest,
  nxgHttpRequest
} from '../core/api/axios.config';
import { ServiceContainer } from '../core/container';
import { ApplicationService } from '../core/service/application';
import { activeLenders } from '../values/lender';
import { activeSponsors } from '../values/sponsor';
import { Api } from '../core/api';
import localstorage from 'store';
import expire from 'store/plugins/expire';
import { CookieService } from '../core/service/cookie';
import { QueryService } from '../core/service/query';
import { RouterService } from '../core/service/route';
import WorkflowFactory from './factory/workflow';
import IngressFactory from './factory/ingress';
import StorageServiceFactory from './factory/storage';
import VueRouter from 'vue-router';
import { routerLinks } from '../router';

localstorage.addPlugin(expire);

/**
 * Configuration for services
 *
 * @property {WorkflowService} workflowService
 * @property {ApplicationService} applicationService
 * @property {IngressService} ingressService
 * @property {StorageService} vuexStoreService
 * @property {AxiosInstance} nxgHttpRequest
 * @property {AxiosInstance} legacyHttpRequest
 * @property {array} nxgLenders
 * @property {array} nxgSponsors
 * @property {decimal} defaultNxgThrottle
 * @property {localstorage} localstorage
 * @property {CookieService} cookieService
 * @property {QueryService} initialQuery
 * @property {VueRouter} vueRouter - registered in src/index.js
 */
const serviceContainer = new ServiceContainer();

/*
 * Dependencies
 *
 * register creates a dependency entry immediately with no injection
 */

serviceContainer.register('location', window.location);
serviceContainer.register('nxgHttpRequest', nxgHttpRequest);
serviceContainer.register('legacyHttpRequest', legacyHttpRequest);
serviceContainer.register('nxgLenders', [
  activeLenders.WEBSTER,
  activeLenders.AFFINITY,
  activeLenders.CAROLINATRUST,
  activeLenders.GARDENSAVINGS,
  activeLenders.HIWAYFCU,
  activeLenders.MATADORS,
  activeLenders.FIRSTINVESTORS,
  activeLenders.EAGLECOMMUNITYCU,
  activeLenders.BRIGHTSTARCU,
  activeLenders.ELEMENTSFINANCIAL,
  activeLenders.ANDREWSFCU,
  activeLenders.FINANCIALPARTNERS,
  activeLenders.HIWAYLENDPRO,
  activeLenders.AFFINITYLENDPRO,
  activeLenders.TROPICALFCU,
  activeLenders.FIRSTCLASSAMERICAN,
  activeLenders.SEATTLEMETRO,
  activeLenders.EECU,
  activeLenders.ALLINCREDITUNION
]);

serviceContainer.register('nxgSponsors', [
  activeSponsors.LENDINGTREE,
  activeSponsors.CARS_DIRECT,
  activeSponsors.CARSDIRECT_EMAIL,
  activeSponsors.AUTO_CREDIT_EXPRESS,
  activeSponsors.NATURAL_INTELLIGENCE,
  activeSponsors.NATURAL_INTELIGENCE_2,
  activeSponsors.CARFUNDY,
  activeSponsors.FLEX,
  activeSponsors.JUNO_STUDENT_LOAN_REFINANCE,
  activeSponsors.COMPLETE,
  activeSponsors.GOOGLE,
  activeSponsors.EXPERIAN,
  activeSponsors.EXPERIAN_2,
  activeSponsors.GOOGLE_NEW_AD,
  activeSponsors.GOOGLE_03,
  activeSponsors.GOOGLE_04,
  activeSponsors.GOOGLE_05,
  activeSponsors.RATEGENIUS_EXPERIAN_EMAIL
]);

serviceContainer.register('defaultNxgThrottle', process.env.NXG_THROTTLE);

serviceContainer.register('randomizeWorkflows', process.env.RANDOMIZE_WORKFLOWS);
serviceContainer.register('randomizeWorkflowsSponsors', JSON.parse(process.env.RANDOMIZE_WORKFLOWS_SPONSORS || '[]'));

serviceContainer.register('localStorage', localstorage);

serviceContainer.register('cookieService', new CookieService(document.cookie));

// registering a make call allows us to register with injection
serviceContainer.register('initialQuery',
  serviceContainer.make(['location', (location) => new QueryService(location)])
);

serviceContainer.register('vueRouter', new VueRouter(routerLinks));

/*
 * Factories
 *
 * bind creates on demand singletons with injection provided
 */

serviceContainer.bind(
  'api',
  [
    'nxgHttpRequest',
    'legacyHttpRequest',
    (nxgHttpRequest, legacyHttpRequest) =>
      new Api(nxgHttpRequest, legacyHttpRequest)
  ]
);

serviceContainer.bind(
  'applicationService',
  [
    'api',
    (api) => new ApplicationService(api)
  ]
);

// vue router is bound later in /src/index.js
serviceContainer.bind('routerService', ['vueRouter', (vueRouter) => new RouterService(vueRouter)]);

serviceContainer.bind('workflowService', WorkflowFactory);

// ingressService is non-lazy and require their deps to be declared already. So we need them at the end.
// StorageServiceFactory is lazy
serviceContainer.bind('ingressService', IngressFactory);

serviceContainer.bind('vuexStoreService', StorageServiceFactory);

export { serviceContainer };
