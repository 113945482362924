import { formRoutes } from './forms';
import { mailRoutes } from './mail';
import { vehicleRoutes } from './vehicle';

// Global components
const App = () => import(/* webpackChunkName: "initial-payload" */ '../components/App');
const LandingPage = () => import(/* webpackChunkName: "initial-payload" */ '../components/LandingPage');
const UnifiedPage = () => import(/* webpackChunkName: "initial-payload" */ '../components/unified/UnifiedPage');
const EmptyVueRouterForNesting = () => import(/* webpackChunkName: "initial-payload" */ '../components/global/EmptyVueRouterForNesting');
const PageNotFound = () => import('../components/global/PageNotFound');

// Common components
const Thanks = () => import('../components/forms/Thanks');
const Review = () => import('../components/forms/Review');
const Maintenance = () => import('../components/forms/Maintenance');
const Offer = () => import('../components/forms/Offer');
const Offers = () => import('../components/forms/Offers');
const Home = () => import('../components/forms/Home');
const PrequalRoot = () => import('../components/prequal/Root');
const PrequalWelcome = () => import('../components/prequal/Welcome');
const Transition = () => import('../components/creditSnap/Transition');
const Loading = () => import('../components/forms/Loading');

const routerLinks = {
  mode: 'history',
  routes: [
    {
      path: '/',
      component: App,
      children: [
        // Landing page
        {
          path: '',
          name: 'forms.home',
          meta: {
            unguarded: true
          },
          component: Home
        },
        formRoutes,
        mailRoutes,
        vehicleRoutes,
        // Application review page
        {
          path: 'review',
          name: 'review',
          component: Review
        },
        // Transition page, but not actually nested
        // under review (this url pattern was requested)
        {
          path: 'review/transition-autopay',
          name: 'transition-autopay',
          component: Transition,
          meta: { unguarded: false }
        },
        // Thank you page
        {
          path: 'thanks',
          component: EmptyVueRouterForNesting,
          children: [
            {
              path: '',
              name: 'thanks',
              component: Thanks,
              meta: {
                unguarded: true
              }
            },
            {
              path: ':id',
              name: 'thanks.id',
              component: Thanks,
              meta: { unguarded: true }
            }
          ]
        },
        {
          path: 'prequal',
          component: EmptyVueRouterForNesting,
          props: true,
          children: [
            {
              path: '',
              name: '',
              component: PrequalRoot,
              props: (route) => ({
                amount: Number(route.query.amount || 0),
                apr: Number(route.query.apr || 0),
                payment: Number(route.query.payment || 0),
                term: Number(route.query.term || 0)
              }),
              meta: {
                unguarded: true
              }
            },
            {
              path: 'welcome',
              name: 'prequal.welcome',
              component: PrequalWelcome,
              meta: {
                unguarded: true
              }
            }
          ]
        },
        {
          path: 'offers',
          name: 'offers',
          component: Offers,
          meta: { unguarded: false }
        },
        {
          path: '/offer/:offerUuid',
          name: 'offer',
          meta: {
            unguarded: true
          },
          component: Offer
        },
        {
          path: 'loading',
          name: 'loading',
          meta: {
            unguarded: true
          },
          component: Loading
        }
      ]
    },
    {
      path: '/maintenance',
      component: Maintenance,
      meta: {
        unguarded: true
      }
    },
    {
      path: '/savings',
      component: LandingPage,
      meta: {
        unguarded: true
      }
    },
    {
      path: '/unified',
      component: UnifiedPage,
      meta: {
        unguarded: true
      }
    },
    {
      path: '*',
      component: PageNotFound,
      meta: {
        unguarded: true
      }
    }
  ]
};

export {
  routerLinks
};
