import get from 'lodash/get';
import { isNonEmptyString } from '../utilities/isNonEmptyString';

const autopayRefinanceStageEnvironment = { auth: 'REOPXtVcZ5fDQvia0k6ecg', preview: 'env-3' };
const autopayStageEnvironment = { auth: '8Nz6wHaZIgwmlnLZ_CN6Mg', preview: 'env-43' };
const autopayDevEnvironment = { auth: 'sdotOmBYj7gJg9o9C37Svw', preview: 'env-44' };

const rateGeniusRefinanceStageEnvironment = { auth: '5rN3OVrvRfgrK9TS_kFKrg', preview: 'env-3' };
const rateGeniusStageEnvironment = { auth: '5ZqZErqpBjwUBvsXHRWrPw', preview: 'env-185' };
const rateGeniusDevEnvironment = { auth: '0NU1paNrmWJ4JSeJJGb2zw', preview: 'env-186' };

const treslRefinanceStageEnvironment = { auth: 'aNrFc2X5JE9SlDWAT6KwBg', preview: 'env-3' };
const treslStageEnvironment = { auth: 'jOkJ9GTkEXsHTK1nxASA', preview: 'env-58' };
const treslDevEnvironment = { auth: '7ySET3NY2K1cb7WJV3yNBQ', preview: 'env-59' };

const envMap = {
  autopay: {
    stage: autopayStageEnvironment,
    stageRefinance: autopayRefinanceStageEnvironment,
    dev: autopayDevEnvironment,
    local: autopayDevEnvironment
  },
  rategenius: {
    stage: rateGeniusStageEnvironment,
    stageRefinance: rateGeniusRefinanceStageEnvironment,
    dev: rateGeniusDevEnvironment,
    local: rateGeniusDevEnvironment
  },
  tresl: {
    stage: treslStageEnvironment,
    stageRefinance: treslRefinanceStageEnvironment,
    dev: treslDevEnvironment,
    local: treslDevEnvironment
  }
};

/**
 * Returns the search parameters for the provided application environment
 *
 * @param {string} appEnvironment
 * @param {string} brandName
 * @returns {string}
 */
const getQueryString = (appEnvironment, brandName = 'autopay') => {
  // These search parameters are only necessary for non-production environments
  // Using startsWith here so we catch: `prod` and `prodRefinance`
  if (isNonEmptyString(appEnvironment) && appEnvironment.startsWith('prod')) {
    return '';
  }

  const { auth, preview } = get(
    envMap,
    [brandName, appEnvironment],
    autopayDevEnvironment
  );

  return `&gtm_auth=${auth}&gtm_preview=${preview}&gtm_cookies_win=x`;
}

export default {
  /**
   * Adds GTM Tracking code
   *
   * @param {Vue} vue
   * @param {Object} options
   * @param {string} options.appEnvironment - process.env.APP_ENV
   * @param {string} options.gtmId - process.env.GTM_ID
   * @param {boolean} options.brandName - The Savings Group Brand Name lowercased
   * @param {Object} options.window
   *
   * @returns {void}
   */
  install (vue, options) {
    const gtmId = options.gtmId;
    const qs = getQueryString(options.appEnvironment, options.brandName);
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl+qs;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', gtmId);
  }
}
