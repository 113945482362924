/**
 * Tracking Data State Object
 *
 * @type object
 */
export const state = {
  attributionLog: {
    attributions: []
  },
  campaignId: null,
  googleVisitorId: null,
  lenderId: null,
  liveChatId: null,
  marketingAutomation: {
    Email: null,
    FirstName: null,
    LastName: null,
    unsubscribed: true,
    custom: {}
  },
  prequalId: null,
  referId: null,
  sponsorId: null,
  sponsorClickId: null,
  sponsorPartnerId: null,
  templateId: null,
  utm: {
    source: null,
    campaign: null,
    medium: null,
    term: null,
    content: null
  },
  workflow: null
};
