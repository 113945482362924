import {
  autopayHttpRequest,
  creditSnapHttpRequest,
  consumerPortalHttpRequest,
  nxgHttpRequest
} from '../../../core/api/axios.config';
import { DEFAULT_APPLICATION_TYPE } from '../../../values/application';

export const state = {
  // the amountFinanced value is also stored in the vehicle store module
  // however, we need this value in this store as well so we can calculate
  // the monthly payment for the offers that come back from CreditSnap
  amountFinanced: null,
  appId: null,
  applicationType: DEFAULT_APPLICATION_TYPE,
  creditSnapHttpRequest,
  consumerPortalHttpRequest,
  hasSeenHome: false,
  autopayHttpRequest,
  isSubmittingApplication: false,
  hasApplicationBeenSubmitted: false,
  loadingPercent: 1,
  nxgHttpRequest,
  originMarketingSourceUrl: null,
  theSavingsGroupContentError: null,
  theSavingsGroupPrivacyPolicy: null,
  theSavingsGroupTerms: null,
  reasonForLoanChange: null,
  selectedOfferId: null,
  submissionError: null,
  submissionResponse: null,
  submissionStatus: null,
  submissionTime: null,
  treslScheduleError: null,
  width: 0
};
