export const mutations = {
  SET_OFFER_MAIL_CODE (state, mailCode) {
    state.mailCode = mailCode;
  },
  SET_OFFER_REQUEST_HAS_FINISHED (state, finished) {
    state.hasRequestFinished = finished;
  },
  SET_OFFER_RESPONSE_BODY (state, responseBody) {
    state.responseBody = responseBody;
  },
  SET_OFFER_RESPONSE_STATUS_CODE (state, statusCode) {
    state.responseStatusCode = statusCode;
  }
};
