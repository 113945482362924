import { popularMakes } from '../values/vehicle';
import { isNonEmptyString } from './isNonEmptyString';
import { pascalCase } from './String';

// reverse the popular makes list outside of the function to ensure
// it retains the correct order each time the function is called
const makeList = popularMakes.reverse();

/**
 * Returns the popular makes list
 *
 * If provided a vehicle make, it will be moved/added to the end of the list
 *
 * @param {string|null} vehicleMakeName
 * @returns {[string]}
 */
export const getPopularMakesList = (vehicleMakeName = null) => {
  if (isNonEmptyString(vehicleMakeName)) {
    const makeParam = pascalCase(vehicleMakeName.toLowerCase());

    if (makeList.includes(makeParam)) {
      const makeIndex = makeList.findIndex(make => make === makeParam);
      makeList.splice(makeIndex, 1);
      makeList.push(makeParam);
    } else {
      makeList.push(makeParam);
    }
  }

  return makeList;
};
