import { consumerPortalIncomeSourceMap } from '../values/application';

/**
 * Returns the Consumer Portal income type for the provided
 * FastTrack additional income source dropdown value
 *
 * @param {string} additionalIncomeSourceValue
 * @returns {string|null}
 */
export const toConsumerPortalIncomeSource = (additionalIncomeSourceValue) =>
  consumerPortalIncomeSourceMap[additionalIncomeSourceValue] || null;
