import add from 'date-fns/add';
import isAfter from 'date-fns/isAfter';
import isValid from 'date-fns/isValid';

import { RATEGENIUS_NEW_APPLICATION, RG_DIRECT_MAIL } from '../../../values/sponsor';

/**
 * Generic Attribution
 *
 * @returns {boolean}
 */
export class Attribution {
  constructor (
    {
      attributedAt,
      autopayData,
      leadChannel,
      referData,
      sponsorClickId,
      sponsorId,
      type
    } = {}
  ) {
    // attributedAt could be the string 'Invalid Date' instead of null or undefined;
    // wrapping it in the date constructure then checking isValid to coerce a boolean
    this.attributedAt = (attributedAt && isValid(new Date(attributedAt))) ? new Date(attributedAt).toISOString() : new Date().toISOString();
    this.autopayData = autopayData;
    this.leadChannel = leadChannel;
    this.referData = referData || null;
    this.sponsorClickId = sponsorClickId || null;
    this.sponsorId = parseInt(sponsorId || RATEGENIUS_NEW_APPLICATION);
    this.type = 'Attribution';
  }

  /**
   * Merges some of the properties from the new attribution
   *
   * @param {attribution} newAttribution
   * @return {this}
   */
  merge (newAttribution) {
    if (newAttribution.autopayData) {
      this.autopayData = { ...this.autopayData, ...newAttribution.autopayData };
    }

    if (newAttribution.leadChannel) {
      this.leadChannel = newAttribution.leadChannel;
    }

    return this;
  }
}

/**
 * CampaignAttribution type Attribution
 */
export class CampaignAttribution extends Attribution {
  constructor (
    {
      sponsorId,
      sponsorClickId,
      campaignId,
      attributedAt,
      timeToLive,
      referData
    }
  ) {
    super({ sponsorId, sponsorClickId, attributedAt, referData });
    this.type = 'Campaign';
    this.campaignId = campaignId || null;
    this.referData = referData || null;

    this.setTtl(timeToLive);
  }

  setTtl (timeToLive) {
    this.timeToLive = null;
    this.expiresAfter = null;

    if (timeToLive !== null || typeof timeToLive === 'undefined') {
      this.timeToLive = parseInt(timeToLive);
      this.expiresAfter = add(
        new Date(this.attributedAt),
        { days: this.timeToLive }
      );
    }
  }

  /**
   * Is this campaign expired? if no ttl given it will never expire
   *
   * @returns {boolean}
   */
  isExpired () {
    return this.expiresAfter === null
      ? false
      : isAfter(new Date(), new Date(this.expiresAfter));
  }
}

export class DirectMailAttribution extends Attribution {
  constructor (
    {
      sponsorClickId,
      attributedAt,
      lenderId,
      referData
    }
  ) {
    super({ sponsorId: RG_DIRECT_MAIL, sponsorClickId, attributedAt, referData });
    this.type = 'DirectMail';
    this.lenderId = lenderId;
    this.referData = referData || null;
  }
}
