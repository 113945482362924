
export const actions = {
  checkSubmitApplicationIntegrity ({ getters, rootGetters }) {
    if (window && window.dataLayer) {
      const hasJointApplicant = rootGetters['applicant/hasJointApplicant'];

      if (!getters.isPrimaryApplicantEmailAddressValid) {
        window.dataLayer.push({ event: 'primary_applicant_email_is_submitted_empty' });
      }

      if (hasJointApplicant && !getters.isJointApplicantEmailAddressValid) {
        window.dataLayer.push({ event: 'joint_applicant_email_is_submitted_empty' });
      }

      if (!getters.isVehicleMakeNameValid) {
        window.dataLayer.push({ event: 'vehicle_make_is_submitted_empty' });
      }

      if (!getters.isVehicleModelNameValid) {
        window.dataLayer.push({ event: 'vehicle_model_is_submitted_empty' });
      }

      if (!getters.isVehicleYearValid) {
        window.dataLayer.push({ event: 'vehicle_year_is_submitted_empty' });
      }
    }
  }
};
