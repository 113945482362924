/**
 * Due to IP restrictions in non-production Tresl environments,
 * we need to swap out the URL returned by their API to a "kong" route
 * so we can navigate users to their site
 *
 * @example for non-production environments
 *  - Original URL: http://tresl-preprod.online.mytresl.com/#/schedule-appointment?request=M6G7W5Y6UJ0PKG70PITF
 *  - Expected URL: https://tresl-online-preprod.rategenius.io/#/schedule-appointment?request=M6G7W5Y6UJ0PKG70PITF
 *
 * Due to both URL's containing hashes, we cannot use the built-in URL class
 * because the URL string will become mangled like this:
 *
 * https://tresl-online-preprod.rategenius.io/?request=M6G7W5Y6UJ0PKG70PITF#/schedule-appointment
 *
 * Which is obviously incorrect, but due to time constraints
 * we cannot spend any more time fixing this issue.
 *
 * @param {Object} options
 * @param {boolean} options.isProduction
 * @param {string} options.nonProductionURLString
 *
 * @returns {(originalURL: string) => string}
 */
export const convertTreslCalendarURL = ({
  isProduction = false,
  nonProductionURLString
} = {}) => (
  originalURL
) => {
  if (isProduction || !nonProductionURLString) {
    return originalURL;
  }

  let calendarURL = nonProductionURLString instanceof URL
    ? nonProductionURLString.href
    : nonProductionURLString;

  const [, originalURLSearchParamsString] = originalURL instanceof URL
    ? originalURL.href.split('?')
    : originalURL.split('?');

  if (!calendarURL.includes('?')) {
    calendarURL += `?${originalURLSearchParamsString}`;
  } else {
    calendarURL += `&${originalURLSearchParamsString}`;
  }

  return calendarURL;
};
