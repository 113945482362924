import { StoreModifier } from '../../utilities/storeModifiers';
import { actions } from './integrity/actions';
import { getters } from './integrity/getters';
import { mutations } from './integrity/mutations';
import { state } from './integrity/state';

export default new StoreModifier({
  actions,
  getters,
  mutations,
  namespaced: true,
  state
});
