import random from 'lodash/random';
import { extractErrorInformation } from './../../../core/api/extractErrorInformation';
import { offers } from '../../../utilities/mockOffers';
import { getOfferByUuid } from '../../../core/api/getOfferByUuid';
import { processAndCompareOffer } from '../../../utilities/processAndCompareOffer';
import { reportError } from '../../../core/errors/reportError';

export const actions = {
  fetchOffer: ({ dispatch, commit, state }, offerUuid) => {
    if (state.shouldMockOffer === true) {
      return dispatch('fetchMockOffer', null, { root: false });
    }
    if (offerUuid !== null) {
      commit('SET_OFFER_UUID', offerUuid);
    }
    return getOfferByUuid(state.nxgHttpRequest)(state.offerUuid).then((response) => {
      const offerValues = processAndCompareOffer(response.data.data);
      const offer = {
        ...response.data.data,
        ...offerValues
      };
      commit('SET_OFFER_APPLICANT_DATA', true);
      commit('LOAD_OFFER', offer);
    }
    ).catch((error) => {
      dispatch('setOfferFailed', true);
      reportError(error, { extra: extractErrorInformation(error) });
      throw error;
    }
    );
  },
  fetchMockOffer: ({ commit }) => {
    const lowerIndex = 0;
    const upperIndex = offers.length - 1;
    const fetchedOffer = offers[random(lowerIndex, upperIndex)];

    const offerValues = processAndCompareOffer(fetchedOffer);

    const offer = {
      ...fetchedOffer,
      ...offerValues
    };
    commit('LOAD_OFFER', offer);
  },
  setOfferUuid: ({ commit }, offerUuid) => {
    commit('SET_OFFER_UUID', offerUuid);
  },
  setApplicationShouldMockOffer: ({ commit }, shouldMockOffer) => {
    commit('SET_SHOULD_MOCK_OFFER', shouldMockOffer);
  },
  setOfferFailed: ({ commit }, offerFailed) => {
    commit('SET_OFFER_FAILED', offerFailed);
  }
};
