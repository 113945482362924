import { isNonEmptyString } from './isNonEmptyString';
import { storage } from './storage';

/**
 * Fetches the origin marketing URL from local storage OR window if this is the
 * first time seeing this browser
 *
 * @param {() => string} getUrlFromWindow
 * @param {(leadChannel: string) => string|undefined} getUrlFromStorage
 * @param {(leadChannel: string, url: string) => void} setUrlInStorage
 * @returns {(leadChannel: string) => string}
 */
export const getAndSetOriginMarketingSourceUrl = (
  getUrlFromWindow = () => window.location.href,
  getUrlFromStorage = (leadChannel) =>
    storage.get(`originMarketingSourceUrl:${leadChannel}`),
  setUrlInStorage = (leadChannel, url) =>
    storage.set(`originMarketingSourceUrl:${leadChannel}`, url)
) => (leadChannel) => {
  const urlFromStorage = getUrlFromStorage(leadChannel);

  if (isNonEmptyString(urlFromStorage)) {
    return urlFromStorage;
  }

  const urlFromWindow = getUrlFromWindow();

  setUrlInStorage(leadChannel, urlFromWindow);
  return urlFromWindow;
};
