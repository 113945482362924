import get from 'lodash/get';

/**
 * Gets returns a GTM ID based on the host name
 *
 * @returns {() => string}
 */
export const getGtmId = (
  getHostnameFromWindow = () => get(window, 'location.hostname', '')
) => () => {
  const hostname = getHostnameFromWindow();
  const isRefinance = hostname.includes('refinance');

  if (hostname.includes('rategenius')) {
    return isRefinance
      ? process.env.RATEGENIUS_REFINANCE_GTM_ID
      : process.env.RATEGENIUS_GTM_ID;
  } else if (hostname.includes('tresl')) {
    return isRefinance
      ? process.env.TRESL_REFINANCE_GTM_ID
      : process.env.TRESL_GTM_ID;
  }

  return isRefinance
    ? process.env.AUTOPAY_REFINANCE_GTM_ID
    : process.env.AUTOPAY_GTM_ID;
};
