export const state = {
  hasModelImagesRequestFinished: false,
  hasModelsRequestFinished: false,
  make: null,
  makes: [],
  payoffAmount: null,
  payoffAmountConfirmed: false,
  payoffAmountConfirmedValue: null,
  purchasePrice: null,
  purchasePriceConfirmed: false,
  purchasePriceConfirmedValue: null,
  mileage: null,
  mileageConfirmed: false,
  mileageConfirmedValue: null,
  model: null,
  models: [],
  trim: null,
  trims: [
    {
      id: 1,
      name: 'Past',
      links: null
    }
  ],
  year: null,
  years: []
};
