export default {
  /**
   * Installs the Vue LiveChat application
   *
   * @param vue
   * @param options {{document: object, window: object, token: string}}
   */
  install (vue, options) {
    options.window.__lc = {};
    options.window.__lc.license = options.token;
    options.window.__lc.ga_version = 'gtm';
    options.window.__lc.providedBy = 'vue-livechat';

    var lc = options.document.createElement('script');
    lc.type = 'text/javascript';
    lc.async = true;
    lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
    document.body.appendChild(lc);

    vue.prototype.$liveChat = options.window.__lc;
  }
};