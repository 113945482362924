export default {
  /**
   * Adds Beacon based on the app environment and The Savings Group brand name
   *
   * @param vue
   * @param options
   * @param {string} options.appEnvironment - process.env.APP_ENV
   * @param {boolean} options.brandName - The Savings Group Brand Name lowercased
   * @param {Object} options.document
   */
  install (vue, options) {
    const { document, brandName, appEnvironment } = options;

    const appEnvironmentIsProd = appEnvironment === 'prod';

    const folder = [brandName, appEnvironmentIsProd ? '2023' : 'test'].join('_');

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://cdn.evgnet.com/beacon/thesavingsgroup/${folder}/scripts/evergage.min.js`;

    document.head.appendChild(script);
  }
}