const EmptyVueRouterForNesting = () => import(/* webpackChunkName: "initial-payload" */ '../components/global/EmptyVueRouterForNesting');

const Root = () => import('../components/mail/Root');
const Enter = () => import('../components/mail/Enter');
const Welcome = () => import('../components/mail/Welcome');

// Mail
export const mailRoutes = {
  path: 'mail',
  component: EmptyVueRouterForNesting,
  children: [
    {
      path: '',
      name: 'mail',
      component: Root,
      meta: { unguarded: true }
    },
    {
      path: 'enter',
      name: 'mail.enter',
      component: Enter,
      meta: { unguarded: true }
    },
    {
      path: 'welcome',
      name: 'mail.welcome',
      component: Welcome,
      meta: { unguarded: true }
    }
  ]
};
