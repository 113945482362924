import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import toInteger from 'lodash/toInteger';
import orderBy from 'lodash/orderBy';
import lowerCase from 'lodash/lowerCase';
import { isNonEmptyString } from '../../../utilities/isNonEmptyString';

import {
  getVehicleMakesSorterFunction
} from '../../../utilities/getVehicleMakesSorterFunction';
import { getPopularMakesList } from '../../../utilities/getPopularMakesList';

export const actions = {
  async getNadaMakes ({ commit, rootGetters }) {
    commit('SET_MODELS', []);

    const getVehicleMakes = rootGetters['vehicle/getVehicleMakesFunction'];
    const vehicleMakes = await getVehicleMakes(rootGetters['vehicle/year']);

    const vehicleMakesSorter = getVehicleMakesSorterFunction(
      getPopularMakesList(rootGetters['vehicle/makeName'])
    );

    commit('SET_MAKES', sortBy(vehicleMakes, [vehicleMakesSorter]));
  },
  async getNadaModels ({ commit, rootGetters }) {
    commit('SET_MODELS_REQUEST_FINISHED', false);
    commit('SET_MODEL_IMAGES_REQUEST_FINISHED', false);

    const getVehicleModels = rootGetters['vehicle/getVehicleModelsFunction'];

    let vehicleModels = [];

    try {
      const originalModels = await getVehicleModels({
        year: rootGetters['vehicle/year'],
        makeId: rootGetters['vehicle/makeId'],
        modelName: rootGetters['vehicle/modelName']
      });

      // ensure vehicle models are sorted by name
      // so users can find their model more quickly
      // (AUTOPAY's vehicle API returns them in a random(-ish) order)
      vehicleModels = sortBy(
        originalModels,
        (model) => get(model, 'name', '').toLowerCase()
      );
    } finally {
      commit('SET_MODELS_REQUEST_FINISHED', true);
    }

    commit('SET_MODELS', vehicleModels);

    try {
      const getVehicleModelsWithImage = rootGetters['vehicle/getVehicleModelsWithImageFunction'];

      const vehicleModelsWithImage = await getVehicleModelsWithImage({
        year: rootGetters['vehicle/year'],
        makeName: rootGetters['vehicle/makeName'],
        vehicleModels
      });

      commit('SET_MODELS', vehicleModelsWithImage);
    } finally {
      commit('SET_MODEL_IMAGES_REQUEST_FINISHED', true);
    }
  },
  async getNadaTrims ({ commit, rootGetters }) {
    const getVehicleTrims = rootGetters['vehicle/getVehicleTrimsFunction'];

    let vehicleTrims = await getVehicleTrims({
      year: rootGetters['vehicle/year'],
      makeId: rootGetters['vehicle/makeId'],
      modelId: rootGetters['vehicle/modelId']
    });

    // sort state selected trim to the top of the list
    const selectedTrimName = rootGetters['vehicle/trimName'];
    if (selectedTrimName) {
      vehicleTrims = orderBy(vehicleTrims, (trim) => lowerCase(trim.name) === lowerCase(selectedTrimName), ['desc']);
    }

    commit('SET_TRIMS', vehicleTrims);
  },
  getNadaYears ({ commit, rootGetters }) {
    if (rootGetters['vehicle/hasLoadedYears']) {
      return;
    }

    const currentDate = new Date();

    const isBeforeMarch = rootGetters['vehicle/isBeforeMarch'];

    // if the current month is January or February we don't want
    // to show the current year in the UI
    let maximumYear = currentDate.getFullYear() + (isBeforeMarch ? 0 : 1);
    const minimumYear = maximumYear - Number(process.env.MINIMUM_VEHICLE_YEARS);

    const years = [];

    for (; maximumYear >= minimumYear; maximumYear--) {
      years.push({ id: maximumYear, name: maximumYear });
    }

    commit('SET_YEARS', years);
  },
  async getVinData ({ commit, dispatch, rootGetters }) {
    const getVehicleVinData = rootGetters['vehicle/getVehicleVinDataFunction'];

    const vehicleData = await getVehicleVinData(
      rootGetters['offers/vehicleVIN']
    );

    const year = get(vehicleData, 'year', null);

    if (year) {
      commit('SET_YEAR', Number(year));
    }

    dispatch('setMakeFromString', get(vehicleData, 'make', null));
    dispatch('setModelFromString', get(vehicleData, 'model', null));
    dispatch('setTrimFromString', get(vehicleData, 'style', null));
  },
  setMake ({ commit }, make) {
    commit('SET_MAKE', make);
  },
  setMakeFromString ({ commit }, make) {
    commit('SET_MAKE', { name: make });
  },
  setPayoffAmount ({ commit }, payoffAmount) {
    commit('SET_PAYOFF_AMOUNT', payoffAmount);

    // we need this set on the global store so we can
    // calculate the monthly payment using offer data
    commit('global/SET_AMOUNT_FINANCED', payoffAmount, { root: true });
  },
  setPayoffAmountConfirmed ({ commit }, payoffAmountConfirmed) {
    commit('SET_PAYOFF_AMOUNT_CONFIRMED', payoffAmountConfirmed);
  },
  setPayoffAmountConfirmedValue ({ commit }, payoffAmountConfirmedValue) {
    commit('SET_PAYOFF_AMOUNT_CONFIRMED_VALUE', payoffAmountConfirmedValue);
  },
  setPurchasePrice ({ commit }, purchasePrice) {
    commit('SET_PURCHASE_PRICE', purchasePrice);
  },
  setPurchasePriceConfirmed ({ commit }, purchasePriceConfirmed) {
    commit('SET_PURCHASE_PRICE_CONFIRMED', purchasePriceConfirmed);
  },
  setPurchasePriceConfirmedValue ({ commit }, purchasePriceConfirmedValue) {
    commit('SET_PURCHASE_PRICE_CONFIRMED_VALUE', purchasePriceConfirmedValue);
  },
  setMileage ({ commit }, mileage) {
    commit('SET_MILEAGE', mileage);
  },
  setMileageConfirmed ({ commit }, mileageConfirmed) {
    commit('SET_MILEAGE_CONFIRMED', mileageConfirmed);
  },
  setMileageConfirmedValue ({ commit }, mileageConfirmedValue) {
    commit('SET_MILEAGE_CONFIRMED_VALUE', mileageConfirmedValue);
  },
  setModel ({ commit }, model) {
    commit('SET_MODEL', model);
  },
  setModelFromString ({ commit }, model) {
    commit('SET_MODEL', { name: model });
  },
  setTrim ({ commit }, trim) {
    commit('SET_TRIM', trim);
  },
  setTrimFromString ({ commit }, trim) {
    commit('SET_TRIM', { name: trim });
  },
  setYear ({ commit }, year) {
    commit('SET_YEAR', year);
  },
  setYearFromString ({ commit }, year) {
    commit(
      'SET_YEAR',
      isNonEmptyString(year) ? toInteger(year) : null
    );
  }
};
