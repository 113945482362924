/**
 * url
 *
 * A utility for working with URls the Autopay way... ish.
 *
 *
 * provides implementation of qs to more closely adhere to spec rfc3986
 * https://github.com/ljharb/qs/issues/163
 * https://www.ietf.org/rfc/rfc3986.txt (Page 12)
  */
import get from 'lodash/get';
import qs from 'qs';

export const autopayUrlParamPrefix = 'ap';

/**
 *
 * @param {Location} location
 * @returns {URLSearchParams}
 *
 * returns the clean URL for URLs that this application utilizes.
 */
export function parseUrl (location) {
  return new URL(location.href.replace('/#', ''));
}

/**
 * replaceBracketEncoding
 *
 * Returns a string where the bracket encoding is a "readable" version.
 * https://github.com/ljharb/qs/issues/163
 * https://www.ietf.org/rfc/rfc3986.txt (Page 12)
 *
 * @param  {String} str
 * @return {String}
 */
function replaceBracketEncoding (str) {
  return str.replace(/%5B/g, '[').replace(/%5D/g, ']');
}

/**
 * parseQueryString
 *
 * custom parser for query strings using qs
 *
 * @param  {String} queryString
 * @return {Object}
 */
export function parseQueryString (queryString) {
  return qs.parse(queryString);
}

/**
 * stringifyQuery
 *
 * Custom stringifycation for queries using qs
 *
 * We want to utilize all of stringify's encoding to ensure we have "valid" RFC3986 Urls.  Technically in RFC 3986 `[`
 * and `]` are safe characters
 * https://github.com/ljharb/qs/issues/163
 * https://www.ietf.org/rfc/rfc3986.txt (Page 12)
 *
 * In lieu of writing a custom encoder we will just replace these on the output.
 *
 * @param  {Object} query
 * @param  {Object} options
 * @return {String}
 */
const defaultOptions = { arrayFormat: 'indices', encode: true, format: 'RFC3986' };
export function stringifyQuery (query, options = defaultOptions) {
  return replaceBracketEncoding(qs.stringify(query, options));
}

/**
 * removeLeadingQuestionMark
 *
 * Removes the "leading" question mark from the URL..
 * necessary for how "url-parse" treats the "query" part.
 *
 * @param  {String} queryParams
 * @return {String}
 */
export const removeLeadingQuestionMark = (queryParams) =>
  (queryParams && queryParams.indexOf('?') === 0 ? queryParams.substring(1) : queryParams)
;

/**
 * getParamFromUrl
 *
 * returns a param in "dot notation".  ie:
 * getParamFromUrl(window.location, `${autopayUrlParamPrefix}.ml`)
 * @param  {String} location
 * @param  {String} param
 * @return {Any}    could be a string or complex type (ie: nested objects that `parseQueryString` is able to understand
 */
export const getParamFromUrl = (location, param) => {
  const parsedUrl = parseUrl(location);
  const searchParams = replaceBracketEncoding(parsedUrl.searchParams.toString());
  const queryParams = parseQueryString(removeLeadingQuestionMark(searchParams || ''));
  return get(queryParams, param);
};
