import get from 'lodash/get';

/**
 * Extracts error information from the request and response
 *
 * @param {Error} error
 * @returns {{ config: Object, originalMessage: string, request: Object|null, requestAttempts: Object|null, responseBody: Object|null, responseHeaders: Object|null, responseStatus: number|null }}
 */
export const extractErrorInformation = (error) => ({
  config: get(error, 'config', null),
  originalMessage: error.message,
  request: get(error, 'request', null),
  requestAttempts: get(error, 'config.axios-retry', null),
  responseBody: JSON.stringify(get(error, 'response.data')),
  responseHeaders: get(error, 'response.headers', null),
  responseStatus: get(error, 'response.status', null)
});
