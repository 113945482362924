import {
  DISABILITY,
  FULL_TIME,
  HOMEMAKER,
  MILITARY,
  OTHER,
  PART_TIME,
  RETIRED,
  SELF_EMPLOYED,
  STUDENT,
  UNEMPLOYED
} from './application';

export const unemployedTypes = [
  RETIRED,
  DISABILITY,
  HOMEMAKER,
  STUDENT,
  UNEMPLOYED
];

export const employmentTypeLabels = [
  { label: 'Full-time', value: FULL_TIME },
  { label: 'Part-time', value: PART_TIME },
  { label: 'Military', value: MILITARY },
  { label: 'Self-employed', value: SELF_EMPLOYED },
  { label: 'Student', value: STUDENT },
  { label: 'Homemaker', value: HOMEMAKER },
  { label: 'Retired', value: RETIRED },
  { label: 'Unemployed', value: UNEMPLOYED },
  { label: 'Disability', value: DISABILITY },
  { label: 'Other', value: OTHER }
];

export const employmentTypeValuesThatRequireEmployerName = [
  FULL_TIME,
  PART_TIME,
  MILITARY,
  SELF_EMPLOYED,
  OTHER
];

export const employmentTypeValuesThatRequireJobTitle = [
  FULL_TIME,
  PART_TIME,
  MILITARY,
  SELF_EMPLOYED,
  OTHER
];

export const defaultPrimaryCurrentEmploymentDurationLabel = 'How long have you been employed?';
export const defaultPrimaryPreviousEmploymentDurationLabelMap = 'How long were you employed?';
export const defaultJointCurrentEmploymentDurationLabel = 'How long have they been employed?';
export const defaultJointPreviousEmploymentDurationLabelMap = 'How long were they employed?';

export const primaryCurrentEmploymentDurationLabelMap = {
  [FULL_TIME]: defaultPrimaryCurrentEmploymentDurationLabel,
  [PART_TIME]: defaultPrimaryCurrentEmploymentDurationLabel,
  [MILITARY]: defaultPrimaryCurrentEmploymentDurationLabel,
  [SELF_EMPLOYED]: defaultPrimaryCurrentEmploymentDurationLabel,
  [STUDENT]: 'How long have you been a student?',
  [HOMEMAKER]: 'How long have you been a homemaker?',
  [RETIRED]: 'How long have you been retired?',
  [UNEMPLOYED]: 'How long have you been unemployed?',
  [DISABILITY]: 'How long have you been receiving disability?',
  [OTHER]: defaultPrimaryCurrentEmploymentDurationLabel
};

export const primaryPreviousEmploymentDurationLabelMap = {
  [FULL_TIME]: defaultPrimaryPreviousEmploymentDurationLabelMap,
  [PART_TIME]: defaultPrimaryPreviousEmploymentDurationLabelMap,
  [MILITARY]: defaultPrimaryPreviousEmploymentDurationLabelMap,
  [SELF_EMPLOYED]: defaultPrimaryPreviousEmploymentDurationLabelMap,
  [STUDENT]: 'How long were you a student?',
  [HOMEMAKER]: 'How long were you a homemaker?',
  [RETIRED]: 'How long were you retired?',
  [UNEMPLOYED]: 'How long were you unemployed?',
  [DISABILITY]: 'How long were you receiving disability?',
  [OTHER]: defaultPrimaryPreviousEmploymentDurationLabelMap
};

export const jointCurrentEmploymentDurationLabelMap = {
  [FULL_TIME]: defaultJointCurrentEmploymentDurationLabel,
  [PART_TIME]: defaultJointCurrentEmploymentDurationLabel,
  [MILITARY]: defaultJointCurrentEmploymentDurationLabel,
  [SELF_EMPLOYED]: defaultJointCurrentEmploymentDurationLabel,
  [STUDENT]: 'How long have they been a student?',
  [HOMEMAKER]: 'How long have they been a homemaker?',
  [RETIRED]: 'How long have they been retired?',
  [UNEMPLOYED]: 'How long have they been unemployed?',
  [DISABILITY]: 'How long have they been receiving disability?',
  [OTHER]: defaultJointCurrentEmploymentDurationLabel
};

export const jointPreviousEmploymentDurationLabelMap = {
  [FULL_TIME]: defaultJointPreviousEmploymentDurationLabelMap,
  [PART_TIME]: defaultJointPreviousEmploymentDurationLabelMap,
  [MILITARY]: defaultJointPreviousEmploymentDurationLabelMap,
  [SELF_EMPLOYED]: defaultJointPreviousEmploymentDurationLabelMap,
  [STUDENT]: 'How long were they a student?',
  [HOMEMAKER]: 'How long were they a homemaker?',
  [RETIRED]: 'How long were they retired?',
  [UNEMPLOYED]: 'How long were they unemployed?',
  [DISABILITY]: 'How long were they receiving disability?',
  [OTHER]: defaultJointPreviousEmploymentDurationLabelMap
};
