import { getHostnameFromWindow } from './getHostnameFromWindow';
import { getLeadChannel } from './getLeadChannel';
import { getLeadChannelFromSearchParams } from './getLeadChannelFromSearchParams';
import { getLeadChannelFromStorage } from './getLeadChannelFromStorage';
import { getSponsorIdFromSearchParams } from './getSponsorIdFromSearchParams';
import { storage } from './storage';

/**
 * Gets and sets the lead channel from search params or local storage
 *
 * @returns {() => string}
*/
export const getAndSetLeadChannel = (
  setLeadChannelInStorage = (value) => storage.set('leadChannel', value),
  getFromSearchParams = getLeadChannelFromSearchParams,
  getFromStorage = getLeadChannelFromStorage,
  getFromWindow = getHostnameFromWindow,
  getSponsorId = getSponsorIdFromSearchParams
) => () => {
  const leadChannel = getLeadChannel(
    getFromSearchParams,
    getFromStorage,
    getFromWindow,
    getSponsorId
  )();

  setLeadChannelInStorage(leadChannel);
  return leadChannel;
};
