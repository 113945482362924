import { creditSnapHttpRequest } from '../axios.config';

/**
 * Submits a request to the credit snap application using the provided axios instance
 *
 * @param {AxiosInstance} httpRequest
 * @returns {( path: string, method: string, payload: Object) => Promise<*>}
 */
export const submitRequestToCreditSnap = (
  httpRequest = creditSnapHttpRequest
) => (
  path,
  method,
  payload
) =>
  httpRequest.request({
    method: method,
    url: path,
    data: JSON.stringify(payload || {}),
    headers: {
      accept: 'application/json',
      channel: 'API',
      'content-type': 'application/json',
      // these values come from Tresl's old name "Innovative Funding Services"
      // so it is unlikely these values will need to change
      'institution-id': 'IFS',
      'partner-id': 'ifs-offer-variations',
      'x-partner-key': 'ifs-offer-variations'
    }
  });
