/**
 * Extracts the text content for the specified selector
 * using the provided xml response body string
 *
 * @param {string} selector - default is 'url'
 * @returns {(responseBody: string) => string|null}
 */
export const getTextContentFromXMLNode = (
  selector = 'url'
) => (
  responseBody = ''
) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(responseBody, 'text/xml');
  const elem = doc.querySelector(selector);

  return elem?.textContent || null;
};
