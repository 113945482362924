/**
 * Returns the XML payload string for use with Tresl's "create schedule url" API endpoint
 *
 * @param {Object} options
 * @param {string} options.apiKey
 * @param {string} options.clientNumber
 * @param {string} options.partnerNumber
 *
 * @returns {string}
 */
export const getTreslCreateScheduleRequestBody = ({
  apiKey = '',
  clientNumber = '',
  partnerNumber = ''
} = {}) => `<?xml version="1.0" encoding="UTF-8"?>
<content>
  <authentication>
    <api_key>${apiKey}</api_key>
    <partner_number>${partnerNumber}</partner_number>
  </authentication>
  <client_number>${clientNumber}</client_number>
</content>`;
