const titleCaseFilter = (value) => {
  if (!value) return '';

  if (typeof value === 'string' && value.length > 1) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
};

// adds decimals and $ char for humans
const currencyFilter = (value) => {
  if (!value) return '';
  const cleanedValue = `${value}`.replace(/[^0-9.]/g, '');
  const currencyObject = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  return currencyObject.format(cleanedValue);
};

// turns numbers into strings and strips anything that isnt a number
const numberFilter = (value) => {
  if (!value) return '';

  if (typeof value === 'number') {
    value = value.toLocaleString();
  }

  return value.replace(/[^0-9.]/g, '');
};

// adds % char
const percentFilter = (value) => {
  if (!value) return '';

  if (typeof value === 'string') {
    const cleanedValue = value.replace(/[^0-9.]/g, '');

    return cleanedValue.toLocaleString();
  }

  return value + '%';
};

export {
  titleCaseFilter,
  currencyFilter,
  numberFilter,
  percentFilter
};
