import get from 'lodash/get';
import { nxgHttpRequest } from '../axios.config';
import { extractErrorInformation } from '../extractErrorInformation';
import { reportError } from '../../errors/reportError';

/**
 * Gets the vehicle data for the provided vin number from RateGenius
 *
 * @param {AxiosInstance} httpRequest
 * @returns {(vinNumber: string) => Promise<{
 *  year: number,
 *  make: string,
 *  model: string,
 *  style: string
 * }>}
 */
export const getVehicleDataFromRateGenius = (
  httpRequest = nxgHttpRequest
) => async (
  vinNumber
) => {
  try {
    const response = await httpRequest.get(
      `/api/v2/vehicle_identities/${vinNumber}`
    );

    return get(response, 'data.data', {});
  } catch (error) {
    reportError(error, { extra: extractErrorInformation(error) });
    throw error;
  }
};
