import get from 'lodash/get';
import isNull from 'lodash/isNull';
import { applicantTypes } from '../../../values/application';
import { isNonEmptyString } from '../../../utilities/isNonEmptyString';

const setDateUTCFormat = (dateOfBirth) => {
  const [month, day, year] = dateOfBirth.split('/');

  return new Date(
    Date.UTC(
      ...[year, (month - 1), day].concat([0, 0, 0, 0])
    )
  ).toISOString();
};

export const getters = {
  applicantType: state => state.applicantType,

  // primary applicant
  primaryApplicantDateOfBirth: state =>
    isNonEmptyString(state.primary.dateOfBirth)
      ? state.primary.dateOfBirth
      : null,
  primaryApplicantDateOfBirthAsStringInUtcFormat: state =>
    isNonEmptyString(state.primary.dateOfBirth)
      ? setDateUTCFormat(state.primary.dateOfBirth)
      : null,
  primaryApplicantEmailAddress: state => state.primary.emailAddress,
  primaryApplicantFirstName: state => state.primary.firstName,
  primaryApplicantLastName: state => state.primary.lastName,
  primaryApplicantMobileNumber: state => state.primary.mobileNumber,
  primaryApplicantSocialSecurityNumber: state => state.primary.ssn,

  // joint applicant
  jointApplicantDateOfBirth: state =>
    isNonEmptyString(state.joint.dateOfBirth)
      ? state.joint.dateOfBirth
      : null,
  jointApplicantDateOfBirthAsStringInUtcFormat: state =>
    isNonEmptyString(state.joint.dateOfBirth)
      ? setDateUTCFormat(state.joint.dateOfBirth)
      : null,
  jointApplicantEmailAddress: state => state.joint.emailAddress,
  jointApplicantFirstName: state => state.joint.firstName,
  jointApplicantLastName: state => state.joint.lastName,
  jointApplicantMobileNumber: state => state.joint.mobileNumber,
  jointApplicantSocialSecurityNumber: state => state.joint.ssn,

  jointApplicantRelationshipToPrimaryApplicantIsNull: state =>
    isNull(state.joint.relationshipToPrimary),
  jointApplicantRelationshipToPrimaryApplicantTypeLabel: state =>
    get(state, 'joint.relationshipToPrimary.label', null),
  jointApplicantRelationshipToPrimaryApplicantTypeValue: state =>
    get(state, 'joint.relationshipToPrimary.value', null),

  hasJointApplicant: state => state.applicantType === applicantTypes.JOINT
};
