import { nxgHttpRequest } from './axios.config';
import { extractErrorInformation } from './extractErrorInformation';
import { reportError } from '../errors/reportError';

/**
 * Get a Prequalification Offer by its UUID
 * @param offerUuid
 * @returns { (httpRequest: AxiosInstance) => (offerUuid: string|null) => Promise<*> }
*/
export const getOfferByUuid = (httpRequest = nxgHttpRequest) => async (offerUuid) => {
  try {
    const path = `/v3/prequalification-offers/${offerUuid}?includes[]=request`;
    const response = await httpRequest.get(path);
    return response;
  } catch (error) {
    reportError(error, { extra: extractErrorInformation(error) });
    throw error;
  }
};
