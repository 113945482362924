import get from 'lodash/get';

/**
 * Returns the `fastTrackBranding` Application Submission API
 *
 * @param {Object} branding
 * @returns {String|null}
 */
export const getApplicationSubmissionApi = branding =>
  get(branding, 'fields.applicationSubmissionApi', null);

/**
 * Returns the API Key based on the `APP_ENV` environment variable
 *
 * @param {Object} branding
 * @returns {String|null}
 */
export const getBearerToken = (
  isProduction = process.env.APP_ENV === 'prod'
) => branding =>
  isProduction
    ? getBearerTokenForProduction(branding)
    : getBearerTokenForStage(branding);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getBearerTokenForProduction = branding =>
  get(branding, 'fields.leadChannel.fields.productionApiKey', null);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getBearerTokenForStage = branding =>
  get(branding, 'fields.leadChannel.fields.stageApiKey', null);

/**
 * Returns the `fastTrackBranding` favicon or falls back to the `leadChannel`
 *
 * @param {Object} branding
 * @returns {String|null}
 */
export const getFaviconURL = branding =>
  get(
    branding,
    'fields.favicon.fields.file.url',
    get(branding, 'fields.leadChannel.fields.favicon.fields.file.url', null)
  );

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getFooterLogoAltText = branding =>
  get(branding, 'fields.footerLogo.fields.title', null);

/**
 * @param {Object} branding
 * @returns {Number|null}
 */
export const getFooterLogoHeight = branding =>
  get(branding, 'fields.footerLogo.fields.file.details.image.height', null);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getFooterLogoURL = branding =>
  get(branding, 'fields.footerLogo.fields.file.url', null);

/**
 * @param {Object} branding
 * @returns {Number|null}
 */
export const getFooterLogoWidth = branding =>
  get(branding, 'fields.footerLogo.fields.file.details.image.width', null);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getLeadChannelBrand = branding =>
  get(branding, 'fields.leadChannel.fields.brand', null);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getLeadChannelCode = branding =>
  get(branding, 'fields.leadChannel.fields.leadChannelCode', null);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getLeadChannelDisplayLabel = branding =>
  get(branding, 'fields.leadChannel.fields.displayLabel', null);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getLeadChannelEmailAddress = branding =>
  get(branding, 'fields.leadChannel.fields.emailAddress', null);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getLeadChannelPhoneNumber = branding =>
  get(branding, 'fields.leadChannel.fields.phoneNumber', null);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getLOSApplicationSource = branding =>
  get(branding, 'fields.leadChannel.fields.losApplicationSource', null);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getMailCodeSource = branding =>
  get(branding, 'fields.leadChannel.fields.mailCodeSource', null);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getOrganizationCode = branding =>
  get(
    branding,
    'fields.leadChannel.fields.leadOrganization.fields.organizationCode',
    null
  );

/**
 * Returns the `fastTrackBranding` primary logo or falls back to the `leadChannel`
 *
 * @param {Object} branding
 * @returns {Object|null}
 */
export const getPrimaryLogo = branding =>
  get(
    branding,
    'fields.primaryLogo.fields',
    get(branding, 'fields.leadChannel.fields.primaryLogo.fields', null)
  );

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getPrimaryLogoAltText = branding =>
  get(getPrimaryLogo(branding), 'title', null);

/**
 * @param {Object} branding
 * @returns {Number|null}
 */
export const getPrimaryLogoHeight = branding =>
  get(getPrimaryLogo(branding), 'file.details.image.height', null);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getPrimaryLogoURL = branding =>
  get(getPrimaryLogo(branding), 'file.url', null);

/**
 * @param {Object} branding
 * @returns {Number|null}
 */
export const getPrimaryLogoWidth = branding =>
  get(getPrimaryLogo(branding), 'file.details.image.width', null);

/**
 * @param {Object} branding
 * @returns {Number|null}
 */
export const getRateGeniusSponsorId = branding =>
  get(branding, 'fields.leadChannel.fields.rateGeniusSponsorId', null);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getStylesheet = branding =>
  get(branding, 'fields.stylesheet', null);

/**
 * @param {Object} branding
 * @returns {Boolean|false}
 */
export const getUseWeightedSubmissionApi = branding =>
  get(branding, 'fields.useWeightedSubmissionApi', false);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getWorkflow = branding => get(branding, 'fields.workflow', null);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getLeadChannelTrackingPixelType = branding =>
  get(branding, 'fields.leadChannel.fields.trackingPixelType', null);

/**
 * @param {Object} branding
 * @returns {String|null}
 */
export const getLeadChannelTrackingPixelUrl = branding =>
  get(branding, 'fields.leadChannel.fields.trackingPixelUrl', null);
