import * as Sentry from '@sentry/vue';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export const ErrorReportingPlugin = store => {
  store.subscribe((mutation, state) => {
    if (mutation.type === 'applicant/SET_PRIMARY_APPLICANT_EMAIL_ADDRESS') {
      const email = get(state, 'applicant.primary.emailAddress', null);

      if (isEmpty(email)) {
        return;
      }

      Sentry.setUser({ email });
    } else if (mutation.type === 'global/SET_APP_ID') {
      const id = get(state, 'global.appId', null);

      if (isEmpty(id)) {
        return;
      }

      Sentry.setUser({ id });
    }
  });
};
