import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import {
  getVehicleMakesFromConsumerPortal
} from '../../../core/api/consumerportal/getVehicleMakesFromConsumerPortal';
import {
  getVehicleMakesFromRateGenius
} from '../../../core/api/rategenius/getVehicleMakesFromRateGenius';
import {
  getVehicleMakesWithImage
} from '../../../core/api/getVehicleMakesWithImage';
import {
  getVehicleModelsFromConsumerPortal
} from '../../../core/api/consumerportal/getVehicleModelsFromConsumerPortal';
import {
  getVehicleModelsFromRateGenius
} from '../../../core/api/rategenius/getVehicleModelsFromRateGenius';
import {
  getVehicleModelsSortedByModelName
} from '../../../core/api/getVehicleModelsSortedByModelName';
import {
  getVehicleModelsWithImage
} from '../../../core/api/getVehicleModelsWithImage';
import {
  getVehicleModelsWithPlaceholderImage
} from '../../../core/api/getVehicleModelsWithPlaceholderImage';
import {
  getVehicleTrimsFromConsumerPortal
} from '../../../core/api/consumerportal/getVehicleTrimsFromConsumerPortal';
import {
  getVehicleTrimsFromRateGenius
} from '../../../core/api/rategenius/getVehicleTrimsFromRateGenius';
import {
  getVehicleDataFromRateGenius
} from '../../../core/api/rategenius/getVehicleDataFromRateGenius';
import { cleanValue } from '../../../utilities/Currency';
import { getNumberFormatter } from '../../../utilities/getNumberFormatter';
import { isNonEmptyString } from '../../../utilities/isNonEmptyString';
import { defaultList } from '../../../values/vehicle';
import VehiclePlaceholder from 'Images/vehicle/vehiclePlaceholder.png';

export const PAYOFF_AMOUNT_LOWER_LIMIT = 10000;
export const PAYOFF_AMOUNT_UPPER_LIMIT = 100000;
export const VEHICLE_MILEAGE_PER_YEAR = 14000;

const currencyFormatter = getNumberFormatter();

const isAmountConfirmationRequired = (amount) =>
  isNumber(amount) &&
  (
    amount < PAYOFF_AMOUNT_LOWER_LIMIT ||
    amount > PAYOFF_AMOUNT_UPPER_LIMIT
  );

const isMileageConfirmationRequired = (vehicleMileage, vehicleYear) => {
  const currentYear = new Date().getFullYear();

  const vehicleAge = vehicleYear > currentYear
    ? 0
    : currentYear - (vehicleYear || currentYear);

  return vehicleMileage > VEHICLE_MILEAGE_PER_YEAR * (2 + vehicleAge);
};

export const getters = {
  getVehicleMakesFunction: (state, getters, rootState, rootGetters) => {
    const getVehicleMakes = rootGetters['leadChannel/shouldSubmitToRateGenius']
      ? getVehicleMakesFromRateGenius(state.nxgHttpRequest)
      : getVehicleMakesFromConsumerPortal(state.consumerPortalHttpRequest);

    return getVehicleMakesWithImage(getVehicleMakes);
  },
  getVehicleModelsFunction: (state, getters, rootState, rootGetters) => {
    const getVehicleModels = rootGetters['leadChannel/shouldSubmitToRateGenius']
      ? getVehicleModelsFromRateGenius(state.nxgHttpRequest)
      : getVehicleModelsFromConsumerPortal(state.consumerPortalHttpRequest);

    return getVehicleModelsSortedByModelName(
      getVehicleModelsWithPlaceholderImage(
        getVehicleModels
      )
    );
  },
  getVehicleModelsWithImageFunction: () => getVehicleModelsWithImage(),
  getVehicleVinDataFunction: (state, getters, rootState, rootGetters) =>
    getVehicleDataFromRateGenius(state.nxgHttpRequest),
  getVehicleTrimsFunction: (state, getters, rootState, rootGetters) =>
    rootGetters['leadChannel/shouldSubmitToRateGenius']
      ? getVehicleTrimsFromRateGenius(state.nxgHttpRequest)
      : getVehicleTrimsFromConsumerPortal(state.consumerPortalHttpRequest),
  hasLoadedMakes: state => !isEmpty(state.makes),
  hasLoadedModels: state => !isEmpty(state.models),
  hasLoadedYears: state => !isEmpty(state.years),
  hasLoadedTrims: state => !isEmpty(state.trims),
  hasNoVehicleMakeName: (state, { makeName }) => isEmpty(makeName),
  hasNoVehicleModelName: (state, { modelName }) => isEmpty(modelName),
  hasNoVehicleTrimName: (state, { trimName }) => isEmpty(trimName),
  hasModelsAndImagesRequestsFinished:
    state => state.hasModelsRequestFinished && state.hasModelImagesRequestFinished,
  hasModelsRequestFinished: state => state.hasModelsRequestFinished,
  hasModelImagesRequestFinished: state => state.hasModelImagesRequestFinished,
  isBeforeMarch: () => (new Date().getMonth() < 2),
  makeId: state => get(state, 'make.id', null),
  makeImageUrl: state => {
    const imageUrl = get(state, 'make.image_url', '');
    return isNonEmptyString(imageUrl) ? imageUrl : VehiclePlaceholder;
  },
  makeName: state => get(state, 'make.name', null),
  makes: state => isEmpty(state.makes) ? defaultList : state.makes,
  payoffAmountAsString: state => currencyFormatter(state.payoffAmount),
  payoffAmountAsNumber: state => state.payoffAmount,
  payoffAmountConfirmed: state => state.payoffAmountConfirmed,
  payoffAmountConfirmedValue: state => state.payoffAmountConfirmedValue,
  payoffAmountMatchesConfirmedValue: (state, { payoffAmountAsNumber, payoffAmountConfirmedValue }) =>
    payoffAmountAsNumber === payoffAmountConfirmedValue,
  payoffAmountConfirmationRequired: (state, { payoffAmountAsNumber }) =>
    isAmountConfirmationRequired(payoffAmountAsNumber),
  purchasePriceAsString: state => currencyFormatter(state.purchasePrice),
  purchasePriceAsNumber: state => state.purchasePrice,
  purchasePriceConfirmed: state => state.purchasePriceConfirmed,
  purchasePriceConfirmedValue: state => state.purchasePriceConfirmedValue,
  purchasePriceMatchesConfirmedValue: (state, { purchasePriceAsNumber, purchasePriceConfirmedValue }) =>
    purchasePriceAsNumber === purchasePriceConfirmedValue,
  purchasePriceConfirmationRequired: (state, { purchasePriceAsNumber }) =>
    isAmountConfirmationRequired(purchasePriceAsNumber),
  mileage: state => state.mileage,
  mileageAsNumber: state => {
    const mileage = cleanValue(state.mileage);
    return isFinite(mileage) ? Number(mileage) : 0;
  },
  mileageConfirmed: state => state.mileageConfirmed,
  mileageConfirmedValue: state => state.mileageConfirmedValue,
  mileageMatchesConfirmedValue: (state, { mileageConfirmedValue }) =>
    state.mileage === mileageConfirmedValue,
  mileageConfirmationRequired: (state, { year, mileageAsNumber }) =>
    isMileageConfirmationRequired(mileageAsNumber, year),
  modelId: state => get(state, 'model.id', null),
  modelImageUrl: state => {
    const imageUrl = get(state, 'model.image_url', '');
    return isNonEmptyString(imageUrl) ? imageUrl : VehiclePlaceholder;
  },
  modelName: state => get(state, 'model.name', null),
  models: state => isEmpty(state.models) ? defaultList : state.models,
  trimId: state => get(state, 'trim.id', null),
  trimName: state => get(state, 'trim.name', null),
  trims: state => state.trims,
  year: state => state.year,
  years: state => state.years
};
