import { email } from 'vee-validate/dist/rules';

import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import isEmpty from 'lodash/isEmpty';
import sub from 'date-fns/sub';
import parse from 'date-fns/parse';

const emailValidator = (fieldValue) => {
  if (email.validate(fieldValue)) {
    return true;
  }

  return 'Please enter a valid email';
};

const alphaNumericSpecialValidator = (fieldValue) => {
  if (/^[a-zA-Z0-9\-\s/.'&!]+$/.test(fieldValue)) {
    return true;
  }

  return 'Please use letters, digits, apostrophes, hyphens, slashes, ampersands, exclamations, and spaces.';
};

const alphaNumericApostropheDashPeriodSlashSpaceValidator = (fieldValue) => {
  if (/^[a-zA-Z0-9\-\s/.']+$/.test(fieldValue)) {
    return true;
  }

  return 'Please use letters, digits, apostrophes, hyphens, slashes, and spaces.';
};

const alphaNumericDashSpaceValidator = (fieldValue) => {
  if (/^[a-zA-Z0-9- .]+$/.test(fieldValue)) {
    return true;
  }

  return 'Please use letters, numbers, dashes, and spaces.';
};

const dateOfBirthValidator = (fieldValue) => {
  if (!/^\d{2}\/\d{2}\/\d{4}$/.test(fieldValue)) {
    return 'The date should be MM/DD/YYYY';
  }

  const currentValue = parse(fieldValue, 'MM/dd/yyyy', new Date());

  // when currentValue is outside of a range, it renders the
  // 'invalid date' string; coercing to a boolean here
  const validDate = currentValue.toString() !== 'Invalid Date';

  const minDate = sub(new Date(), { years: 100 });

  const inRange = (isBefore(currentValue, new Date()) && isAfter(currentValue, minDate));

  if (!inRange || !validDate) {
    return 'Please enter a valid date of birth';
  }

  return true;
};

const milesValidator = (fieldValue) => {
  if (/^[0-9,]+$/.test(fieldValue)) {
    return true;
  }

  return 'Please only use digits and commas';
};

const moneyValidator = (fieldValue) => {
  if (/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(fieldValue)) {
    return true;
  }

  return 'This money value appears to be incorrect';
};

const phoneValidator = (fieldValue) => {
  if (!/^\d{3}-\d{3}-\d{4}$/.test(fieldValue)) {
    return 'Phone number should be ###-###-####';
  }

  if (!/^[2-9]\d{2}-[2-9]\d{2}-\d{4}$/.test(fieldValue)) {
    return 'Please enter a valid phone number';
  }

  return true;
};

const ssnValidator = (fieldValue) => {
  if (isEmpty(fieldValue)) {
    return true;
  }

  if (!/^\d{3}-\d{2}-\d{4}$/.test(fieldValue)) {
    return 'Social security number should be ###-##-####';
  }

  return true;
};

const zipCodeValidator = (fieldValue) => {
  if (fieldValue.length === 5 && /^[0-9]+$/.test(fieldValue)) {
    return true;
  }

  return 'A zip code should be 5 digits long';
};

const cannotBeZeroIfOtherFieldIsZeroValidator = (value, { otherValue, otherFieldName }) => {
  if (otherValue === 0 && value === 0) {
    return 'Either "{_field_}" or "{otherFieldName}" must be greater than 0';
  }

  return true;
};

const poBoxValidator = (fieldValue) => {
  // Looks for variations of "PO Box" or "Post Office"
  const pattern = /\b[p]*(ost)*\.*\s*[o|0](ffice)*\.*\sb[o|0]x\b/;
  const poRegex = new RegExp(pattern, 'i');

  // Assumes "POB 3333" and "Box 3333" are also boxes, like Mailboxes Etc.
  const confirmationPattern = /(pob|box)\s\d+/;
  const confirmationRegex = new RegExp(confirmationPattern, 'i');

  if (poRegex.test(fieldValue) || confirmationRegex.test(fieldValue)) {
    return 'You must provide a physical mail address to complete your application';
  };

  return true;
};

export {
  emailValidator,
  alphaNumericSpecialValidator,
  alphaNumericApostropheDashPeriodSlashSpaceValidator,
  alphaNumericDashSpaceValidator,
  dateOfBirthValidator,
  milesValidator,
  moneyValidator,
  phoneValidator,
  ssnValidator,
  poBoxValidator,
  zipCodeValidator,
  cannotBeZeroIfOtherFieldIsZeroValidator
};
