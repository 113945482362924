import { isNonEmptyString } from '../../utilities/isNonEmptyString';
import { StoreModifier } from '../../utilities/storeModifiers';

const state = {
  next: null,
  previous: null
};

const mutations = {
  SET_NEXT_ROUTE_NAME (state, next) {
    state.next = next;
  },
  SET_PREVIOUS_ROUTE_NAME (state, previous) {
    state.previous = previous;
  }
};

const getters = {
  hasNextRouteName: (state) => isNonEmptyString(state.next),
  hasPreviousRouteName: (state) => isNonEmptyString(state.previous),
  nextRouteName: state => state.next,
  previousRouteName: state => state.previous
};

const actions = {
  setNextRouteName ({ commit }, next) {
    commit('SET_NEXT_ROUTE_NAME', next);
  },
  setPreviousRouteName ({ commit }, previous) {
    commit('SET_PREVIOUS_ROUTE_NAME', previous);
  }
};

export default new StoreModifier({
  actions,
  getters,
  mutations,
  namespaced: true,
  state
});
