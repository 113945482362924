import { toUpper } from 'lodash';
import { REFINANCE } from '../values/application';
import { getApplicationTypeFromSearchParams } from './getApplicationTypeFromSearchParams';
import { getApplicationTypeFromStorage } from './getApplicationTypeFromStorage';
import { isValidApplicationType } from './isValidApplicationType';

/**
 * Gets the application type from:
 *  - search params
 *  - local storage
 *
 * @returns {() => string}
*/
export const getApplicationTypeForLeadChannel = (
  leadChannelCode,
  getFromSearchParams = getApplicationTypeFromSearchParams,
  getFromStorage = getApplicationTypeFromStorage
) => () => {
  const applicationTypeFromSearchParams = getFromSearchParams();
  if (isValidApplicationType(applicationTypeFromSearchParams)) {
    return toUpper(applicationTypeFromSearchParams);
  }

  const applicationTypeFromLocalStorage = getFromStorage(leadChannelCode);
  if (isValidApplicationType(applicationTypeFromLocalStorage)) {
    return toUpper(applicationTypeFromLocalStorage);
  }

  return REFINANCE;
};
