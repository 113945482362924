import { stringify } from 'qs';
import { nxgHttpRequest } from '../axios.config';

/**
 * POSTs the rate genius application to next-gen
 *
 * @param {string} bearerToken
 * @param {AxiosInstance} httpRequest
 * @returns {(application: Object) => Promise<*>}
 */
export const submitApplicationToRateGenius = (
  httpRequest = nxgHttpRequest
) => (
  application
) =>
  httpRequest.post(
    '/api/rgsystem/sponsor/rglegacy',
    stringify(application),
    {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
  );
