// Order is popularity by ascending order (most popular are at the bottom)
export const popularMakes = [
  'Nissan',
  'Toyota',
  'Ford',
  'Chevrolet',
  'Honda',
  'Dodge',
  'Jeep',
  'Kia',
  'Hyundai',
  'GmcLightDuty',
  'Chrysler',
  'Bmw',
  'Volkswagen',
  'MercedesBenz',
  'Mazda',
  'Subaru',
  'Lexus',
  'Cadillac',
  'Buick',
  'Infiniti'
];

export const defaultList = [
  {
    id: 1,
    name: '',
    links: null
  },
  {
    id: 2,
    name: '',
    links: null
  },
  {
    id: 3,
    name: '',
    links: null
  },
  {
    id: 4,
    name: '',
    links: null
  }
];
