import store from '../../store';
import { StorageService } from '../../core/service/storage';

export default {
  require: ['workflowService', 'ingressService', 'initialQuery', 'cookieService'],
  lazy: true,
  init (workflowService, ingressService, initialQuery, cookieService) {
    const storage = new StorageService(store);

    storage.syncAttributions(ingressService.attributionLog);
    storage.syncGoogleVisitorId(cookieService);
    storage.syncWorkflowInfo(workflowService);
    storage.syncQueryData(initialQuery);

    return storage;
  }
};
