import * as Sentry from '@sentry/vue';

/**
 * Generic information reporting function
 *
 * @param {String} message
 * @param {Object} captureContext - optional
 */
export const reportInfoMessage = (message, captureContext) => {
  Sentry.captureMessage(message, captureContext);
};
