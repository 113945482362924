import includes from 'lodash/includes';
import { isNonEmptyString } from './isNonEmptyString';

const validWorkflows = [
  'default',
  'custinfofirst',
  'vehandloanfirst',
  'defaultnointent',
  'vehiclefirst',
  'offers'
];

/**
 * Returns a prioritized workflow to use.
 *
 * Prioritizes a workflow that is available in the URL query parameter or
 * falls back to a provided default.
 */
export function getWorkflow (defaultWorkflow) {
  if (!window.location) {
    return defaultWorkflow;
  }

  const workflowFromURL = new URL(window.location).searchParams.get('fl');

  return isNonEmptyString(workflowFromURL) && includes(validWorkflows, workflowFromURL)
    ? workflowFromURL
    : defaultWorkflow;
}
