import { parse as parseCookie } from 'cookie';

export class CookieService {
  constructor (cookie) {
    this.cookie = parseCookie(cookie);
  }

  /**
   * getter for cookie values
   *
   * @param index
   * @param def
   * @returns {*}
   */
  get (index, def) {
    def = typeof def === 'undefined' ? null : def;
    return this.cookie.hasOwnProperty(index) ? this.cookie[index] : def;
  }

  /**
   * Parses out google visitor ID from cookie
   *
   * @returns {*}
   * @private
   */
  getCookieGoogleVisitorId () {
    const __utma = this.get('__utma');
    const _ga = this.get('_ga');

    if (_ga !== null) {
      const parts = _ga.split('.');
      return parts[2] + '.' + parts[3];
    }

    if (__utma !== null) {
      const parts = __utma.split('.');
      return parts[1] + '.' + parts[2];
    }
  }
}
