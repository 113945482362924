import eq from 'lodash/eq';
import every from 'lodash/every';
import findIndex from 'lodash/findIndex';
import findLast from 'lodash/findLast';
import { Attribution, CampaignAttribution, DirectMailAttribution } from './attributionTypes';
import { RATEGENIUS_NEW_APPLICATION } from '../../../values/sponsor';

/**
 * Decide which class object to build from raw object
 *
 * @param attribution
 * @returns {*}
 */
export function createAttribution (attribution) {
  if (attribution instanceof Attribution || attribution instanceof CampaignAttribution || attribution instanceof DirectMailAttribution) {
    return attribution;
  }

  switch (attribution.type) {
    case 'DirectMail': return new DirectMailAttribution(attribution);
    case 'Campaign': return new CampaignAttribution(attribution);
    case 'Attribution': return new Attribution(attribution);
    default : return attribution.campaignId ? new CampaignAttribution(attribution) : new Attribution(attribution);
  }
}

/**
 * Collection of attributions
 *
 */
export class AttributionLog {
  constructor ({ attributions } = {}) {
    this.attributions = (attributions || []).map(attr => createAttribution(attr));
  }

  /***
   * Push a new attribute to the end of the collection
   *
   * @param attribution
   */
  push (attribution) {
    this.attributions.push(attribution);
  }

  /**
   * Push a unique attribution to end of collection. If not unique shift record to end.
   *
   * @param attribution
   */
  pushUnique (attribution) {
    const foundIndex = findIndex(this.attributions, (attributionItem) => {
      if (attribution.campaignId) {
        if (attributionItem instanceof CampaignAttribution) {
          return attributionItem.sponsorId === attribution.sponsorId && attributionItem.campaignId === attribution.campaignId;
        }
      } else {
        return every([
          eq(attributionItem.sponsorId, attribution.sponsorId),
          eq(attributionItem.referData, attribution.referData),
          eq(attributionItem.leadChannel, attribution.leadChannel)
        ]);
      }
    });
    if (foundIndex < 0) {
      this.push(attribution);
    } else {
      // move found item to end of array
      const item = this.attributions[foundIndex];
      this.attributions.splice(foundIndex, 1);
      this.push(item.merge ? item.merge(attribution) : item);
    }
  }

  /**
   * get all campaigns
   *
   * @returns {T[]}
   */
  getCampaigns () {
    return this.attributions.filter(attribution => {
      return attribution instanceof CampaignAttribution;
    });
  }

  /**
   *
   * @param campaignId
   * @param sponsorId
   * @returns {number}
   */
  findCampaign (campaignId, sponsorId) {
    return findLast(this.getCampaigns(), campaignAttribution => {
      return campaignAttribution.campaignId === campaignId && campaignAttribution.sponsorId === sponsorId;
    });
  }

  /**
   * get the most recent attribution entry
   *
   * @returns {*}
   */
  getLastAttribution () {
    return this.attributions[this.attributions.length - 1];
  }

  /**
   * picks the last attribution or campaign cid is passed and attribution not expired
   *
   * @returns {*}
   */
  getCurrentAttribution () {
    const directAttribution = createAttribution({ sponsorId: RATEGENIUS_NEW_APPLICATION });
    const lastAttribution = this.getLastAttribution();

    // if the last attribution had a cid search for first time it was seen.
    if (lastAttribution instanceof CampaignAttribution) {
      const foundCampaign = this.findCampaign(lastAttribution.campaignId, lastAttribution.sponsorId);
      if (foundCampaign && !foundCampaign.isExpired()) {
        // if we found a non-expired campaign use that as the attribution
        return foundCampaign;
      } else {
        // if campaign was expired return direct attribution
        return directAttribution;
      }
    }

    // for non campaign attributions return the last attribution or direct if none exists
    return lastAttribution || directAttribution;
  }
}
