const EmptyVueRouterForNesting = () => import(/* webpackChunkName: "initial-payload" */ '../components/global/EmptyVueRouterForNesting');

const FullDetails = () => import('../components/vehicle/FullDetails');
const Details = () => import('../components/vehicle/Details');
const Make = () => import('../components/vehicle/Make');
const Model = () => import('../components/vehicle/Model');
const Trim = () => import('../components/vehicle/Trim');
const Year = () => import('../components/vehicle/Year');

// Vehicle
export const vehicleRoutes = {
  path: 'vehicle',
  component: EmptyVueRouterForNesting,
  children: [
    {
      path: '',
      redirect: '/apply'
    },
    {
      path: 'details',
      name: 'vehicle.details',
      component: Details
    },
    {
      path: 'full-details',
      name: 'vehicle.full-details',
      component: FullDetails
    },
    {
      path: 'make',
      name: 'vehicle.make',
      component: Make
    },
    {
      path: 'model',
      name: 'vehicle.model',
      component: Model
    },
    {
      path: 'trim',
      name: 'vehicle.trim',
      component: Trim
    },
    {
      path: 'year',
      name: 'vehicle.year',
      component: Year
    }
  ]
};
