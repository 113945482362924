import isNumber from 'lodash/isNumber';
import { isNonEmptyString } from './isNonEmptyString';

/**
 * Converts the value value to number (if string or number) else return null
 *
 * @param {string|number} value
 * @returns {number|null}
 */
export const convertToNumberOrNull = (value) => {
  return isNumber(value) ? value : isNonEmptyString(value) ? Number(value) : null;
};
