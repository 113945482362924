import { activeSponsors } from '../../../values/sponsor';

export const actions = {
  /**
   * Sets the Sponsor ID
   * @param commit {function(*)}
   * @param sponsorId {number}
   */
  setSponsorId ({ commit }, sponsorId) {
    if (sponsorId === null) {
      sponsorId = activeSponsors.RATEGENIUS_NEW_APPLICATION;
    }
    commit('SET_SPONSOR_ID', sponsorId);
  },

  /**
   * Sets the template ID
   * @param commit
   * @param templateId
   */
  setTemplateId ({ commit }, templateId) {
    commit('SET_TEMPLATE_ID', templateId);
  },

  /**
   * Sets the `subid` or Sponsor Click ID
   * @param commit {function(*)}
   * @param sponsorClickId {(string|null)}
   */
  setSponsorClickId ({ commit }, sponsorClickId) {
    commit('SET_SPONSOR_CLICK_ID', sponsorClickId);
  },

  /**
   * Sets the Sponsor Partner ID
   * @param commit {function(*)}
   * @param sponsorPartnerId {(string|null)}
   */
  setSponsorPartnerId ({ commit }, sponsorPartnerId) {
    commit('SET_SPONSOR_PARTNER_ID', sponsorPartnerId);
  },

  /**
   *
   * @param commit {function(*)}
   * @param searchParams {{URLSearchParams}}
   */
  setSponsorPartnerIdFromURLSearchParams ({ commit }, searchParams) {
    if (!searchParams) {
      return;
    }

    commit('SET_SPONSOR_PARTNER_ID', searchParams.get('sponsorPartnerId'));
  },

  /**
   * @param commit {function(*)}
   * @param lenderId {(number|null)}
   */
  setLenderId ({ commit }, lenderId) {
    commit('SET_LENDER_ID', lenderId);
  },

  /**
   * @param commit {function(*)}
   * @param googleVisitorId {(string|number|null)}
   */
  setGoogleVisitorId ({ commit }, googleVisitorId) {
    commit('SET_GOOGLE_VISITOR_ID', googleVisitorId);
  },

  /**
   * @param commit {function(*)}
   * @param liveChatId {(string|number|null)}
   */
  setLiveChatId ({ commit }, liveChatId) {
    commit('SET_LIVE_CHAT_ID', liveChatId);
  },

  /**
   * Sets the UTMs as a pre-built object
   * @param commit {function(*)}
   * @param utms {object|{campaign: string, source: string, medium: string, content: string, term: string}}
   */
  setUtmAsObject ({ commit }, utms) {
    commit('SET_ALL_UTMS', utms);
  },

  /**
   * Sets an indivudal UTM value.
   * Usage:
   *   Requires an object with a `name` and `value` property.
   * @param commit {function(*)}
   * @param utm {Object|{name: string, value: string}}
   */
  setUtmValue ({ commit }, utm) {
    commit('SET_UTM_VALUE', utm);
  },

  setMarketingAutomationFields ({ commit }, automationFields) {
    commit('ADD_MARKETING_AUTOMATION_FIELD', automationFields);
  },

  setMarketingAutomationUnsubscribed ({ commit }, unsubscribed) {
    commit('ADD_MARKETING_AUTOMATION_FIELD', { unsubscribed });
  },

  /**
   * Set the attribution log
   * @param commit
   * @param attributionLog
   */
  setAttributionLog ({ commit }, attributionLog) {
    commit('SET_ATTRIBUTION_LOG', attributionLog);
  },

  /**
   * Set campaign id
   * @param commit
   * @param campaignId
   */
  setCampaignId ({ commit }, campaignId) {
    commit('SET_CAMPAIGN_ID', campaignId);
  },

  /**
   * Set the active workflow for this session
   * @param commit
   * @param {string} activeWorkflow
   */
  setWorkflow ({ commit }, activeWorkflow) {
    commit('SET_WORKFLOW', activeWorkflow);
  },

  /**
   * Set the refer id
   * @param commit
   * @param {number}
   */
  setReferId ({ commit }, referId) {
    commit('SET_REFER_ID', referId);
  },

  /**
   * Set the prequal Id
   * @param commit
   * @param {number}
   */
  setPrequalId ({ commit }, prequalId) {
    commit('SET_PREQUAL_ID', prequalId);
  }
};
