import { serviceContainer } from '../di';
import store from '../store';

if (process.env.APP_ENV === 'local' || process.env.APP_ENV === 'qa' || process.env.APP_ENV === 'stage') {
  window.fastTrackDebug = {
    appSubmitsToNxg: function () {
      const applicationService = serviceContainer.get('applicationService');
      const sponsorId = store.getters['trackingData/sponsorId'];

      return applicationService.isPayloadNxg({
        sponsorId
      });
    },
    appSubmitsToNxgWithAttempts: function (attempts) {
      const loggedAttempts = [];
      for (let i = 1; i <= attempts; i++) {
        const result = this.appSubmitsToNxg();
        loggedAttempts.push(result);
      }

      return {
        totalAttempts: attempts,
        nxgCount: loggedAttempts.filter(attempt => attempt === true).length,
        legacyCount: loggedAttempts.filter(attempt => attempt === false).length,
        nxgRate: loggedAttempts.filter(attempt => attempt === true).length / attempts,
        legacyRate: loggedAttempts.filter(attempt => attempt === false).length / attempts
      };
    }
  };
}
