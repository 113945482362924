import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import {
  emailValidator,
  alphaNumericSpecialValidator,
  alphaNumericApostropheDashPeriodSlashSpaceValidator,
  alphaNumericDashSpaceValidator,
  dateOfBirthValidator,
  milesValidator,
  moneyValidator,
  phoneValidator,
  ssnValidator,
  poBoxValidator,
  zipCodeValidator,
  cannotBeZeroIfOtherFieldIsZeroValidator
} from './validators';

extend('email', emailValidator);
extend('alphaNumericSpecial', alphaNumericSpecialValidator);
extend('alphaNumericApostropheDashPeriodSlashSpace', alphaNumericApostropheDashPeriodSlashSpaceValidator);
extend('alphaNumericDashSpace', alphaNumericDashSpaceValidator);
extend('dateOfBirth', dateOfBirthValidator);
extend('miles', milesValidator);
extend('money', moneyValidator);
extend('phone', phoneValidator);
extend('ssn', ssnValidator);

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('zipCode', zipCodeValidator);

extend('cannotBeZeroIfOtherFieldIsZero', {
  validate: cannotBeZeroIfOtherFieldIsZeroValidator,
  params: ['otherValue', 'otherFieldName']
});

extend('isPO', poBoxValidator);
