
export const mutations = {
  SET_APPLICANT_TYPE (state, applicantType) {
    state.applicantType = applicantType;
  },

  SET_PRIMARY_APPLICANT_DATE_OF_BIRTH (state, dateOfBirth) {
    state.primary.dateOfBirth = dateOfBirth;
  },
  SET_PRIMARY_APPLICANT_PREVIOUS_EMAIL_ADDRESS (state, previousEmailAddress) {
    state.primary.previousEmailAddress = previousEmailAddress;
  },
  SET_PRIMARY_APPLICANT_EMAIL_ADDRESS (state, emailAddress) {
    state.primary.emailAddress = emailAddress;
  },
  SET_PRIMARY_APPLICANT_FIRST_NAME (state, firstName) {
    state.primary.firstName = firstName;
  },
  SET_PRIMARY_APPLICANT_LAST_NAME (state, lastName) {
    state.primary.lastName = lastName;
  },
  SET_PRIMARY_APPLICANT_MOBILE_NUMBER (state, mobileNumber) {
    state.primary.mobileNumber = mobileNumber;
  },
  SET_PRIMARY_APPLICANT_SOCIAL_SECURITY_NUMBER (state, ssn) {
    state.primary.ssn = ssn;
  },

  SET_JOINT_APPLICANT_DATE_OF_BIRTH (state, dateOfBirth) {
    state.joint.dateOfBirth = dateOfBirth;
  },
  SET_JOINT_APPLICANT_EMAIL_ADDRESS (state, emailAddress) {
    state.joint.emailAddress = emailAddress;
  },
  SET_JOINT_APPLICANT_FIRST_NAME (state, firstName) {
    state.joint.firstName = firstName;
  },
  SET_JOINT_APPLICANT_LAST_NAME (state, lastName) {
    state.joint.lastName = lastName;
  },
  SET_JOINT_APPLICANT_MOBILE_NUMBER (state, mobileNumber) {
    state.joint.mobileNumber = mobileNumber;
  },
  SET_JOINT_APPLICANT_SOCIAL_SECURITY_NUMBER (state, ssn) {
    state.joint.ssn = ssn;
  },
  SET_JOINT_APPLICANT_RELATIONSHIP_TO_PRIMARY_APPLICANT (state, relationshipToPrimary) {
    state.joint.relationshipToPrimary = relationshipToPrimary;
  }
};
