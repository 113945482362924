import { creditSnapIncomeTypeMap } from '../values/application';

/**
 * Returns the CreditSnap income type for the provided
 * FastTrack additional income frequency dropdown value
 *
 * @param {string} additionalIncomeFrequencyValue
 * @returns {string|null}
 */
export const toCreditSnapIncomeType = (additionalIncomeFrequencyValue) =>
  creditSnapIncomeTypeMap[additionalIncomeFrequencyValue] || null;
