import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import { convertToFloat } from './convertToFloat';

/**
 * @param {Object} options - Options for formatting the string
 * @param {string} options.currency - The currency to use (USD)
 * @param {string} options.locale - The locale to use for formatting the string (en-US)
 * @param {string} options.style - The style to use for formatting the string (currency)
 *
 * @returns {function(): (string|null)}
 */
export const getNumberFormatter = (
  {
    currency = 'USD',
    locale = 'en-US',
    style = 'currency'
  } = {}
) =>
  (value) =>
    isString(value) || isNumber(value)
      ? new Intl.NumberFormat(locale, { currency, style }).format(
        isString(value) ? convertToFloat(value) : value
      )
      : null;
