import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import { isNonEmptyString } from '../../../utilities/isNonEmptyString';
import {
  CREDIT_SOFT_PULL_LOOKUP_FAILED,
  MAINTENANCE_MODE
} from '../../../values/errorCodes';
import { purchaseApplicationTypes } from '../../../values/application';

const MOBILE_MAX_WIDTH = 720;

export const getters = {
  activationLink: state => get(state, 'submissionResponse.activationLink', null),
  amountFinanced: state => state.amountFinanced,
  appId: state => state.appId,
  applicationType: state => state.applicationType,
  autopayNumber: state => get(state, 'submissionResponse.autopayNumber', null),
  clientApplicationNumber: state =>
    get(state, 'submissionResponse.clientApplicationNumber', null),
  displayMode: state => state.width > MOBILE_MAX_WIDTH ? 'desktop' : 'mobile',
  firstEmbeddedError: state =>
    get(state, 'submissionResponse._embedded.errors[0]', null),
  firstEmbeddedErrorCode: (state, { firstEmbeddedError }) =>
    get(firstEmbeddedError, 'errorCode', null),
  firstEmbeddedErrorMessage: (state, { firstEmbeddedError }) =>
    get(firstEmbeddedError, 'message', null),
  hasActivationLink: (state, { activationLink }) =>
    isNonEmptyString(activationLink),
  hasSeenHome: state => state.hasSeenHome,
  hasOffers: state => !isEmpty(get(state, 'submissionResponse.offerRecommendations', [])),
  isApplicationTypePurchase: state =>
    purchaseApplicationTypes.includes(state.applicationType),
  isErrorStatusCode: state =>
    state.submissionStatus >= 400,
  isNotFoundStatusCode: state =>
    state.submissionStatus === 404,
  isScheduleError: state => !isNull(state.treslScheduleError),
  isCreditSoftPullError: (state, { firstEmbeddedErrorCode }) =>
    firstEmbeddedErrorCode === CREDIT_SOFT_PULL_LOOKUP_FAILED,
  isMaintenanceMode: (state, { firstEmbeddedErrorCode }) =>
    state.submissionStatus === 503 && firstEmbeddedErrorCode === MAINTENANCE_MODE,
  isSubmittingApplication: state => state.isSubmittingApplication,
  hasApplicationBeenSubmitted: state => state.hasApplicationBeenSubmitted,
  loadingPercent: state => state.loadingPercent,
  lowestRateOfferId: (state, { offerLowestAPR }) =>
    get(offerLowestAPR, 'offerId', null),
  lowestRateAPR: (state, { offerLowestAPR }) =>
    get(offerLowestAPR, 'apr', null),
  lowestRateTerm: (state, { offerLowestAPR }) =>
    get(offerLowestAPR, 'defaultTerm', null),
  lowestPaymentOfferId: (state, { offerLowestPayment }) =>
    get(offerLowestPayment, 'offerId', null),
  lowestPaymentAPR: (state, { offerLowestPayment }) =>
    get(offerLowestPayment, 'apr', null),
  lowestPaymentTerm: (state, { offerLowestPayment }) =>
    get(offerLowestPayment, 'defaultTerm', null),
  offerApplicationId: state => get(state, 'submissionResponse.applicationId', null),
  offerLowestAPR: state =>
    find(
      get(state, 'submissionResponse.offerRecommendations', []),
      { offerRecommendationType: 'LOWESTAPRFIXEDRATE' }
    ),
  offerLowestPayment: state =>
    find(
      get(state, 'submissionResponse.offerRecommendations', []),
      { offerRecommendationType: 'LOWESTEMIFIXEDRATE' }
    ),
  offerSelected: state =>
    find(
      get(state, 'submissionResponse.offerRecommendations', []),
      { offerId: state.selectedOfferId }
    ),
  originMarketingSourceUrl: state => state.originMarketingSourceUrl,
  rateGeniusApplicationId: state =>
    get(state, 'submissionResponse.data.sponsorApplicationId', 'NXG App'),
  theSavingsGroupPrivacyPolicy: state => state.theSavingsGroupPrivacyPolicy,
  theSavingsGroupTerms: state => state.theSavingsGroupTerms,
  reasonForLoanChange: state => state.reasonForLoanChange,
  treslApiKey: () => process.env.TRESL_API_KEY,
  treslPartnerNumber: () => process.env.TRESL_PARTNER_NUMBER,
  treslNonProductionScheduleAppointmentURL: () =>
    'https://tresl-online-preprod.rategenius.io/#/schedule-appointment'
};
