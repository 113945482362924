export const actions = {
  setApplicantType ({ commit }, applicantType) {
    commit('SET_APPLICANT_TYPE', applicantType);
  },

  setPrimaryApplicantDateOfBirth ({ commit }, dateOfBirth) {
    commit('SET_PRIMARY_APPLICANT_DATE_OF_BIRTH', dateOfBirth);
  },
  setPrimaryApplicantPreviousEmailAddress ({ commit }, previousEmailAddress) {
    commit('SET_PRIMARY_APPLICANT_PREVIOUS_EMAIL_ADDRESS', previousEmailAddress);
  },
  setPrimaryApplicantEmailAddress ({ commit }, emailAddress) {
    commit('SET_PRIMARY_APPLICANT_EMAIL_ADDRESS', emailAddress);
  },
  setPrimaryApplicantFirstName ({ commit }, firstName) {
    commit('SET_PRIMARY_APPLICANT_FIRST_NAME', firstName);
  },
  setPrimaryApplicantLastName ({ commit }, lastName) {
    commit('SET_PRIMARY_APPLICANT_LAST_NAME', lastName);
  },
  setPrimaryApplicantMobileNumber ({ commit }, mobileNumber) {
    commit('SET_PRIMARY_APPLICANT_MOBILE_NUMBER', mobileNumber);
  },
  setPrimaryApplicantSocialSecurityNumber ({ commit }, ssn) {
    commit('SET_PRIMARY_APPLICANT_SOCIAL_SECURITY_NUMBER', ssn);
  },
  setJointApplicantDateOfBirth ({ commit }, dateOfBirth) {
    commit('SET_JOINT_APPLICANT_DATE_OF_BIRTH', dateOfBirth);
  },
  setJointApplicantEmailAddress ({ commit }, emailAddress) {
    commit('SET_JOINT_APPLICANT_EMAIL_ADDRESS', emailAddress);
  },
  setJointApplicantFirstName ({ commit }, firstName) {
    commit('SET_JOINT_APPLICANT_FIRST_NAME', firstName);
  },
  setJointApplicantLastName ({ commit }, lastName) {
    commit('SET_JOINT_APPLICANT_LAST_NAME', lastName);
  },
  setJointApplicantMobileNumber ({ commit }, mobileNumber) {
    commit('SET_JOINT_APPLICANT_MOBILE_NUMBER', mobileNumber);
  },
  setJointApplicantSocialSecurityNumber ({ commit }, ssn) {
    commit('SET_JOINT_APPLICANT_SOCIAL_SECURITY_NUMBER', ssn);
  },

  setJointApplicantRelationshipToPrimaryApplicant ({ commit }, relationshipToPrimary) {
    commit(
      'SET_JOINT_APPLICANT_RELATIONSHIP_TO_PRIMARY_APPLICANT',
      relationshipToPrimary
    );
  }
};
