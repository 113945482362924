const organizationApiToken = '332bf9e5-e6a8-4812-b82c-5763ab26d9b3';

export default {
  /**
   * Installs the Autopay's podium chat widget
   *
   * @param vue
   * @param options {{document: object, window: object, token: string}}
   */
  install(vue, options) {
    options.window.__lc = {};
    options.window.__lc.license = options.token;
    options.window.__lc.ga_version = 'gtm';
    options.window.__lc.providedBy = 'autopay-chat';

    var lc = options.document.createElement('script');
    lc.id = 'podium-widget';
    lc.dataset.organizationApiToken = organizationApiToken;
    lc.type = 'text/javascript';
    lc.async = true;
    lc.src =
      ('https:' == document.location.protocol ? 'https://' : 'http://') +
      `connect.podium.com/widget.js#ORG_TOKEN=${organizationApiToken}`;
    document.body.appendChild(lc);

    vue.prototype.$liveChat = options.window.__lc;
  },
};
