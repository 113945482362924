export const mutations = {
  SET_APR (state, apr) {
    state.apr = apr;
  },
  SET_AMOUNT (state, amount) {
    state.amount = amount;
  },
  SET_PAYMENT (state, payment) {
    state.payment = payment;
  },
  SET_TERM (state, term) {
    state.term = term;
  }
};
