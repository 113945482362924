export const mutations = {
  LOAD_OFFER (state, offer) {
    state.offer = offer;
  },
  SET_SHOULD_MOCK_OFFER (state, shouldMockOffer) {
    state.shouldMockOffer = shouldMockOffer;
  },
  SET_OFFER_APPLICANT_DATA (state, offerApplicantData) {
    state.offerApplicantData = offerApplicantData;
  },
  SET_OFFER_UUID (state, offerUuid) {
    state.offerUuid = offerUuid;
  },
  SET_OFFER_FAILED (state, offerFailed) {
    state.offerFailed = offerFailed;
  }
};
