export default [
  {
    name: 'forms.home',
    next: ({ reason } = {}) => {
      return reason ? 'vehicle.year' : 'forms.pre';
    }
  },
  {
    name: 'forms.pre',
    next: 'vehicle.year',
    previous: 'forms.home'
  },
  {
    name: 'vehicle.year',
    next: 'vehicle.make',
    previous: 'forms.pre'
  },
  {
    name: 'vehicle.make',
    next: 'vehicle.model',
    previous: 'vehicle.year'
  },
  {
    name: 'vehicle.model',
    next: 'vehicle.trim',
    previous: 'vehicle.make'
  },
  {
    name: 'vehicle.trim',
    next: 'forms.contact',
    previous: 'vehicle.model'
  },
  {
    name: 'forms.contact',
    next: ({ hasJointApplicant } = {}) => {
      return hasJointApplicant ? 'forms.contact.joint' : 'vehicle.details';
    },
    previous: 'vehicle.trim'
  },
  {
    name: 'forms.contact.joint',
    next: 'forms.residence.joint',
    previous: 'forms.contact'
  },
  {
    name: 'forms.residence.joint',
    next: 'forms.employment.joint',
    previous: 'forms.contact.joint'
  },
  {
    name: 'forms.employment.joint',
    next: 'vehicle.details',
    previous: 'forms.residence.joint'
  },
  {
    name: 'vehicle.details',
    next: 'forms.residence',
    previous: ({ hasJointApplicant } = {}) => {
      return hasJointApplicant ? 'forms.employment.joint' : 'forms.contact';
    }
  },
  {
    name: 'forms.residence',
    next: 'forms.employment',
    previous: 'vehicle.details'
  },
  {
    name: 'forms.employment',
    next: 'review',
    previous: 'forms.residence'
  },
  {
    name: 'review',
    next: 'loading',
    previous: 'forms.employment'
  },
  {
    name: 'loading',
    next: ({ legacyAppId } = {}) => {
      return typeof legacyAppId !== 'undefined' && legacyAppId !== null ? {
        name: 'thanks.id',
        params: { id: legacyAppId }
      } : 'thanks';
    },
    previous: 'review'
  }
];
