import { creditSnapEmploymentTypeMap } from '../values/application';

/**
 * Returns the CreditSnap employment type for the provided FastTrack emplyment type
 *
 * @param {string} fastTrackEmploymentType
 * @returns {string|null}
 */
export const toCreditSnapEmploymentType = (fastTrackEmploymentType) => {
  const type = (fastTrackEmploymentType || '').toUpperCase();

  return creditSnapEmploymentTypeMap[type] || null;
};
