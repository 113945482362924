import { applicantTypes, jointRelationTypes, langPref, loanTypes, options } from '../../values/application';
import {
  RATEGENIUS_NEW_APPLICATION,
  RG_DIRECT_MAIL,
  utmSourceSponsors,
  vscRestrictedSponsors,
  sponsorConfigs
} from '../../values/sponsor';

import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import has from 'lodash/has';
import store from './../../store/index';

const primaryApplicantPayloadFields = {
  PREFIX: null,
  FIRST_NAME: null,
  MIDDLE_NAME: null,
  LAST_NAME: null,
  SUFFIX: null,
  DOB: null,
  SSN: null,
  EMAIL: null,
  PHONE: null,
  RES_TYPE: null,
  ADDRESS: null,
  CITY: null,
  STATE: null,
  ZIP: null,
  ADDR_Y: null,
  ADDR_M: null,
  HOUSE_PMT: null
};

const employmentPayloadFields = {
  EMP_TYPE: null,
  EMPLOYER: null,
  EMP_TITLE: null,
  EMP_PHONE: null,
  EMP_Y: null,
  EMP_M: null,
  EMP_ADDRESS: null,
  EMP_APT: null,
  EMP_CITY: null,
  EMP_STATE: null,
  EMP_ZIP: null,
  GROSS_MTH_INC: null,
  OTHER_MTH_INC: null,
  OTHER_INC_TYPE: null,
  PREV_STATE: null,
  PREV_EMP_TYPE: null,
  PREV_EMPLOYER: null,
  PREV_EMP_TITLE: null,
  PREV_EMP_PHONE: null,
  PREV_EMP_Y: null,
  PREV_EMP_M: null,
  PREV_EMP_ADDRESS: null,
  PREV_EMP_APT: null,
  PREV_EMP_CITY: null,
  PREV_EMP_STATE: null
};

const jointApplicantPayloadFields = {
  JT_REL: null,
  JT_PREFIX: null,
  JT_FIRST_NAME: null,
  JT_MIDDLE_NAME: null,
  JT_LAST_NAME: null,
  JT_SUFFIX: null,
  JT_DOB: null,
  JT_SSN: null,
  JT_EMAIL: null,
  JT_PHONE: null,
  JT_RES_TYPE: null,
  JT_ADDRESS: null,
  JT_APT: null,
  JT_CITY: null,
  JT_STATE: null,
  JT_ZIP: null,
  JT_ADDR_Y: null,
  JT_ADDR_M: null,
  JT_HOUSE_PMT: null,
  JT_EMP_TYPE: null,
  JT_EMPLOYER: null,
  JT_EMP_TITLE: null,
  JT_EMP_PHONE: null,
  JT_EMP_EXT: null,
  JT_EMP_Y: null,
  JT_EMP_M: null,
  JT_EMP_ADDRESS: null,
  JT_EMP_APT: null,
  JT_EMP_CITY: null,
  JT_EMP_STATE: null,
  JT_EMP_ZIP: null,
  JT_GROSS_MTH_INC: null,
  JT_OTHER_MTH_INC: null,
  JT_OTHER_INC_TYPE: null,
  JT_PREV_ADDRESS: null,
  JT_PREV_APT: null,
  JT_PREV_CITY: null,
  JT_PREV_STATE: null,
  JT_PREV_ZIP: null,
  JT_PREV_ADDR_Y: null,
  JT_PREV_ADDR_M: null
};

const vehiclePayloadFields = {
  MAKE: null,
  MODEL: null,
  YEAR: null,
  BODY_STYLE: null,
  MILEAGE: null,
  VIN: null,
  PLATE: null,
  OPTIONS: null,
  COLOR: null,
  VEH_AGE: null
};

const financingPayloadFields = {
  CUR_PMT: null,
  CUR_LIEN: null,
  CUR_RATE: null,
  CUR_TERM: null,
  CUR_PAYOFF: null,
  FIRST_PMT: null,
  FIN_AMT: null
};

const metaPayloadFields = {
  utmstring: null,
  templateId: null,
  sponsorId: null,
  SOURCE_NAME: null,
  SOURCE_ID: null,
  APP_TYPE: null,
  LOAN_TYPE: null,
  PERM_PURPOSE: null,
  EMAIL_OPTION: null,
  LANG_PREF: null,
  COMMENTS: null,
  INTENT: null,
  LENDER_ID: null
};

export const applicationPayload = {
  ...metaPayloadFields,
  ...primaryApplicantPayloadFields,
  ...employmentPayloadFields,
  ...jointApplicantPayloadFields,
  ...vehiclePayloadFields,
  ...financingPayloadFields
};

const emptyToNaFields = [
  'PHONE',
  'RES_TYPE',
  'ADDRESS',
  'CITY',
  'STATE',
  'ZIP',
  'ADDR_Y',
  'ADDR_M',
  'HOUSE_PMT',
  'EMP_TYPE',
  'GROSS_MTH_INC',
  'EMPLOYER',
  'EMP_TITLE',
  'EMP_PHONE',
  'EMP_Y',
  'EMP_M',
  'JT_FIRST_NAME',
  'JT_LAST_NAME',
  'JT_DOB',
  'JT_PHONE',
  'JT_RES_TYPE',
  'JT_ADDRESS',
  'JT_CITY',
  'JT_STATE',
  'JT_ZIP',
  'JT_ADDR_Y',
  'JT_ADDR_M',
  'JT_HOUSE_PMT',
  'JT_GROSS_MTH_INC',
  'JT_EMPLOYER',
  'JT_EMP_TITLE',
  'JT_EMP_PHONE',
  'JT_EMP_Y',
  'JT_EMP_M'
];

function setEmptyPayloadValuesToNa (payload) {
  for (const x in payload) {
    if (emptyToNaFields.includes(x) && payload[x] === null) {
      payload[x] = options.NOT_APPLICABLE;
    }
  }
  return payload;
}

function setSpecificDefaultValues (payload) {
  const sponsorId = payload.sponsorId
    ? payload.sponsorId
    : RATEGENIUS_NEW_APPLICATION;

  let LENDER_ID = null;
  if (sponsorId === RG_DIRECT_MAIL) {
    LENDER_ID = store.getters['trackingData/lenderId'];
  }

  let sourceId = payload.SOURCE_ID;
  if (!sourceId) {
    sourceId = sponsorId;
  }

  return {
    ...payload,
    ...{
      PERM_PURPOSE: options.YES,
      EMAIL_OPTION: options.NO,
      LOAN_TYPE: loanTypes.REFINANCE,
      LANG_PREF: langPref.ENGLISH,
      SOURCE_ID: sourceId,
      sponsorId: sponsorId,
      LENDER_ID: LENDER_ID
    }
  };
}

function sanitizePayload (payload) {
  // clean out null values
  payload.utmstring = JSON.stringify(
    omitBy(JSON.parse(payload.utmstring), isNull)
  );

  return setEmptyPayloadValuesToNa(
    setSpecificDefaultValues(payload)
  );
}

export function getSponsorIdFromUtmSource (utmSource) {
  const source = (utmSource || '').toUpperCase();
  return utmSourceSponsors[source] ? utmSourceSponsors[source] : null;
}

function modifyPayloadForLegacy (payload) {
  const intent = payload.INTENT;
  const utmData = JSON.parse(payload.utmstring);

  delete payload.INTENT;

  // attributions object breaks legacy
  delete utmData.attributions;

  // cohabitant not listed in legacy
  if (payload.APP_TYPE === applicantTypes.JOINT) {
    payload.JT_REL = payload.JT_REL === jointRelationTypes.COHABITANT ? jointRelationTypes.OTHER : payload.JT_REL;
  }

  payload.utmstring = JSON.stringify({
    ...utmData,
    ...{
      intent
    }
  });

  return payload;
}

/**
 * Self contained Application Resource available globally to interact with api
 */
export class ApplicationService {
  constructor (
    api,
    nxgLenders,
    nxgSponsors,
    nxgThrottle
  ) {
    this.api = api;
    this.nxgLenders = nxgLenders;
    this.nxgSponsors = nxgSponsors;
    this.defaultNxgThrottle = nxgThrottle >= 0 ? nxgThrottle : 10;
  }

  /**
   * Conditionally send payload to appropriate API
   *
   * @param payload
   * @returns {Promise<*>}
   */
  async submit (payload) {
    const cleanPayload = sanitizePayload(payload);
    let response = null;

    if (this.isPayloadNxg(cleanPayload)) {
      response = await this.api.submitNxg(cleanPayload);
    } else {
      response = await this.api.submitLegacy(modifyPayloadForLegacy(cleanPayload));
    }

    return response;
  }

  /**
   * Determine where to send payload
   *
   * @param payload
   * @returns {boolean}
   */
  isPayloadNxg (payload) {
    let sendToNxg = false;
    let throttleValue = this.defaultNxgThrottle;
    const lenderId = store.getters['trackingData/lenderId'];

    // override throttle with sponsor config
    if (has(sponsorConfigs, [payload.sponsorId, 'throttleToNxg'])) {
      throttleValue = sponsorConfigs[payload.sponsorId].throttleToNxg;
    }

    if (
      Math.floor(Math.random() * 100) <= throttleValue ||
        this.nxgSponsors.includes(parseInt(payload.sponsorId)) ||
        this.nxgLenders.includes(parseInt(lenderId))
    ) {
      sendToNxg = true;
    }
    // if direct mail then check lender to see where it belongs
    if (payload.sponsorId === RG_DIRECT_MAIL && !lenderId) {
      if (!this.nxgLenders.includes(parseInt(lenderId))) {
        sendToNxg = false;
      }
    }
    if (vscRestrictedSponsors.includes(payload.sponsorId)) {
      sendToNxg = false;
    }

    return sendToNxg;
  }
}
