import { getVehicleModelsWithPlaceholderImage } from './getVehicleModelsWithPlaceholderImage';
import { isNonEmptyString } from '../../utilities/isNonEmptyString';

/**
 * Gets the vehicle model data for the provided year and make id and then
 * moves the provided vehicle model name to the front of the list (if provided)
 *
 * @param {({ year: number, makeId: number }) => Promise<Object[]>} getVehicleModels
 * @returns {({ year: number, makeId: number, modelName?: string }) => Promise<Object[]>}
 */
export const getVehicleModelsSortedByModelName = (
  getVehicleModels = getVehicleModelsWithPlaceholderImage()
) => async (
  year,
  makeId,
  modelName
) => {
  let vehicleModels = await getVehicleModels(year, makeId);

  if (isNonEmptyString(modelName)) {
    const modelQueryIndex = vehicleModels.findIndex(
      model => model.name === modelName
    );

    if (modelQueryIndex === -1) {
      // the provided vehicle model name does not exist in the list
      // return the model list unmodified
      return vehicleModels;
    }

    const queryModel = vehicleModels.slice(
      modelQueryIndex,
      (modelQueryIndex + 1)
    );

    vehicleModels.splice(modelQueryIndex, 1);
    vehicleModels = queryModel.concat(vehicleModels);
  }

  return vehicleModels;
};
