import get from 'lodash/get';
import {
  isRetryableError,
  isNetworkOrIdempotentRequestError
} from 'axios-retry';

const isPostRequest = (error) => get(error, 'config.method') === 'post';

export default {
  retryCondition (error) {
    // the current default behavior is just `isNetworkOrIdempotentRequestError`
    // this allows network errors and 500-599 status codes from POST requests
    // to also be retried (i.e. submit and mail code)
    return isNetworkOrIdempotentRequestError(error) ||
      (isRetryableError(error) && isPostRequest(error));
  },
  retryDelay (retryNumber) {
    return retryNumber * 1000;
  }
};
