import get from 'lodash/get';

export const actions = {
  setData ({ commit }, prequalData) {
    commit('SET_APR', get(prequalData, 'apr', null));
    commit('SET_AMOUNT', get(prequalData, 'amount', null));
    commit('SET_PAYMENT', get(prequalData, 'payment', null));
    commit('SET_TERM', get(prequalData, 'term', null));
  }
};
