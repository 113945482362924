import { httpRequest } from '../../../core/api/axios.config';
import {
  NETWORK_STATUS_CHECK_PATH,
  NETWORK_STATUS_CHECK_DELAY_IN_MILLISECONDS,
  NETWORK_STATUS_ERROR_CODE
} from '../../../values/connection';

const checkNetworkStatus = (httpRequest, timeoutInMilliseconds = 5000) => async (path = '/version.json') => {
  const startTime = Date.now();
  let success = false;
  let endTime;

  try {
    const response = await httpRequest.head(path, { timeout: timeoutInMilliseconds });
    success = response.status === 200;
  } catch { // In promise network errors must be caught in case of offline
  } finally {
    endTime = Date.now();
  }

  return {
    success,
    responseTimeInMilliseconds: success ? (endTime - startTime) : NETWORK_STATUS_ERROR_CODE
  };
};

export const actions = {
  startNetworkStatusCheck ({ commit, dispatch, rootGetters }) {
    return checkNetworkStatus(httpRequest, NETWORK_STATUS_CHECK_DELAY_IN_MILLISECONDS)(NETWORK_STATUS_CHECK_PATH)
      .then((networkStatus) => {
        commit('SET_IS_ONLINE', navigator.onLine);
        commit('SET_LATEST_RESPONSE_TIME_IN_MILLISECONDS', networkStatus.responseTimeInMilliseconds);
        commit('SET_NETWORK_STATUS', networkStatus);
      })
      .finally(() => {
        setTimeout(() => dispatch('startNetworkStatusCheck'), NETWORK_STATUS_CHECK_DELAY_IN_MILLISECONDS);
      });
  }
};
