export const RATEGENIUS_NEW_APPLICATION = 585;
export const RG_DIRECT_MAIL = 3;

export const LENDINGTREE = 1;
export const CREDIT_KARMA = 212;
export const RG_EMAIL = 23;
export const MSN = 33;
export const BENEPLACE = 59;
export const RG_TEST = 180;
export const CREDIT_DOT_COM = 213;
export const RG_INSURANCE = 218;
export const ALLIANCE_DIRECT = 219;
export const ELEADIST = 225;
export const BANK_RATE = 251;
export const LENDINGTREE_CALL = 272;
export const SHOP_AT_HOME = 279;
export const LOWER_MY_BILLS = 388;
export const LOWER_MY_BILLS_DISPLAY = 424;
export const LOWER_MY_CAR_NOTE = 473;
export const CAR_CHEX = 475;
export const CARS_INC = 495;
export const CARS_DIRECT = 524;
export const CREDIT_SESAME = 527;
export const PKLAS = 617;
export const PERK_SPOT = 621;
export const ACTON_KILL = 626;
export const ACTON_REFERRAL = 627;
export const READY_FOR_ZERO = 633;
export const LEADNOMICS = 634;
export const LENDING_TREE_EXCLUSIVE = 655;
export const LENDING_TREE_PURCHASE = 670;
export const FREEDOM_FINANCIAL = 675;
export const CONSUMER_ADVOCATE = 676;
export const BILL_ADVISOR = 678;
export const NATURAL_INTELLIGENCE = 680;
export const YOUTUBE_COMMON_CENTS = 681;
export const CALL_ENGINE = 683;
export const AUTO_CREDIT_EXPRESS = 203;
export const WEB_2_CARZ = 292;
export const RATE_RESET = 616;
export const FLEET_FINANCIAL = 641;
export const CITY_LENDING = 644;
export const LEADS_MARKET = 645;
export const NERDWALLET = 647;
export const NERDWALLET_2 = 785;
export const RG_CONTENT = 657;
export const RG_BLOG = 658;
export const FINANCESOURCE = 660;
export const CARSDIRECT_EMAIL = 662;
export const OPEN_ROAD_LENDING = 665;
export const SUITED_CONNECTOR = 666;
export const QUIN_STREET = 667;
export const EXTENDED_ASSET_PROTECTION = 672;
export const ZOOM_CAR_LOAN = 674;
export const REPAIR_BANC = 685;
export const CREDIT_KARMA_PREQUAL = 686;
export const MY_AUTO_LOAN = 20;
export const DECISIONLINKS = 741;
export const SUPERMONEY = 742;
export const AUTO_LOAN_ANALYZER = 743;
export const CREDIT_COUNCIL_CORP = 744;
export const ENTERTAINMENT_BENEFITS_GROUP = 745;
export const BRAND_X_ADS = 746;
export const CASH_MONEY_LIFE = 747;
export const GOOD_FINANCIAL_CENTS = 748;
export const TICKETS_AT_WORK = 749;
export const WORKING_ADVANTAGE = 750;
export const PLUM_BENEFITS = 751;
export const ISURE = 752;
export const MILLENNIAL_MONEY = 753;
export const DMVCOM = 754;
export const CARREG = 755;
export const CAR_LOANS_USA = 756;
export const B2DIRECT = 757;
export const SKY_BLUE_FINANCIAL_SERVICES = 740;
export const IMPEDE_MEDIA = 739;
export const THE_SIMPLE_DOLLAR = 738;
export const MONEY_UNDER_30 = 737;
export const MAG_MONEY = 736;
export const LARRY_HERNANDEZ = 735;
export const PREVENT_A_LEMON = 734;
export const MICHAEL_PROZY = 733;
export const KEITH_PAGE = 732;
export const RETAIL_ME_NOT = 731;
export const CDDKU = 730;
export const CDDKN = 729;
export const CDKU = 728;
export const CDKN = 727;
export const AUTO_CREDIT_EXPRESS_CALL_CAMPAIGN = 725;
export const AUTO_LOAN_RATE = 724;
export const FINDER = 717;
export const CREDIT_KARMA_DIRECT = 715;
export const MONEY_WU_RX = 712;
export const NAF_DIGITAL = 711;
export const CREDIFUL = 710;
export const SUPER_DINERO = 709;
export const ELITE_AUTO_LENDING = 705;
export const CREDIT_SESAME_SITE = 693;
export const SPEED_CENTS = 692;
export const AUTO_LOAN = 691;
export const CREDIT_SNAP = 690;
export const INSURE_STATION = 688;
export const AUTO_ASSURE = 646;
export const MAIL_MAN = 612;
export const AAA_ARIZONA = 580;
export const FIRST_INVESTORS = 576;
export const CRED_APPEAL = 564;
export const CHRISTIAN_REED = 541;
export const GEISTM = 726;
export const PINTEREST = 723;
export const REACH_MOBI = 719;
export const DIANOMI = 718;
export const MGID_NATIVE = 714;
export const NORTHWEST_SPECIAL_OLYMPICS = 713;
export const REV_CONTENT = 707;
export const POWER_INBOX = 706;
export const DEALER_AUTOMOTIVE_GROUP = 704;
export const FIRST_INSURANCE_FINANCIAL_SERVICES = 703;
export const SELF_LENDER = 702;
export const HANDLED_NET = 701;
export const NATIVE_ADS = 699;
export const NORTHWEST_COMMUNITY_SXU = 698;
export const AFFILIATE = 694;
export const LEND_EDU = 682;
export const ADS_TRACK = 679;
export const ACTIVE_HOURS = 656;
export const CORE_DIGITAL = 651;
export const REFI_MY_AUTO = 648;
export const RATEGENIUS_TODAY = 636;
export const RADIO = 628;
export const NEW_AUTO_RATE = 620;
export const REFINANCE_MY_RIDE = 599;
export const CSX_RETIREE = 530;
export const DATCU_ANB = 474;
export const DM_EMAIL = 442;
export const AARP = 381;
export const INTERNATIONAL_HARVESTER = 378;
export const PENN_STATE = 368;
export const MATADOR = 363;
export const LIBERTY_FIRST = 361;
export const JACKSON = 358;
export const FIRST_AMERICAN = 352;
export const FAMILY = 351;
export const DUPACO = 349;
export const ASCENTRA = 340;
export const ARIZONA = 339;
export const THREERIVERS = 336;
export const SMART_FINANCIAL = 322;
export const SUMMIT_MOBILE = 317;
export const SUMMIT_PURL = 316;
export const DPDDATA = 301;
export const UPS = 290;
export const NSPHERE = 288;
export const AUTOREFINANCEREVIEW = 277;
export const VOICEPROCESS = 249;
export const NEXT_AUTO = 245;
export const AUTO_CRICKET = 233;
export const ERA = 227;
export const LEASE_EXCHANGE = 201;
export const CHAD_SIMMONS = 199;
export const GLOBAL_MARKETING_NETWORK = 197;
export const AAA_DIRECT_MAIL = 193;
export const AAA_WEB = 192;
export const AUTOMART_DOT_COM_3 = 191;
export const SPROCKETS_MEDIA = 182;
export const AOL = 177;
export const LINKSHARE_PAY_PER_CALL = 159;
export const MERCHANT_ACCESS = 158;
export const LOWER_MY_PAYMENTS = 154;
export const TRADE_EXPRESS = 134;
export const AUTO_APPROVED = 77;
export const BENEPLACE_CSX_CORPORATION = 531;
export const BENEPLACE_CENTURA_HEALTH = 331;
export const BENEPLACE_HP = 328;
export const BENEPLACE_CONOCO_PHILLIPS = 325;
export const BENEPLACE_UTI_ALUMNI = 306;
export const ACQUINITY_INTERACTIVE = 303;
export const COBALT_GOOM7 = 267;
export const BENEPLACE_BAYLOR_HEALTH_CARE_SYSTEM = 238;
export const BENEPLACE_WASTE_MANAGEMENT = 185;
export const BENEPLACE_BENJAMIN_MOORE = 174;
export const ADFUSION_02 = 170;
export const BENEPLACE_FMC = 160;
export const BENEPLACE_HONEYWELL = 153;
export const LOOKSMART_LOAN_GENERAL_FINANCE = 139;
export const LOOKSMART_ONLINE_CAR_REFINANCE = 106;
export const LOOKSMART_AUTOREFINANCE = 102;
export const LOOKSMART_AUTO_REFINANCING_COMPANIES = 100;
export const LOOKSMART_AUTO_LEASE_REFINANCING = 93;
export const FLEX_OFFERS = 758;
export const CREDIT_KARMA_2 = 759;
export const OMEGA = 760;
export const DRIVE_AMERICA = 761;
export const REPAIR_BANC_1 = 762;
export const DIRECT_MAIL_RETARGETING = 765;
export const CLUTCH = 766;
export const ALLWEB = 767;
export const COVER = 768;
export const VSCD = 769;
export const COMPLETE = 770;
export const BRIGHT = 771;
export const DEBT = 772;
export const BIGTWIN = 773;
export const RG_TV = 774;
export const NATURAL_INTELIGENCE_2 = 778;
export const CARFI_DIRECT = 792;
export const FINANCE_BUZZ = 798;
export const BRADS_DEALS = 799;
export const CARFUNDY = 803;
export const FLEX = 804;
export const JUNO_STUDENT_LOAN_REFINANCE = 805;
export const CAPEXCH = 813;
export const ALLSTATE = 796;
export const RATE_GENIUS_MAIL_SPONSOR_ID = 837;
export const PERFORM_CB = 842;
export const SECCO_SQUARED = 843;

// Experian
export const EXPERIAN = 505;
export const EXPERIAN_2 = 782;
export const RATEGENIUS_EXPERIAN_EMAIL = 314;

// Google
export const GOOGLE = 22;
export const GOOGLE_NEW_AD = 168;
export const GOOGLE_03 = 720;
export const GOOGLE_04 = 721;
export const GOOGLE_05 = 722;

export const JALOPNIK = 711;
export const NAFMSN = 711;
export const DAG = 704;
export const POWERINBOX_RG = 706;
export const TABOOLA = 629;
export const OUTBRAIN = 630;
export const GEMINI = 708;
export const REVCONTENT = 707;
export const FACEBOOK = 236;
export const BING = 33;

export const vscRestrictedSponsors = [
  OMEGA, DRIVE_AMERICA, REPAIR_BANC
];

/* ===== UTM MAP ===== */
export const utmSourceSponsors = {
  JALOPNIK,
  NAFMSN,
  DAG,
  POWERINBOX_RG,
  TABOOLA,
  OUTBRAIN,
  GEMINI,
  REVCONTENT,
  FACEBOOK,
  GOOGLE,
  BING,
  DMVCOM,
  CARREG
};

/* ===== Sponsors ===== */
export const activeSponsors = {
  LENDINGTREE,
  RATEGENIUS_NEW_APPLICATION,
  RG_DIRECT_MAIL,
  OMEGA,
  DRIVE_AMERICA,
  REPAIR_BANC,
  CREDIT_KARMA,
  CREDIT_KARMA_2,
  GOOGLE,
  AUTO_CREDIT_EXPRESS,
  CARS_DIRECT,
  CARSDIRECT_EMAIL,
  CARFUNDY,
  FLEX,
  JUNO_STUDENT_LOAN_REFINANCE,
  NATURAL_INTELLIGENCE,
  NATURAL_INTELIGENCE_2,
  COMPLETE,
  EXPERIAN,
  EXPERIAN_2,
  GOOGLE_NEW_AD,
  GOOGLE_03,
  GOOGLE_04,
  GOOGLE_05,
  RATEGENIUS_EXPERIAN_EMAIL,
  CAPEXCH,
  ALLSTATE,
  ...utmSourceSponsors
};

export const sponsorConfigs = {
  [CREDIT_KARMA]: {
    throttleToNxg: 30
  },
  [BRADS_DEALS]: {
    throttleToNxg: 10
  },
  [NERDWALLET]: {
    throttleToNxg: 80
  },
  [NERDWALLET_2]: {
    throttleToNxg: 80
  }
};
