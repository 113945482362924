import { creditSnapHttpRequest } from '../axios.config';
import { submitRequestToCreditSnap } from './submitRequestToCreditSnap';

/**
 * PUTs the credit snap application using the provided axios instance
 *
 * @param {AxiosInstance} httpRequest
 * @returns {( applicationId: (string|number), creditSnapOffer: Object) => Promise<*>}
 */
export const submitOfferToCreditSnap = (
  httpRequest = creditSnapHttpRequest
) => (
  applicationId,
  creditSnapOffer
) =>
  submitRequestToCreditSnap(httpRequest)(
    `/partner/application/${applicationId}/offers`,
    'PUT',
    creditSnapOffer
  );
