import { BI_WEEKLY, YEARLY } from '../values/application';

/**
 * Converts an income value which could be yearly, monthly or bi-weekly to a monthly value
 *
 * @param {Object} options
 * @param {string} options.frequency
 * @param {number} options.value
 *
 * @returns {number|null}
 */
export const convertIncomeToMonthly = ({ frequency, value } = {}) => {
  if (value === null || !isFinite(value)) {
    return null;
  }

  if (frequency === YEARLY) {
    return Math.round(value / 12);
  }

  if (frequency === BI_WEEKLY) {
    // multiply the amount by 26 (half of 52 weeks in a year)
    // first to calculate the total additional income for the year
    // then divide by 12 to get the amount per month
    return Math.round((value * 26) / 12);
  }

  // we will assume if the frequency is not
  // yearly or bi-weekly, then it is monthly
  return value;
};
