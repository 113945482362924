import { getApplicationTypeForLeadChannel } from './getApplicationTypeForLeadChannel';
import { getApplicationTypeFromStorage } from './getApplicationTypeFromStorage';
import { getApplicationTypeFromSearchParams } from './getApplicationTypeFromSearchParams';
import { setApplicationTypeInStorage } from './setApplicationTypeInStorage';

export const getAndSetApplicationType = (
  leadChannelCode,
  getFromSearchParams = getApplicationTypeFromSearchParams,
  getFromStorage = getApplicationTypeFromStorage,
  getApplicationType = getApplicationTypeForLeadChannel,
  setApplicationType = setApplicationTypeInStorage
) => () => {
  const applicationType = getApplicationType(
    leadChannelCode,
    getFromSearchParams,
    getFromStorage
  )();

  setApplicationType(leadChannelCode, applicationType);

  return applicationType;
};
