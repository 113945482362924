import isNaN from 'lodash/isNaN';
import round from 'lodash/round';
import { cleanValue } from './Currency';

/**
 * Converts the string or number value to float
 *
 * @param {string|number} value
 * @returns {number}
 */
export const convertToFloat = (value) => {
  const convertedValue = round(parseFloat(cleanValue(value) || 0), 2);
  return !isNaN(convertedValue)
    ? convertedValue
    : null;
};
