import get from 'lodash/get';
import { pascalCase } from './String';

/**
 * Returns a function that can be used to sort vehicle makes
 * based on the provided make list array
 *
 * If the vehicle make is not in the provided make list,
 * it will be moved to the end of the list
 *
 * NOTE: to get `_.sortBy` to return the expected makes order, you need to
 * reverse the order of the provided make list
 *
 * @param {string[]} makeList
 * @returns {({ id: number, name: string }) => number}
 */
export const getVehicleMakesSorterFunction = (makeList = []) => (make) => {
  const makeName = pascalCase(get(make, 'name', '').toLowerCase());

  return makeList.includes(makeName)
    ? -makeList.indexOf(makeName)
    : 1;
};
