export const WEBSTER = 77;
export const AFFINITY = 90;
export const CAROLINATRUST = 137;
export const GARDENSAVINGS = 153;
export const HIWAYFCU = 158;
export const MATADORS = 159;
export const FIRSTINVESTORS = 180;
export const EAGLECOMMUNITYCU = 249;
export const BRIGHTSTARCU = 285;
export const ELEMENTSFINANCIAL = 306;
export const ANDREWSFCU = 312;
export const FINANCIALPARTNERS = 326;
export const HIWAYLENDPRO = 332;
export const AFFINITYLENDPRO = 334;
export const TROPICALFCU = 337;
export const FIRSTCLASSAMERICAN = 352;
export const SEATTLEMETRO = 361;
export const EECU = 407;
export const ALLINCREDITUNION = 411;

/* ===== Lenders ===== */
export const activeLenders = {
  WEBSTER,
  AFFINITY,
  CAROLINATRUST,
  GARDENSAVINGS,
  HIWAYFCU,
  MATADORS,
  FIRSTINVESTORS,
  EAGLECOMMUNITYCU,
  BRIGHTSTARCU,
  ELEMENTSFINANCIAL,
  ANDREWSFCU,
  FINANCIALPARTNERS,
  HIWAYLENDPRO,
  AFFINITYLENDPRO,
  TROPICALFCU,
  FIRSTCLASSAMERICAN,
  SEATTLEMETRO,
  EECU,
  ALLINCREDITUNION
};
