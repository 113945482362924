import {
  convertToFloatIfStringOrNumber
} from '../../../utilities/convertToFloatIfStringOrNumber';

export const mutations = {
  SET_MAKE (state, make) {
    state.make = make;
  },
  SET_MAKES (state, makes) {
    state.makes = makes;
  },
  SET_PAYOFF_AMOUNT (state, payoffAmount) {
    state.payoffAmount =
      convertToFloatIfStringOrNumber(payoffAmount);
  },
  SET_PAYOFF_AMOUNT_CONFIRMED (state, payoffAmountConfirmed) {
    state.payoffAmountConfirmed = payoffAmountConfirmed;
  },
  SET_PAYOFF_AMOUNT_CONFIRMED_VALUE (state, payoffAmountConfirmedValue) {
    state.payoffAmountConfirmedValue =
      convertToFloatIfStringOrNumber(payoffAmountConfirmedValue);
  },
  SET_PURCHASE_PRICE (state, purchasePrice) {
    state.purchasePrice =
      convertToFloatIfStringOrNumber(purchasePrice);
  },
  SET_PURCHASE_PRICE_CONFIRMED (state, purchasePriceConfirmed) {
    state.purchasePriceConfirmed = purchasePriceConfirmed;
  },
  SET_PURCHASE_PRICE_CONFIRMED_VALUE (state, purchasePriceConfirmedValue) {
    state.purchasePriceConfirmedValue =
      convertToFloatIfStringOrNumber(purchasePriceConfirmedValue);
  },
  SET_MILEAGE (state, mileage) {
    state.mileage = mileage;
  },
  SET_MILEAGE_CONFIRMED (state, mileageConfirmed) {
    state.mileageConfirmed = mileageConfirmed;
  },
  SET_MILEAGE_CONFIRMED_VALUE (state, mileageConfirmedValue) {
    state.mileageConfirmedValue = mileageConfirmedValue;
  },
  SET_MODEL (state, model) {
    state.model = model;
  },
  SET_MODEL_IMAGES_REQUEST_FINISHED (state, finished) {
    state.hasModelImagesRequestFinished = finished;
  },
  SET_MODELS (state, models) {
    state.models = models;
  },
  SET_MODELS_REQUEST_FINISHED (state, finished) {
    state.hasModelsRequestFinished = finished;
  },
  SET_TRIM (state, trim) {
    state.trim = trim;
  },
  SET_TRIMS (state, trims) {
    state.trims = trims;
  },
  SET_YEAR (state, year) {
    state.year = year;
  },
  SET_YEARS (state, years) {
    state.years = years;
  }
};
