import get from 'lodash/get';

export function processAndCompareOffer (fetchedOffer) {
  const originalPayment = parseInt(
    get(fetchedOffer, 'request.payload.monthly_payment', 0),
    10
  );

  const newPayment = parseFloat(get(fetchedOffer, 'monthlyPayment', 0));
  const newTerm = parseInt(get(fetchedOffer, 'term', 0), 10);
  const loanAmount = parseFloat(get(fetchedOffer, 'loanAmount', 0));

  const remainingTermFromPayload = parseInt(
    get(fetchedOffer, 'request.payload.remainingTerm', null),
    10
  );

  const remainingBalance = parseInt(
    get(fetchedOffer, 'request.payload.remainingBalance', null),
    10
  );

  const remainingTerm = parseInt(
    get(fetchedOffer, 'request.remainingTerm', 0),
    10
  );

  const monthlyPaymentSavings = Math.max(0, originalPayment - newPayment);

  const offerTotalOfPayments = newPayment * newTerm;
  const offerTotalInterest = offerTotalOfPayments - loanAmount;
  const termDifference = newTerm - remainingTermFromPayload;
  const offerTotalSavings = (originalPayment * remainingTermFromPayload - newPayment * newTerm);
  const remainingInterest = (originalPayment * remainingTerm) - remainingBalance;
  const interestRateDifference = remainingInterest - offerTotalInterest;
  const monthlyArrow = newPayment > originalPayment ? 'up' : 'down';
  const termArrow = newTerm > remainingTerm ? 'up' : 'down';

  const offerComparedValues = {
    totalCost: offerTotalOfPayments,
    totalSavings: !isNaN(offerTotalSavings) ? offerTotalSavings : null,
    totalInterest: offerTotalInterest,
    interestSavings: !isNaN(interestRateDifference) ? interestRateDifference : null,
    monthlyPaymentSavings,
    termArrow,
    monthlyPaymentArrow: monthlyArrow,
    termSavings: !isNaN(termDifference) ? termDifference : null
  };

  return offerComparedValues;
}
