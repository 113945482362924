const EmptyVueRouterForNesting = () => import(/* webpackChunkName: "initial-payload" */ '../components/global/EmptyVueRouterForNesting');

const ContactMain = () => import('../components/forms/ContactMain');
const ContactJoint = () => import('../components/forms/ContactJoint');
const EmploymentMain = () => import('../components/forms/EmploymentMain');
const EmploymentJoint = () => import('../components/forms/EmploymentJoint');
const Pre = () => import(/* webpackChunkName: "initial-payload" */ '../components/forms/Pre');
const ResidenceMain = () => import('../components/forms/ResidenceMain');
const ResidenceJoint = () => import('../components/forms/ResidenceJoint');

// Forms
export const formRoutes = {
  path: 'forms',
  component: EmptyVueRouterForNesting,
  children: [
    {
      path: '',
      redirect: '/pre'
    },
    {
      path: 'contact',
      component: EmptyVueRouterForNesting,
      children: [
        {
          path: '',
          name: 'forms.contact',
          component: ContactMain
        },
        {
          path: 'coborrower',
          name: 'forms.contact.joint',
          component: ContactJoint
        }
      ]
    },
    {
      path: 'employment',
      component: EmptyVueRouterForNesting,
      children: [
        {
          path: '',
          name: 'forms.employment',
          component: EmploymentMain
        },
        {
          path: 'coborrower',
          name: 'forms.employment.joint',
          component: EmploymentJoint
        }
      ]
    },
    {
      path: 'pre',
      name: 'forms.pre',
      component: Pre
    },
    {
      path: 'residence',
      component: EmptyVueRouterForNesting,
      children: [
        {
          path: '',
          name: 'forms.residence',
          component: ResidenceMain
        },
        {
          path: 'coborrower',
          name: 'forms.residence.joint',
          component: ResidenceJoint
        }
      ]
    }
  ]
};
