import isNull from 'lodash/isNull';
import some from 'lodash/some';
import values from 'lodash/values';
import { CampaignAttribution } from './ingress/attributionTypes';
import { isNonEmptyString } from '../../utilities/isNonEmptyString';

export class StorageService {
  /**
   *
   * @param {Vuex} vuexStore
   */
  constructor (vuexStore) {
    this.vuexStore = vuexStore;
  }

  /**
   *
   * @param {AttributionLog} attributionLog
   */
  syncAttributions (attributionLog) {
    const attribution = attributionLog.getCurrentAttribution();
    // put data from our services into the store
    this.vuexStore.dispatch('trackingData/setSponsorId', attribution.sponsorId);
    this.vuexStore.dispatch('trackingData/setSponsorClickId', attribution.sponsorClickId);
    this.vuexStore.dispatch('trackingData/setReferId', attribution.referData);
    if (attribution instanceof CampaignAttribution) {
      this.vuexStore.dispatch('trackingData/setCampaignId', attribution.campaignId);
    }
    this.vuexStore.dispatch('trackingData/setAttributionLog', attributionLog);
  }

  /**
   *
   * @param {CookieService} cookieService
   */
  syncGoogleVisitorId (cookieService) {
    this.vuexStore.dispatch('trackingData/setGoogleVisitorId', cookieService.getCookieGoogleVisitorId());
  }

  /**
   *
   * @param {WorkflowService} workflowService
   */
  syncWorkflowInfo (workflowService) {
    this.vuexStore.dispatch('trackingData/setWorkflow', workflowService.activeFlow);
  }

  /**
   *
   * @param {QueryService} initialQuery
   */
  syncQueryData (initialQuery) {
    const applicantData = initialQuery.getApplicantData();

    this.vuexStore.dispatch(
      'trackingData/setUtmAsObject',
      initialQuery.getUtms()
    );

    this.vuexStore.dispatch(
      'trackingData/setTemplateId',
      initialQuery.getTemplateData().templateId
    );

    this.vuexStore.dispatch(
      'trackingData/setPrequalId',
      initialQuery.getPrequalId()
    );

    if (isNonEmptyString(initialQuery.getIntent().intent)) {
      this.vuexStore.dispatch(
        'global/setReasonForLoanChange',
        initialQuery.getIntent().intent
      );
    }

    if (isNonEmptyString(applicantData.primary.email)) {
      this.vuexStore.dispatch(
        'applicant/setPrimaryApplicantEmailAddress',
        applicantData.primary.email
      );
    }

    if (isNonEmptyString(applicantData.primary.firstName)) {
      this.vuexStore.dispatch(
        'applicant/setPrimaryApplicantFirstName',
        applicantData.primary.firstName
      );
    }

    if (isNonEmptyString(applicantData.primary.lastName)) {
      this.vuexStore.dispatch(
        'applicant/setPrimaryApplicantLastName',
        applicantData.primary.lastName
      );
    }

    if (isNonEmptyString(applicantData.primary.mobileNumber)) {
      this.vuexStore.dispatch(
        'applicant/setPrimaryApplicantMobileNumber',
        applicantData.primary.mobileNumber
      );
    }

    if (some(values(initialQuery.getApplicantAddress()), (val) => !isNull(val))) {
      this.vuexStore.dispatch(
        'residence/setPrimaryApplicantCurrentAddress',
        initialQuery.getApplicantAddress()
      );
    }

    if (initialQuery.getVehicleData().year) {
      this.vuexStore.dispatch(
        'vehicle/setYearFromString',
        initialQuery.getVehicleData().year
      );
    }

    if (initialQuery.getVehicleData().make) {
      this.vuexStore.dispatch(
        'vehicle/setMakeFromString',
        initialQuery.getVehicleData().make
      );
    }

    if (initialQuery.getVehicleData().model) {
      this.vuexStore.dispatch(
        'vehicle/setModelFromString',
        initialQuery.getVehicleData().model
      );
    }

    if (initialQuery.getVehicleData().trim) {
      this.vuexStore.dispatch(
        'vehicle/setTrimFromString',
        initialQuery.getVehicleData().trim
      );
    }

    if (initialQuery.getIncomeData().grossAnnualIncome) {
      this.vuexStore.dispatch(
        'employment/setPrimaryApplicantGrossAnnualIncomeAtCurrentEmployment',
        initialQuery.getIncomeData().grossAnnualIncome
      );
    }

    if (initialQuery.shouldMockOffer() === 'true') {
      this.vuexStore.dispatch(
        'offers/setApplicationShouldMockOffer',
        initialQuery.shouldMockOffer() === 'true'
      );
    }

    if (initialQuery.getOfferUuid()) {
      this.vuexStore.dispatch(
        'offers/setOfferUuid',
        initialQuery.getOfferUuid()
      );
    }
  }
}
