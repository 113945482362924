import cloneDeep from 'lodash/cloneDeep';

/**
 * A centralized way to alter many vuex store modules.
 */
export class StoreModifier {
  constructor ({
    actions,
    getters,
    mutations,
    namespaced,
    state
  }) {
    this.actions = actions;
    this.getters = getters;
    this.mutations = mutations;
    this.namespaced = namespaced;
    this.state = state;

    this.makeResettable();
  }

  makeResettable () {
    // Store the initial state as a deep clone. This will break the reference to
    // the state object and changes will not be reflected in the initial state,
    // helping us to be able to reset the store modules properly
    const initialState = cloneDeep(this.state);

    this.mutations.RESET = (state) => {
      for (const item in state) {
        // Set the state item as a deep clone to break the reference to the
        // initial state.
        // This ensures that we don't modify the initial state after reset
        state[item] = cloneDeep(initialState[item]);
      }
    };
    this.actions.resetState = ({ commit }) => {
      commit('RESET');
    };
  }
}
