/**
 * Generates the path for the provided organization code and lead channel
 *
 * We could theoretically default the organization code and lead channel values
 * here using the DEFAULT_BRAND_ORGANIZATION_CODE and DEFAULT_BRAND_LEAD_CHANNEL...
 *
 * However, if a different lead channel happens to provide undefined/null values, (not sure how likely this is yet...)
 * we would return the incorrect path for the lead channel, which would likely result
 * in a failure to submit the application due to the bearer token mismatch
 *
 * So I decided to return the correct number of path segments to allow APEX to
 * reject the request based on the path being incorrect.
 *
 * Is this the correct decision? I do not know...
 *
 * @param {string} organizationCode
 * @param {string} leadChannel
 * @returns {string}
 */
export const getAutopayApplicationPath = (
  organizationCode,
  leadChannel
) =>
  '/' + [
    'api',
    '2.0',
    'lead',
    'inbound',
    'organization',
    organizationCode || '',
    'channel',
    leadChannel || '',
    'event',
    'RATES_REQUESTED',
    'version',
    '1.0.0'
  ].join('/');
