import { consumerPortalHttpRequest } from '../axios.config';
import { extractErrorInformation } from '../extractErrorInformation';
import { reportError } from '../../errors/reportError';
import { mapValueAndLabelToIdAndName } from '../../../utilities/mapValueAndLabelToIdAndName';

/**
 * Gets the vehicle make data for the provided year
 *
 * @param {*} httpRequest
 * @returns {(year: number) => Promise<{ id: number, name: string }[]>}
 */
export const getVehicleMakesFromConsumerPortal = (
  httpRequest = consumerPortalHttpRequest
) => async (
  year
) => {
  try {
    const response = await httpRequest.get(
      `/api/vehicles/years/${year}/makes`
    );

    return mapValueAndLabelToIdAndName(response.data);
  } catch (error) {
    reportError(error, { extra: extractErrorInformation(error) });
    throw error;
  }
};
