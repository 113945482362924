import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import round from 'lodash/round';

export const getters = {
  applicantPayload: (state) => get(state, 'offer.request.payload', null),
  applicantData: (state) => state.offerApplicantData,
  consumerSelectedRate: (state, { hasOffer, loanAmount, lenderName, monthlyPaymentAmount, rateAsDecimal, term }) => {
    if (!hasOffer) {
      return null;
    }

    const selectedRate = {
      lenderName,
      loanAmount,
      monthlyPaymentAmount,
      rate: rateAsDecimal,
      selectedDate: new Date().toISOString(),
      selectedSource: 'LEAD_PARTNER_PLATFORM',
      term
    };

    // trim any data that is missing, otherwise APEX will not be too happy
    Object.keys(selectedRate).forEach((key) => {
      if (!selectedRate[key]) {
        delete selectedRate[key];
      }
    });

    return selectedRate;
  },
  firstName: (state) => get(state, 'offer.request.payload.first_name', null),
  hasOfferAndUuid: (state) => !isEmpty(state.offer) && state.offerUuid !== null,
  offer: (state) => get(state, 'offer', null),
  hasOffer: (state) => !isEmpty(state.offer),
  lenderName: state => get(state, 'offer.lender', null),
  loanAmount: state => get(state, 'offer.loanAmount', null),
  monthlyPaymentAmount: state => get(state, 'offer.monthlyPayment', null),
  offerFailed: (state) => state.offerFailed,
  offerUuid: (state) => state.offerUuid,
  rate: state => get(state, 'offer.rate', null),
  rateAsDecimal: (state, { rate }) => {
    return isNumber(rate) ? round((rate / 100), 4) : null;
  },
  term: state => get(state, 'offer.term', null),
  vehicleVIN: (state) => get(state, 'offer.request.payload.vin', get(state, 'offer.request.payload.VIN', null)),
  vehicleYear: (state) => get(state, 'offer.request.payload.vehicle_year', null),
  vehicleMake: (state) => get(state, 'offer.request.payload.vehicle_make', null),
  vehicleModel: (state) => get(state, 'offer.request.payload.vehicle_model', null),
  vehicleTrim: (state) => get(state, 'offer.request.payload.vehicle_trim', null)
};
