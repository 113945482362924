import axios from 'axios';
import axiosRetry from 'axios-retry';
import axiosRetryConfig from './axios-retry.config';

const httpRequest = axios.create();

const autopayHttpRequest = axios.create({
  baseURL: process.env.AUTOPAY_APEX_BASE
});

axiosRetry(autopayHttpRequest, axiosRetryConfig);

const creditSnapHttpRequest = axios.create({
  baseURL: process.env.CREDIT_SNAP_BASE
});

const consumerPortalHttpRequest = axios.create({
  baseURL: process.env.CONSUMER_PORTAL_BASE
});

axiosRetry(consumerPortalHttpRequest, axiosRetryConfig);

const nxgHttpRequest = axios.create({
  baseURL: process.env.NXG_BASE
});

const legacyHttpRequest = axios.create({
  baseURL: process.env.LEGACY_BASE
});

const treslHttpRequest = axios.create({
  baseURL: process.env.TRESL_CREATE_SCHEDULE_URL
});

const sponsorPostback = axios.create({
  maxRedirects: 0
});

export {
  httpRequest,
  autopayHttpRequest,
  creditSnapHttpRequest,
  consumerPortalHttpRequest,
  nxgHttpRequest,
  legacyHttpRequest,
  treslHttpRequest,
  sponsorPostback
};
