import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';

/**
 * Cast raw object to page object
 *
 * @param rawObjects
 * @returns {Page[]}
 */
function castPages (rawObjects) {
  return keyBy(rawObjects.map((rawPageObject) => new Page(rawPageObject)), 'name');
}

/**
 * Just a type cast object
 */
export class Page {
  /**
   * Constructor
   *
   * @param name
   * @param next
   * @param previous
   */
  constructor ({ name, next, previous }) {
    this.name = name;
    this.next = next;
    this.previous = previous;
  }
}

/**
 * Collection of pages
 */
export class Workflow {
  /**
   * Constructor
   *
   * @param pages
   */
  constructor (pages) {
    this.pages = castPages(pages || []);
  }

  /**
   * Returns a page object
   * @param page
   * @returns {Page}
   */
  getPage (page) {
    return this.pages[page];
  }

  /**
   * Get approximate percentage of completion
   *
   * @param page
   * @returns {number}
   */
  getPageProgress (page) {
    const pageKeys = this.keys;
    const pos = pageKeys.indexOf(page);
    return Math.round((pos / pageKeys.length) * 100);
  }

  /**
   * Return array page names
   *
   * @returns {Array}
   */
  get keys () {
    return keys(this.pages);
  }

  /**
   * Length accessor
   *
   * @returns {number}
   */
  get length () {
    return this.keys.length;
  }
}
