import { applicantTypes } from '../../../values/application';

export const state = {
  applicantType: applicantTypes.SINGLE,
  primary: {
    dateOfBirth: null,
    previousEmailAddress: null,
    emailAddress: null,
    firstName: null,
    lastName: null,
    mobileNumber: null,
    ssn: null
  },
  joint: {
    dateOfBirth: null,
    emailAddress: null,
    firstName: null,
    lastName: null,
    mobileNumber: null,
    relationshipToPrimary: null,
    ssn: null
  }
};
