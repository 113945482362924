import {
  convertToFloatIfStringOrNumber
} from '../../../utilities/convertToFloatIfStringOrNumber';

export const mutations = {
  SET_APP_ID (state, appId) {
    state.appId = appId;
  },
  SET_APPLICATION_TYPE (state, applicationType) {
    state.applicationType = applicationType;
  },
  SET_AMOUNT_FINANCED (state, amountFinanced) {
    state.amountFinanced = convertToFloatIfStringOrNumber(amountFinanced);
  },
  SET_HAS_SEEN_HOME (state, hasSeenHome) {
    state.hasSeenHome = hasSeenHome;
  },
  SET_LOADING_PERCENT (state, loadingPercent) {
    state.loadingPercent = Math.floor(loadingPercent / 5) * 5;
  },
  SET_ORIGIN_MARKETING_SOURCE_URL (state, originMarketingSourceUrl) {
    state.originMarketingSourceUrl = originMarketingSourceUrl;
  },
  SET_REASON_FOR_LOAN_CHANGE (state, reasonForLoanChange) {
    state.reasonForLoanChange = reasonForLoanChange;
  },
  SET_SELECTED_OFFER_ID (state, selectedOfferId) {
    state.selectedOfferId = selectedOfferId;
  },
  SET_SUBMITTING_APPLICATION (state, isSubmittingApplication) {
    state.isSubmittingApplication = isSubmittingApplication;
  },
  SET_APPLICATION_SUBMITTED (state, hasApplicationBeenSubmitted) {
    state.hasApplicationBeenSubmitted = hasApplicationBeenSubmitted;
  },
  SET_SUBMISSION_ERROR (state, error) {
    state.submissionError = error;
  },
  SET_SUBMISSION_RESPONSE (state, response) {
    state.submissionResponse = response;
  },
  SET_SUBMISSION_STATUS (state, status) {
    state.submissionStatus = status;
  },
  SET_SUBMISSION_TIME (state, datetime) {
    state.submissionTime = datetime;
  },
  SET_THE_SAVINGS_GROUP_TERMS (state, theSavingsGroupTerms) {
    state.theSavingsGroupTerms = theSavingsGroupTerms;
  },
  SET_THE_SAVINGS_GROUP_PRIVACY_POLICY (state, theSavingsGroupPrivacyPolicy) {
    state.theSavingsGroupPrivacyPolicy = theSavingsGroupPrivacyPolicy;
  },
  SET_THE_SAVINGS_GROUP_RENDERED_CONTENT_ERROR (state, theSavingsGroupContentError) {
    state.theSavingsGroupContentError = theSavingsGroupContentError;
  },
  SET_TRESL_SCHEDULE_ERROR (state, treslScheduleError) {
    state.treslScheduleError = treslScheduleError;
  },
  SET_WINDOW_WIDTH (state, width) {
    state.width = isFinite(width) ? Number(width) : 0;
  }
};
