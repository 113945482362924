export class States {
  constructor () {
    this.AL = 'Alabama';
    this.AK = 'Alaska';
    this.AZ = 'Arizona';
    this.AR = 'Arkansas';
    this.CA = 'California';
    this.CO = 'Colorado';
    this.CT = 'Connecticut';
    this.DE = 'Delaware';
    this.DC = 'District of Columbia';
    this.FL = 'Florida';
    this.GA = 'Georgia';
    this.HI = 'Hawaii';
    this.ID = 'Idaho';
    this.IL = 'Illinois';
    this.IN = 'Indiana';
    this.IA = 'Iowa';
    this.KS = 'Kansas';
    this.KY = 'Kentucky';
    this.LA = 'Louisiana';
    this.ME = 'Maine';
    this.MD = 'Maryland';
    this.MA = 'Massachusetts';
    this.MI = 'Michigan';
    this.MN = 'Minnesota';
    this.MS = 'Mississippi';
    this.MO = 'Missouri';
    this.MT = 'Montana';
    this.NE = 'Nebraska';
    this.NV = 'Nevada';
    this.NH = 'New Hampshire';
    this.NJ = 'New Jersey';
    this.NM = 'New Mexico';
    this.NY = 'New York';
    this.NC = 'North Carolina';
    this.ND = 'North Dakota';
    this.OH = 'Ohio';
    this.OK = 'Oklahoma';
    this.OR = 'Oregon';
    this.PA = 'Pennsylvania';
    this.PR = 'Puerto Rico';
    this.RI = 'Rhode Island';
    this.SC = 'South Carolina';
    this.SD = 'South Dakota';
    this.TN = 'Tennessee';
    this.TX = 'Texas';
    this.UT = 'Utah';
    this.VT = 'Vermont';
    this.VA = 'Virginia';
    this.WA = 'Washington';
    this.WV = 'West Virginia';
    this.WI = 'Wisconsin';
    this.WY = 'Wyoming';
  }

  isValidState (state) {
    return this.hasOwnProperty(state);
  }
}
