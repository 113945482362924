import get from 'lodash/get';

export const mutations = {
  SET_APPLICANT_SUBMISSION_API (state, applicationSubmissionApi) {
    state.applicationSubmissionApi = applicationSubmissionApi;
  },
  SET_LEAD_CHANNEL_BEARER_TOKEN (state, bearerToken) {
    state.bearerToken = bearerToken;
  },
  SET_LEAD_CHANNEL_BRAND (state, brand) {
    state.brand = brand;
  },
  SET_LEAD_CHANNEL_EMAIL_ADDRESS (state, emailAddress) {
    state.emailAddress = emailAddress;
  },
  SET_LEAD_CHANNEL_FAVICON (state, favicon) {
    state.favicon = favicon;
  },
  SET_LEAD_CHANNEL_FOOTER_LOGO (state, footerLogo) {
    state.footerLogo.alt = get(footerLogo, 'alt', null);
    state.footerLogo.height = get(footerLogo, 'height', null);
    state.footerLogo.src = get(footerLogo, 'src', null);
    state.footerLogo.width = get(footerLogo, 'width', null);
  },
  SET_LEAD_CHANNEL_HEADER_LOGO (state, headerLogo) {
    state.headerLogo.alt = get(headerLogo, 'alt', null);
    state.headerLogo.height = get(headerLogo, 'height', null);
    state.headerLogo.src = get(headerLogo, 'src', null);
    state.headerLogo.width = get(headerLogo, 'width', null);
  },
  SET_LEAD_CHANNEL_CODE (state, leadChannel) {
    state.leadChannel = leadChannel;
  },
  SET_LEAD_CHANNEL_MAIL_CODE_SOURCE (state, mailCodeSource) {
    state.mailCodeSource = mailCodeSource;
  },
  SET_LEAD_CHANNEL_NAME (state, name) {
    state.name = name;
  },
  SET_LEAD_CHANNEL_ORGANIZATION_CODE (state, organizationCode) {
    state.organizationCode = organizationCode;
  },
  SET_LEAD_CHANNEL_PHONE_NUMBER (state, phoneNumber) {
    state.phoneNumber = phoneNumber;
  },
  SET_LEAD_CHANNEL_STYLESHEET (state, stylesheet) {
    state.stylesheet = stylesheet;
  },
  SET_LEAD_CHANNEL_TRACKING_PIXEL_TYPE (state, pixelType) {
    state.trackingPixelType = pixelType;
  },
  SET_LEAD_CHANNEL_TRACKING_PIXEL_URL (state, pixelUrl) {
    state.trackingPixelUrl = pixelUrl;
  },
  SET_LEAD_CHANNEL_WORKFLOW (state, workflow) {
    state.workflow = workflow;
  }
};
