import {
  AAA_ARIZONA,
  AAA_DIRECT_MAIL,
  ACQUINITY_INTERACTIVE,
  ACTON_REFERRAL,
  ADS_TRACK,
  AFFILIATE,
  ALLSTATE,
  AUTO_APPROVED,
  AUTO_CREDIT_EXPRESS,
  AUTO_LOAN_RATE,
  BANK_RATE,
  BRADS_DEALS,
  CALL_ENGINE,
  CARFI_DIRECT,
  CARS_DIRECT,
  CARS_INC,
  CITY_LENDING,
  COMPLETE,
  CONSUMER_ADVOCATE,
  CORE_DIGITAL,
  CREDIFUL,
  CREDIT_KARMA_2,
  CREDIT_KARMA_DIRECT,
  CREDIT_KARMA_PREQUAL,
  CREDIT_KARMA,
  CREDIT_SESAME_SITE,
  CREDIT_SESAME,
  DATCU_ANB,
  DEALER_AUTOMOTIVE_GROUP,
  DEBT,
  DIRECT_MAIL_RETARGETING,
  DRIVE_AMERICA,
  EXPERIAN,
  FACEBOOK,
  FAMILY,
  FINANCE_BUZZ,
  FINDER,
  FIRST_INVESTORS,
  GEISTM,
  GOOGLE_03,
  GOOGLE,
  LEND_EDU,
  LENDING_TREE_EXCLUSIVE,
  LENDING_TREE_PURCHASE,
  LENDINGTREE_CALL,
  LENDINGTREE,
  LINKSHARE_PAY_PER_CALL,
  LOWER_MY_CAR_NOTE,
  MAIL_MAN,
  MSN,
  MY_AUTO_LOAN,
  NATURAL_INTELIGENCE_2,
  NATURAL_INTELLIGENCE,
  NERDWALLET_2,
  NERDWALLET,
  OPEN_ROAD_LENDING,
  PENN_STATE,
  PERFORM_CB,
  PERK_SPOT,
  QUIN_STREET,
  RADIO,
  RATE_RESET,
  RATEGENIUS_EXPERIAN_EMAIL,
  RATEGENIUS_NEW_APPLICATION,
  RATEGENIUS_TODAY,
  REACH_MOBI,
  REV_CONTENT,
  RG_BLOG,
  RG_DIRECT_MAIL,
  RG_TEST,
  SECCO_SQUARED,
  SELF_LENDER,
  SUPERMONEY,
  TABOOLA,
  UPS,
  WEB_2_CARZ,
  YOUTUBE_COMMON_CENTS
} from './sponsor';

export const sponsorLeadChannelMap = {
  [AAA_ARIZONA]: 'RG_AAA_ARIZONA_FAST_TRACK_API',
  [AAA_DIRECT_MAIL]: 'RG_AAA_DIRECT_MAIL_FAST_TRACK_API',
  [ACQUINITY_INTERACTIVE]: 'RG_ACQUINITY_INTERACTIVE_FAST_TRACK_API',
  [ACTON_REFERRAL]: 'RG_ACTON_REFERRAL_FAST_TRACK_API',
  [ADS_TRACK]: 'RG_ADS_TRACK_FAST_TRACK_API',
  [AFFILIATE]: 'RG_AFFILIATE_FAST_TRACK_API',
  [ALLSTATE]: 'RG_ALL_STATE_FAST_TRACK_API',
  [AUTO_APPROVED]: 'RG_AUTO_APPROVED_FAST_TRACK_API',
  [AUTO_CREDIT_EXPRESS]: 'RG_AUTO_CREDIT_EXPRESS_FAST_TRACK_API',
  [AUTO_LOAN_RATE]: 'RG_AUTO_LOAN_RATE_FAST_TRACK_API',
  [BANK_RATE]: 'RG_BANK_RATE_FAST_TRACK_API',
  [BRADS_DEALS]: 'RG_BRADS_DEALS_FAST_TRACK_API',
  [CALL_ENGINE]: 'RG_CALL_ENGINE_FAST_TRACK_API',
  [CARFI_DIRECT]: 'RG_CARFI_DIRECT_FAST_TRACK_API',
  [CARS_DIRECT]: 'RG_CARS_DIRECT_FAST_TRACK_API',
  [CARS_INC]: 'RG_CARS_INC_FAST_TRACK_API',
  [CITY_LENDING]: 'RG_CITY_LENDING_FAST_TRACK_API',
  [COMPLETE]: 'RG_COMPLETE_FAST_TRACK_API',
  [CONSUMER_ADVOCATE]: 'RG_CONSUMER_ADVOCATE_FAST_TRACK_API',
  [CORE_DIGITAL]: 'RG_CORE_DIGITAL_FAST_TRACK_API',
  [CREDIFUL]: 'RG_CREDIFUL_FAST_TRACK_API',
  [CREDIT_KARMA_2]: 'RG_CREDIT_KARMA_TWO_FAST_TRACK_API',
  [CREDIT_KARMA_DIRECT]: 'RG_CREDIT_KARMA_DIRECT_FAST_TRACK_API',
  [CREDIT_KARMA_PREQUAL]: 'RG_CREDIT_KARMA_PREQUAL_FAST_TRACK_API',
  [CREDIT_KARMA]: 'RG_CREDIT_KARMA_FAST_TRACK_API',
  [CREDIT_SESAME_SITE]: 'RG_CREDIT_SESAME_SITE_FAST_TRACK_API',
  [CREDIT_SESAME]: 'RG_CREDIT_SESAME_FAST_TRACK_API',
  [DATCU_ANB]: 'RG_DATCU_ANB_FAST_TRACK_API',
  [DEALER_AUTOMOTIVE_GROUP]: 'RG_DEALER_AUTOMOTIVE_GROUP_FAST_TRACK_API',
  [DEBT]: 'RG_DEBT_FAST_TRACK_API',
  [DIRECT_MAIL_RETARGETING]: 'RG_DIRECT_MAIL_RETARGETING_FAST_TRACK_API',
  [DRIVE_AMERICA]: 'RG_DRIVE_AMERICA_FAST_TRACK_API',
  [EXPERIAN]: 'RG_EXPERIAN_FAST_TRACK_API',
  [FACEBOOK]: 'RG_FACEBOOK_FAST_TRACK_API',
  [FAMILY]: 'RG_FAMILY_FAST_TRACK_API',
  [FINANCE_BUZZ]: 'RG_FINANCEBUZZ_FAST_TRACK_API',
  [FINDER]: 'RG_FINDER_FAST_TRACK_API',
  [FIRST_INVESTORS]: 'RG_FIRST_INVESTORS_FAST_TRACK_API',
  [GEISTM]: 'RG_GEISTM_FAST_TRACK_API',
  [GOOGLE_03]: 'RG_GOOGLE_ZEROTHREE_FAST_TRACK_API',
  [GOOGLE]: 'RG_GOOGLE_FAST_TRACK_API',
  [LEND_EDU]: 'RG_LEND_EDU_FAST_TRACK_API',
  [LENDING_TREE_EXCLUSIVE]: 'RG_LENDING_TREE_EXCLUSIVE_FAST_TRACK_API',
  [LENDING_TREE_PURCHASE]: 'RG_LENDING_TREE_PURCHASE_FAST_TRACK_API',
  [LENDINGTREE_CALL]: 'RG_LENDINGTREE_CALL_FAST_TRACK_API',
  [LENDINGTREE]: 'RG_LENDINGTREE_FAST_TRACK_API',
  [LINKSHARE_PAY_PER_CALL]: 'RG_LINKSHARE_PAY_PER_CALL_FAST_TRACK_API',
  [LOWER_MY_CAR_NOTE]: 'RG_LOWER_MY_CAR_NOTE_FAST_TRACK_API',
  [MAIL_MAN]: 'RG_MAIL_MAN_FAST_TRACK_API',
  [MSN]: 'RG_MSN_FAST_TRACK_API',
  [MY_AUTO_LOAN]: 'RG_MY_AUTO_LOAN_FAST_TRACK_API',
  [NATURAL_INTELIGENCE_2]: 'RG_NATURAL_INTELLIGENCE_TWO_FAST_TRACK_API',
  [NATURAL_INTELLIGENCE]: 'RG_NATURAL_INTELLIGENCE_FAST_TRACK_API',
  [NERDWALLET_2]: 'RG_NERD_WALLET_TWO_FAST_TRACK_API',
  [NERDWALLET]: 'RG_NERDWALLET_FAST_TRACK_API',
  [OPEN_ROAD_LENDING]: 'RG_OPEN_ROAD_LENDING_FAST_TRACK_API',
  [PENN_STATE]: 'RG_PENN_STATE_FAST_TRACK_API',
  [PERFORM_CB]: 'PERFORM_CB_FAST_TRACK_API',
  [PERK_SPOT]: 'RG_PERK_SPOT_FAST_TRACK_API',
  [QUIN_STREET]: 'RG_QUIN_STREET_FAST_TRACK_API',
  [RADIO]: 'RG_RADIO_FAST_TRACK_API',
  [RATE_RESET]: 'RG_RATE_RESET_FAST_TRACK_API',
  [RATEGENIUS_EXPERIAN_EMAIL]: 'RG_RATEGENIUS_EXPERIAN_EMAIL_FAST_TRACK_API',
  [RATEGENIUS_NEW_APPLICATION]: 'RG_RATEGENIUS_NEW_APPLICATION_FAST_TRACK_API',
  [RATEGENIUS_TODAY]: 'RG_RATEGENIUS_TODAY_FAST_TRACK_API',
  [REACH_MOBI]: 'RG_REACH_MOBI_FAST_TRACK_API',
  [REV_CONTENT]: 'RG_REV_CONTENT_FAST_TRACK_API',
  [RG_BLOG]: 'RG_BLOG_FAST_TRACK_API',
  [RG_DIRECT_MAIL]: 'RG_DIRECT_MAIL_FAST_TRACK_API',
  [RG_TEST]: 'RG_TEST_FAST_TRACK_API',
  [SECCO_SQUARED]: 'RG_SECCO_SQUARED_FAST_TRACK_API',
  [SELF_LENDER]: 'RG_SELF_LENDER_FAST_TRACK_API',
  [SUPERMONEY]: 'RG_SUPERMONEY_FAST_TRACK_API',
  [TABOOLA]: 'RG_TABOOLA_FAST_TRACK_API',
  [UPS]: 'RG_UPS_FAST_TRACK_API',
  [WEB_2_CARZ]: 'RG_WEB_TWO_CARZ_FAST_TRACK_API',
  [YOUTUBE_COMMON_CENTS]: 'RG_YOUTUBE_COMMON_CENTS_FAST_TRACK_API'
};
