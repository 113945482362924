import { consumerPortalHttpRequest } from '../axios.config';
import { extractErrorInformation } from '../extractErrorInformation';
import { reportError } from '../../errors/reportError';
import { mapValueAndLabelToIdAndName } from '../../../utilities/mapValueAndLabelToIdAndName';

/**
 * Gets the vehicle model data for the provided year and make id
 *
 * @param {*} httpRequest
 * @returns {({ year: number, makeId: number }) => Promise<Object[]>}
 */
export const getVehicleModelsFromConsumerPortal = (
  httpRequest = consumerPortalHttpRequest
) => async ({
  year,
  makeId
}) => {
  try {
    const response = await httpRequest.get(
      `/api/vehicles/years/${year}/makes/${makeId}/models`
    );

    return mapValueAndLabelToIdAndName(response.data);
  } catch (error) {
    reportError(error, { extra: extractErrorInformation(error) });
    throw error;
  }
};
