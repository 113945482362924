import { camelCase, snakeCase, kebabCase, pascalCase } from 'eslint-plugin-vue/lib/utils/casing';

function ucWords (sentence, separator) {
  const sep = separator || ' ';
  const words = sentence.toLowerCase().split(sep);
  for (const i in words) {
    const word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.substr(1);
  }
  return words.join(sep);
}

export {
  camelCase,
  ucWords,
  snakeCase,
  kebabCase,
  pascalCase
};
