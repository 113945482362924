import filter from 'lodash/filter';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import map from 'lodash/map';

export const mapValueAndLabelToIdAndName = (values) =>
  map(
    filter(values, isObject),
    (value) => ({
      id: get(value, 'value', null),
      name: get(value, 'label', null)
    })
  );
