import parseFunction from 'parse-function';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';

const app = parseFunction();

/**
 * Factory object used internally by service container.
 *
 */
export class Factory {
  /**
   * Constructor
   *
   * @param {Array} require - Array of strings that tells us which services are needed
   * @param {function} init - the initializer for the the service
   * @param {boolean} lazy - setting to false triggers injection immediately
   */
  constructor ({ require, init, lazy }) {
    this.require = require || [];
    this.init = init || (() => null);
    this.lazy = typeof lazy !== 'undefined' ? lazy : true;
  }

  /**
   * does injection
   *
   * @param {ServiceContainer} serviceContainer
   * @returns {*|null}
   */
  inject (serviceContainer) {
    const dependencies = this.require.map(dependencyAlias => serviceContainer.get(dependencyAlias));
    return this.init.apply(null, dependencies);
  }

  /**
   * create a factory from a compatible structure
   *
   * {require: ['foo', 'bar'], init: (f, b)=> new FooBar(f, b)}, lazy: true
   *
   * @param {Object|Factory} factory
   * @returns {Factory}
   */
  static createFromObject (factory) {
    if (factory instanceof Factory) {
      return factory;
    }

    if (!factory.hasOwnProperty('require') || !factory.hasOwnProperty('init')) {
      throw new Error('Factory is not compatible object');
    }

    return new Factory(factory);
  }

  /**
   * Create a factory from array
   *
   * format ['foo', 'bar', (f, b)=>new Foobar(f, b)]
   *
   * @param {array} factory
   * @returns {Factory}
   */
  static createFromArray (factory) {
    if (!isArray(factory)) {
      throw new Error('Factory must be array');
    }
    const require = factory;
    const init = require.pop();
    const lazy = true;

    return new Factory({
      require,
      init,
      lazy
    });
  }

  /**
   * Evaluates the function param names.
   *
   * NOTE: DEVELOPMENT ONLY. DO NOT USE IN PRODUCTION! Code mangler will change parameter names.
   *
   * @param factory
   * @returns {Factory}
   */
  static createFromParamNames (factory) {
    if (typeof factory !== 'function') {
      throw new Error('Factory is not a function');
    }
    const require = app.parse(factory).args;
    const init = factory;
    const lazy = true;

    return new Factory({
      require,
      init,
      lazy
    });
  }

  /**
   * Decide how to build a factory from different input types
   *
   * @param {Factory|Object|Array} factory
   * @returns {Factory}
   */
  static create (factory) {
    if (isArray(factory)) {
      return Factory.createFromArray(factory);
    } else if (isFunction(factory)) {
      return Factory.createFromParamNames(factory);
    } else if (isObject(factory)) {
      return Factory.createFromObject(factory);
    }
  }
}
