import {
  NETWORK_STATUS_MAX_ENTRIES
} from '../../../values/connection';

export const mutations = {
  SET_IS_ONLINE (state, isOnline) {
    state.isOnline = isOnline;
  },
  SET_LATEST_RESPONSE_TIME_IN_MILLISECONDS (state, latestResponseTimeInMilliseconds) {
    state.latestResponseTimeInMilliseconds = latestResponseTimeInMilliseconds;
  },
  SET_NETWORK_STATUS (state, networkStatus) {
    // Prevent memory leak by shifting the oldest item in the array
    if (state.networkStatus.length === NETWORK_STATUS_MAX_ENTRIES) {
      state.networkStatus.shift();
    }

    state.networkStatus.push(networkStatus);
  }
};
