export const state = {
  applicationSubmissionApi: null,
  autopayFaqLink: 'https://www.autopay.com/faq/',
  autopayPrivacyPolicyLink: 'https://www.autopay.com/privacy-policy',
  autopayTermsOfUseLink: 'https://www.autopay.com/terms',
  bearerToken: null,
  brand: null,
  emailAddress: null,
  favicon: null,
  footerLogo: {
    alt: null,
    height: null,
    src: null,
    width: null
  },
  headerLogo: {
    alt: null,
    height: null,
    src: null,
    width: null
  },
  leadChannel: null,
  mailCodeSource: null,
  name: null,
  organizationCode: null,
  phoneNumber: null,
  rateGeniusFaqLink: 'https://www.rategenius.com/faq/',
  rateGeniusPrivacyPolicyLink: 'https://www.rategenius.com/privacy-policy',
  rateGeniusTermsOfUseLink: 'https://www.rategenius.com/terms-of-use/',
  stylesheet: null,
  trackingPixelType: null,
  trackingPixelUrl: null,
  treslFaqLink: 'https://mytresl.com/learn/',
  treslPrivacyPolicyLink: 'https://mytresl.com/privacy-policy/',
  treslTermsOfUseLink: 'https://mytresl.com/terms-of-use/',
  workflow: null
};
