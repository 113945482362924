import { actions } from './applicant/actions';
import { getters } from './applicant/getters';
import { mutations } from './applicant/mutations';
import { state } from './applicant/state';
import { StoreModifier } from '../../utilities/storeModifiers';

export default new StoreModifier({
  actions,
  getters,
  mutations,
  namespaced: true,
  state
});
