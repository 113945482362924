import get from 'lodash/get';
import { autopayHttpRequest } from '../axios.config';
import { extractErrorInformation } from '../extractErrorInformation';
import { reportError } from '../../errors/reportError';

/**
 * Returns the mail code data for the provided mail code
 *
 * @param {string} bearerToken
 * @param {*} httpRequest
 * @returns {(source: string, mailCode: string) => Promise<Object>}
 */
export const getMailCodeData = (
  httpRequest = autopayHttpRequest
) => async (
  source,
  mailCode
) => {
  try {
    const response = await httpRequest.post(
      `/services/v1.0/mail_code/find/source/${source}/mailCode/${mailCode}`
    );

    return response;
  } catch (error) {
    if (get(error, 'response.status') !== 404) {
      reportError(error, { extra: extractErrorInformation(error) });
    }

    throw error;
  }
};
