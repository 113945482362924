import every from 'lodash/every';
import head from 'lodash/head';
import isNumber from 'lodash/isNumber';
import { getNumberFormatter } from '../../../utilities/getNumberFormatter';

const currencyFormatter = getNumberFormatter();

export const getters = {
  apr: state => state.apr,
  amount: state => state.amount,
  amountAsCurrencyString:
    (state, { amount }) => currencyFormatter(amount),
  amountAsCurrencyStringWithoutCents:
    (state, { amount }) => head(currencyFormatter(amount)?.split('.')),
  consumerSelectedRate: (state, { amount, hasValidPrequalData, payment, apr, term }) => {
    if (!hasValidPrequalData) {
      return null;
    }

    const selectedRate = {
      loanAmount: amount,
      monthlyPaymentAmount: payment,
      rate: apr,
      selectedDate: new Date().toISOString(),
      selectedSource: 'LEAD_PARTNER_PLATFORM',
      term
    };

    // trim any data that is missing, otherwise APEX will not be too happy
    Object.keys(selectedRate).forEach((key) => {
      if (!selectedRate[key]) {
        delete selectedRate[key];
      }
    });

    return selectedRate;
  },
  hasValidPrequalData: (state) => {
    return every(
      ['amount', 'apr', 'payment', 'term'],
      (param) => state[param] > 0
    );
  },
  hasPrequalAmount: (state) => isNumber(state.amount),
  payment: state => state.payment,
  paymentAsCurrencyString:
    (state, { payment }) => currencyFormatter(payment),
  term: state => state.term
};
