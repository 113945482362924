const scripts = {
  autopay: 'https://cmp.osano.com/Azq8b9SazCgbg131e/efb3a723-eaec-436a-b10d-446adaa79eff/osano.js',
  rategenius: 'https://cmp.osano.com/Azq8b9SazCgbg131e/ad9ebdf4-68d7-4291-8223-437702837afa/osano.js',
  tresl: 'https://cmp.osano.com/Azq8b9SazCgbg131e/bb0b3db1-88b0-4b9e-b970-4156507051bd/osano.js'
};

/**
 * @link https://www.osano.com/cookieconsent
 */
export default {
  install(vue, options) {
    const script = document.createElement('script');
    script.src = scripts[options?.brandName || 'autopay'];
    script.type = 'text/javascript';

    document.body.appendChild(script);
  }
}
