import { autopayHttpRequest } from '../axios.config';

/**
 * POSTs the autopay application to the provided path using
 * the axios instance and bearer token
 *
 * @param {string} bearerToken
 * @param {AxiosInstance} httpRequest
 * @returns {(path: string, application: Object) => Promise<*>}
 */
export const submitApplicationToAutopay = (
  bearerToken,
  httpRequest = autopayHttpRequest
) => (
  path,
  autopayApplication
) =>
  httpRequest.post(
    path,
    JSON.stringify(autopayApplication || {}),
    {
      headers: {
        authorization: `Bearer ${bearerToken}`,
        'content-type': 'application/json'
      }
    }
  );
