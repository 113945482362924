import * as Sentry from '@sentry/vue';

/**
 * Generic error reporting function
 *
 * @param {Error} error
 * @param {Object} captureContext - optional
 */
export const reportError = (error, captureContext) => {
  Sentry.captureException(error, captureContext);
};
