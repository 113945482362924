export const CREDIT_SNAP = 'CreditSnap';
export const CONSUMER_PORTAL = 'Consumer Portal';
export const TRESL_BRAND_NAME = 'Tresl';
export const TRESL_LEAD_CHANNEL = 'TRESL_FAST_TRACK_API';
export const RATE_GENIUS = 'RateGenius';
export const RATEGENIUS_LEAD_CHANNEL = 'RATEGENIUS_FAST_TRACK_API';
export const DEFAULT_APPLICATION_SUBMISSION_API = 'Autopay';
export const DEFAULT_BRAND_LEAD_CHANNEL = 'AUTOPAY_FAST_TRACK_API';
export const DEFAULT_BRAND_NAME = 'AUTOPAY';
export const DEFAULT_BRAND_ORGANIZATION_CODE = 'AUTOPAY';
export const DEFAULT_MAIL_CODE_SOURCE = 'SAVE_WITH_AUTOPAY';

export const DEFAULT_EMAIL_ADDRESS = 'save@autopay.com';
export const DEFAULT_FAVICON = '/favicon.ico';
export const DEFAULT_PHONE_NUMBER = '844-276-3272';
export const DEFAULT_WORKFLOW = 'default';
export const DEFAULT_HEIGHT = 'auto';
export const DEFAULT_WIDTH = 'auto';
export const META_DESCRIPTION = 'Find the best auto loan rate by comparing competitive refinance offers. Apply with $leadChannelName to get fast, easy savings with no hidden fees.';
