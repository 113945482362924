import Vue from 'vue';
import VueRouter from 'vue-router';

import { serviceContainer } from './di';

import './utilities/setupVueFilters';
import './utilities/setupVueDirectives';

// Font awesome
import {
  faCamera,
  faCar,
  faCaretDown,
  faCaretUp,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faEnvelopeOpenText,
  faExclamationTriangle,
  faHandHoldingUsd,
  faInfoCircle,
  faLock,
  faMoneyBillWave,
  faPen,
  faPeopleArrows,
  faPercent,
  faPlayCircle,
  faRandom,
  faSearch,
  faStar,
  faTimesCircle,
  faUniversity,
  faWifi
} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import 'tippy.js/dist/tippy.css';
// Vee-validate
import { setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import './utilities/dev.js';

require('./utilities/extendValidationRules');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

setInteractionMode('maskable', ({ errors, value }) => {
  if (errors.length) {
    return {
      on: ['change', 'blur', 'keyup'],
      debounce: 0
    };
  }

  // validate immediately after leaving the field.
  return {
    on: ['change', 'blur', 'keyup'],
    debounce: value ? 1000 : 0
  };
});

Vue.use(VueRouter);

require('./css/global');

// To add a new icon, import it on line 5 and add it to the call below
library.add(
  faCamera,
  faCar,
  faCaretDown,
  faCaretUp,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faEnvelopeOpenText,
  faExclamationTriangle,
  faHandHoldingUsd,
  faInfoCircle,
  faLock,
  faMoneyBillWave,
  faPen,
  faPeopleArrows,
  faPercent,
  faPlayCircle,
  faRandom,
  faSearch,
  faStar,
  faTimesCircle,
  faUniversity,
  faWifi
);

// Registering Vue stuff
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.config.productionTip = false;

// Attach some of our services to vue instance
Vue.use((Vue) => {
  // dependency injection attached to vue instance as $di
  Vue.prototype.$di = serviceContainer;
});
