import {
  DEFAULT_BRAND_LEAD_CHANNEL,
  RATEGENIUS_LEAD_CHANNEL,
  TRESL_LEAD_CHANNEL
} from '../values/brand';
import { getHostnameFromWindow } from './getHostnameFromWindow';
import { getLeadChannelFromSearchParams } from './getLeadChannelFromSearchParams';
import { getLeadChannelFromStorage } from './getLeadChannelFromStorage';
import { isNonEmptyString } from './isNonEmptyString';
import { sponsorLeadChannelMap } from '../values/sponsorLeadChannelMap';
import { getSponsorIdFromSearchParams } from './getSponsorIdFromSearchParams';

/**
 * Gets the lead channel from:
 *  - search params
 *  - local storage
 *  - sponsor map
 *  - based on hostname (if none of the above are available)
 *
 * @returns {() => string}
*/
export const getLeadChannel = (
  getFromSearchParams = getLeadChannelFromSearchParams,
  getFromStorage = getLeadChannelFromStorage,
  getFromWindow = getHostnameFromWindow,
  getSponsorFromSearchParams = getSponsorIdFromSearchParams
) => () => {
  const leadChannelFromSearchParams = getFromSearchParams();
  if (isNonEmptyString(leadChannelFromSearchParams)) {
    return leadChannelFromSearchParams;
  }

  const sponsorId = getSponsorFromSearchParams();
  if (sponsorLeadChannelMap[sponsorId]) {
    return sponsorLeadChannelMap[sponsorId];
  }

  const leadChannelFromLocalStorage = getFromStorage();
  if (isNonEmptyString(leadChannelFromLocalStorage)) {
    return leadChannelFromLocalStorage;
  }

  const hostname = getFromWindow();
  if (hostname.includes('rategenius')) {
    return RATEGENIUS_LEAD_CHANNEL;
  } else if (hostname.includes('tresl')) {
    return TRESL_LEAD_CHANNEL;
  }

  return DEFAULT_BRAND_LEAD_CHANNEL;
};
