import { getParamFromUrl, parseUrl } from '../lib/url';
import { States } from '../../values/states';
import { formatValue } from '../../utilities/Currency';
import { customerIntent } from '../../values/application';
import toUpper from 'lodash/toUpper';

/**
 *
 * @param {Location} location
 * @returns {URLSearchParams}
 */
function parseQuery (location) {
  const url = parseUrl(location);
  return url.searchParams;
}

// CAPITALIZE STATE FROM QUERY
function capitalize (stateStr) {
  return stateStr.charAt(0).toUpperCase() + stateStr.slice(1);
}

export class QueryService {
  /**
   * Constructor
   *
   * @param {Location} location
   */
  constructor (location) {
    /** @type Location */
    this.location = location;
    this.query = parseQuery(this.location);
  }

  /**
   * Getter for query params
   *
   * @param index
   * @param def
   * @returns {*}
   */
  get (index, def) {
    def = typeof def === 'undefined' ? null : def;
    let val = (this.query.get(index) !== null) ? decodeURIComponent(this.query.get(index)) : null;
    // CONVERTS PHONE NUMBER TO DESIRED FORMAT
    if (index === 'mobile_number' && val !== null) {
      const formattedPhone = this.query.get(index).replace(/\D/g, '');
      val = formattedPhone.substring(0, 3) + '-' + formattedPhone.substring(3, 6) + '-' + formattedPhone.substring(6, 10);
    }
    // CONVERTS STATE FROM QUERY TO ABBREVIATED FORMAT
    if (index === 'state' && val !== null) {
      if (val.length !== 2) {
        const statesList = new States();
        const stateCap = val.split(' ').map(capitalize).join(' ');
        val = Object.keys(statesList).find(key => statesList[key] === stateCap);
      };
    }
    // CONVERT VEHICLE MAKE TO UPPERCASE
    if (index === 'vehicle_make' && val !== null) {
      val = val.toUpperCase();
    }
    // CONVERT CURRENCY TO CORRECT FORMAT
    if (((index === 'annual_income') || (index === 'income')) && val !== null) {
      val = formatValue(val);
    }
    return typeof val === 'undefined' || val === null ? def : val;
  }

  /**
   * Get the template data from query
   *
   * @returns {{templateId: *}}
   * @private
   */
  getTemplateData () {
    const templateId = this.get('tid');
    return { templateId };
  }

  /**
   * Get the campaign data from query
   *
   * @returns {{timeToLive: *, attributedAt: any, campaignId: *}}
   * @private
   */
  getCampaignData (defaultTtl) {
    const campaignId = this.get('cid');
    const attributedAt = this.get('attr_at');
    const timeToLive = this.get('attr_ttl', defaultTtl);

    return {
      campaignId,
      attributedAt: attributedAt ? new Date(parseInt(attributedAt, 10)).toISOString() : null,
      timeToLive
    };
  }

  /**
   * Retrieves attribution data from query params
   *
   * @returns {{sponsorId: number, sponsorClickId: string}}
   */
  getAttributionData () {
    return {
      sponsorId: this.get('sid'),
      sponsorClickId: this.get('subid')
    };
  }

  getReferalData () {
    return this.get('grsf');
  }

  /**
   * Retrieves intent data from query params
   *
   * @returns {{intent: string}}
   */
  getIntent () {
    const intent = customerIntent[toUpper(this.get('intent'))];
    return {
      intent
    };
  }

  /**
   * Retrieves Prequal ID data from query params
   *
   * @returns {{prequalId: string}}
   */
  getPrequalId () {
    return this.get('prequal_id');
  }

  /**
   * Retrieves applicant data from query params
   *
   * @returns {{firstName: string, lastName: string, email: string, mobileNumber: number, street: string, street2: string, city: string, state: string, zipCode: string}}
   */
  getApplicantData () {
    const primary = {
      firstName: this.get('first_name'),
      lastName: this.get('last_name'),
      email: this.get('email'),
      mobileNumber: this.get('mobile_number'),
      address: {
        street: this.get('address_line_1'),
        street2: this.get('address_line_2'),
        city: this.get('city'),
        state: this.get('state'),
        zipCode: this.get('zip')
      }
    };
    return {
      primary
    };
  }

  /**
  * Retrieves applicant data from query params
  *
  * @returns {{street: string, street2: string, city: string, state: string, zipCode: string}}
  */
  getApplicantAddress () {
    return {
      street: this.get('address_line_1'),
      street2: this.get('address_line_2'),
      city: this.get('city'),
      state: this.get('state'),
      zipCode: this.get('zip')
    };
  }

  /**
   * Retrieves vehicle data from query params
   *
   * @returns {{year: integer, make: string, model: string, trim: string}}
   */
  getVehicleData () {
    return {
      year: this.get('vehicle_year'),
      make: this.get('vehicle_make'),
      model: this.get('vehicle_model'),
      trim: this.get('vehicle_trim')
    };
  }

  /**
   * Get AUTOPAY data.
   *
   * AUTOPAY data is nested under an ap object. with `ap[a]` representing an application
   *
   * [NOTE] using a different "URL" methodology so as not to break current support
   */
  getAutopayData () {
    return getParamFromUrl(window.location, 'ap');
  }

  /**
   * Retrieves employment data from query params
   *
   * @returns {{grossAnnualIncome: integer}}
   */
  getIncomeData () {
    return {
      grossAnnualIncome: this.get('annual_income')
    };
  }

  /**
   * Get utm data from query
   *
   * @returns {{campaign: *, term: *, source: *, medium: *, content: *}}
   * @private
   */
  getUtms () {
    const utmCampaign = this.get('utm_campaign');
    const utmTerm = this.get('utm_term');
    const utmSource = this.get('utm_source');
    const utmMedium = this.get('utm_medium');
    const utmContent = this.get('utm_content');

    return {
      campaign: utmCampaign,
      term: utmTerm,
      source: utmSource,
      medium: utmMedium,
      content: utmContent
    };
  }

  /**
   * Should the application mock its offer?
   *
   * @returns {boolean}
   */
  shouldMockOffer () {
    return this.get('force_mock_offer', false);
  }

  /**
   * Get the offer UUID (if supplied via query string)
   *
   * @returns {boolean}
   */
  getOfferUuid () {
    return this.get('offer_uuid', null);
  }

  /**
   * return the workflow name
   *
   * @returns {*}
   */
  getWorkflowName () {
    return this.get('fl');
  }
}
