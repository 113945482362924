import get from 'lodash/get';
import { getNumberFormatter } from '../../../utilities/getNumberFormatter';
import { isNonEmptyString } from '../../../utilities/isNonEmptyString';
import { convertToNumberOrNull } from '../../../utilities/convertToNumberOrNull';

const currencyFormatter = getNumberFormatter();

const removeForwardSlashes = (value) =>
  isNonEmptyString(value) ? value.replace(/\//g, '') : null;

export const getters = {
  consumerSelectedRate: (state, { hasMailCode, preApprovalAmount, newPayment, newRate, newTerm }) => {
    if (!hasMailCode) {
      return null;
    }

    const selectedRate = {
      loanAmount: preApprovalAmount,
      monthlyPaymentAmount: newPayment,
      rate: newRate,
      selectedDate: new Date().toISOString(),
      selectedSource: 'MAIL_CODE',
      term: newTerm
    };

    // trim any data that is missing, otherwise APEX will not be too happy
    Object.keys(selectedRate).forEach((key) => {
      if (!selectedRate[key]) {
        delete selectedRate[key];
      }
    });

    return selectedRate;
  },
  currentPayment:
    state => get(state, 'responseBody.payload.mailerCode.currentPayment', null),
  currentPaymentAsCurrencyString:
    (state, { currentPayment }) => currencyFormatter(currentPayment),
  hasMailCode: state => isNonEmptyString(state.mailCode),
  hasRequestFinished: state => state.hasRequestFinished,
  isMailCodeValid: state => state.responseStatusCode === 200,
  // need to check for `=== true` so undefined/null return correct boolean value
  isMailerCodeDataInvalid:
    state => get(state, 'responseBody.payload.mailerCodeDataInvalid', false) === true,
  // remove any forward slashes from the mail code
  mailCode:
    state => removeForwardSlashes(state.mailCode),
  newPayment:
    state => convertToNumberOrNull(get(state, 'responseBody.payload.mailerCode.newPayment', null)),
  newPaymentAsCurrencyString:
    (state, { newPayment }) => currencyFormatter(newPayment),
  newRate:
    state => get(state, 'responseBody.payload.mailerCode.newRate', null),
  newTerm:
    state => get(state, 'responseBody.payload.mailerCode.newTerm', null),
  payoffAmount:
    state => convertToNumberOrNull(get(state, 'responseBody.payload.vehicle.payoffAmount', null)),
  potentialSavings:
    state => get(state, 'responseBody.payload.mailerCode.potentialSavings', null),
  potentialSavingsAsCurrencyString:
    (state, { potentialSavings }) => currencyFormatter(potentialSavings),
  preApprovalAmount:
    state => convertToNumberOrNull(get(state, 'responseBody.payload.mailerCode.preApprovalAmount', null)),
  preApprovalAmountAsCurrencyString:
    (state, { preApprovalAmount }) => currencyFormatter(preApprovalAmount)
};
